import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './custommodal.scss';
import { TextField, Radio, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Constant from '../../common/constant/constant';
import { messageLockAccount } from '../../data/mockDataUser';
import { MenuItem } from '@material-ui/core';

const CustomModal = ({
	message,
	btnAgreeShow,
	btnCancelShow,
	btnCloseShow,
	btnConfirmShow,
	onCloseModal,
	show,
	onHide,
	showInputNumber,
	showInputReason,
	showInputGameCategory,
	inputGameCategory,
	showProcess,
	valueSelect,
}) => {
	const [number, setNumber] = useState(Constant.ZERO);
	const [reason, setReason] = useState('Cheat');
	const [gameCategory, setGameCategory] = useState(Constant.EMPTY);
	const [selectedValue, setSelectedValue] = useState(valueSelect);
	const [isLoadingBtn, setIsLoadingBtn] = useState(false);

	useEffect(() => {
		if (showInputNumber) {
			setNumber(Constant.EMPTY);
		}
		if (showInputReason) {
			setReason('Cheat');
		}
		if (showInputGameCategory) {
			setSelectedValue(Constant.EMPTY);
		}
		if (inputGameCategory === Constant.EMPTY) {
			setGameCategory(Constant.EMPTY);
		} else {
			setGameCategory(inputGameCategory);
		}
		if (showProcess && valueSelect) {
			setSelectedValue(valueSelect);
		}
	}, [
		inputGameCategory,
		showInputNumber,
		showInputReason,
		showInputGameCategory,
		showProcess,
		valueSelect,
	]);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
		console.log(event.target.value);
	};

	const onModalClose = (event) => {
		let data = Constant.EMPTY;
		data = showInputNumber
			? number
			: showInputReason
			? reason
			: showInputGameCategory
			? gameCategory
			: showProcess
			? selectedValue
			: Constant.EMPTY;
		onCloseModal(event, data);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="headerModal">
				<Modal.Title id="contained-modal-title-vcenter">
					{Constant.LABEL.NOTIFICATION}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="bodyModal">
				<span>{message}</span>
				<TextField
					placeholder="Nhập số lượng"
					onChange={(e) => setNumber(e.target.value)}
					value={number ? number : ''}
					type="number"
					variant="outlined"
					sx={{
						display: showInputNumber ? 'block' : 'none',
						marginTop: '10px',
					}}
					size="small"
				/>

				<div
					style={{
						display: showInputReason ? 'flex' : 'none',
						marginTop: '10px',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'start',
					}}
				>
					<span>{Constant.LABEL.REASON}</span>
					{/* <TextField
						placeholder="Nhập nội dung"
						onChange={(e) => setReason(e.target.value)}
						value={reason ? reason : ''}
						variant="outlined"
						
					/> */}
					<Select
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={reason ? reason : 'Cheat'}
						onChange={(e) => setReason(e.target.value)}
						sx={{ marginLeft: '10px', minWidth: '270px' }}
						size="small"
					>
						{messageLockAccount.map((item) => {
							return (
								<MenuItem value={item} key={item}>
									<span>{item}</span>
								</MenuItem>
							);
						})}
					</Select>
				</div>

				<div
					style={{
						display: showInputGameCategory ? 'flex' : 'none',
						marginTop: '10px',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<span>{Constant.LABEL.PROJECT_CATEGORY}</span>
					<TextField
						placeholder="Nhập thể loại"
						onChange={(e) => setGameCategory(e.target.value)}
						value={gameCategory ? gameCategory : ''}
						variant="outlined"
						sx={{ marginLeft: '10px' }}
						size="small"
					/>
				</div>

				<div
					style={{
						display: showProcess ? 'flex' : 'none',
						marginTop: '10px',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<FormGroup row={false} sx={{ marginLeft: '10px' }}>
						<FormControlLabel
							control={
								<Radio
									onChange={handleChange}
									checked={
										Number(selectedValue) ===
										Constant.STATUS_PROCESSING
									}
									value={Constant.STATUS_PROCESSING}
									name="radio-buttons"
								/>
							}
							label={Constant.LABEL.PROCESSING}
						/>
						<FormControlLabel
							control={
								<Radio
									onChange={handleChange}
									checked={
										Number(selectedValue) ===
										Constant.STATUS_PROCESSED
									}
									value={Constant.STATUS_PROCESSED}
									name="radio-buttons"
								/>
							}
							label={Constant.LABEL.PROCESSED}
						/>
					</FormGroup>
				</div>
			</Modal.Body>
			<Modal.Footer className="footerModal">
				<Button
					className={
						btnCancelShow ? 'btnModal btnModalCancel' : 'd-none'
					}
					onClick={onHide}
				>
					{Constant.BUTTON.CANCEL}
				</Button>
				<Button
					className={
						btnAgreeShow ? 'btnModal btnModalAgree' : 'd-none'
					}
					onClick={(e) => onModalClose(e)}
				>
					{Constant.BUTTON.AGREE}
				</Button>
				<Button
					className={
						btnConfirmShow ? 'btnModal btnModalAgree' : 'd-none'
					}
					disabled={isLoadingBtn}
					onClick={(e) => {
						setIsLoadingBtn(true);
						onModalClose(e, {
							number,
							reason,
							gameCategory,
							selectedValue,
						});
					}}
				>
					{Constant.BUTTON.CONFIRM}
				</Button>
				<Button
					className={btnCloseShow ? 'btnModal' : 'd-none'}
					onClick={onHide}
				>
					{Constant.BUTTON.CLOSE}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CustomModal;
