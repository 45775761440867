import './navbar.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../context/SiteContext';
import * as FaIcons from 'react-icons/fa';
import Badge from '@mui/material/Badge';
// import BreadCrumb from '../breadcrumb/BreadCrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import ApiRequest from '../../api/axios';
import Authen from '../../common/helper';
import Constant from '../../common/constant/constant';
import { useSnackbar } from 'notistack';

const Navbar = () => {
	const { state, dispatch } = useContext(SiteContext);
	const [selectedItem, setSelectedItem] = useState('');
	const [game, setGame] = useState('');
	const [listGame, setListGame] = useState([]);
	const navigate = useNavigate();
	const localLogin = JSON.parse(window.localStorage.getItem('LOGIN'));
	const login = state?.login?.email ? state?.login : localLogin;
	const { enqueueSnackbar } = useSnackbar();

	const logout = async () => {
		// if used in more components, this should be in context
		// axios to /logout endpoint
		try {
			await ApiRequest(Constant.DELETE, Constant.LOGOUT_URL);
			dispatch({ type: 'LOGOUT' });
			window.localStorage.clear();
			navigate('/login');
		} catch (err) {
			enqueueSnackbar(err.response?.data.message, { variant: 'error' });
		}
	};

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item, i) => {
				return (
					<MenuItem value={item?._id} key={item?._id}>
						{item.thumbnail === 'catrestaurent.webp' ? (
							<SportsEsportsIcon />
						) : (
							<img
								src={`https://upload.goodgames.vn/uploads/${item.thumbnail}`}
								alt=""
								style={{
									width: '20px',
									height: '20px',
									marginRight: '20px',
								}}
							/>
						)}
						<span className="game">{item?.title}</span>
					</MenuItem>
				);
			});
		}
	};

	const handleChangeGame = (event) => {
		const gameId = event.target.value;
		setGame(gameId);
		const game = listGame.find((e) => e._id === gameId)
		dispatch({ type: 'LISTGAME', navbar: { id: game.id, gameId: gameId} });
	};

	const init = async () => {
		Authen(login?.accessToken);
		const response = await ApiRequest(
			Constant.GET,
			Constant.GAME_URL,
			null,
			null
		);
		if (!response?.data) return;
		const data = response?.data?.data.games.list;
		if (data.length > Constant.ZERO) {
			const dataGame = [];
			if (login?.project?.length > 0) {
				for (const e of login?.project) {
					const i = data.findIndex((v) => v.id === e);
					if (i !== -1) dataGame.push(data[i]);
				}
			}

			setListGame(dataGame?.length > 0 ? dataGame : data);
			setGame(data[0]?._id);
			dispatch({ type: 'LISTGAME', navbar: { id: data[0]?.id, gameId: data[0]?._id } });
			// window.localStorage.setItem('GAME', id);
		}
	};

	useEffect(() => {
		if (state?.login?.accessToken && listGame.length <= 0) {
			init();
		}
	}, [state]);

	return (
		<div className="navbar">
			<div className="wrapper">
				<div className="menu-bars">
					<FaIcons.FaBars
						onClick={() => dispatch({ type: 'BAR_TOGGLE' })}
					/>
				</div>

				<FormControl sx={{ minWidth: 190, top: -5 }} size="small">
					<Select
						id="demo-select-small"
						value={game}
						onChange={handleChangeGame}
					>
						{listItems(listGame)}
					</Select>
				</FormControl>
				<div className="search">
					<input type="text" placeholder="Search..." />
					<SearchOutlinedIcon />
				</div>
				<div className="items">
					{/* {<div className="item">
						<Badge badgeContent={1} color="error">
							<NotificationsNoneOutlinedIcon className="icon" />
						</Badge>
					</div> } */}
					<Dropdown>
						<Dropdown.Toggle
							variant=""
							id="dropdown-basic"
							className="item"
						>
							<AccountCircleIcon className="avatar" />
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="item" disabled>
								<AccountCircleIcon className="avatarDisable" />
								<span className="titles">
									<p className="title">{state.login?.name}</p>
									<p className="title">
										{state.login?.email}
									</p>
								</span>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item
								className="item"
								as={Link}
								to={'/profile'}
							>
								<img
									src="/asset/icons/tai-khoan.png"
									className="icon"
									alt=""
								/>
								<span className="titles">
									{Constant.LABEL.ACCOUNT}
								</span>
							</Dropdown.Item>
							<Dropdown.Item
								className="item"
								as={Link}
								to={'/setting'}
							>
								<img
									src="/asset/icons/cai-dat.png"
									className="icon"
									alt=""
								/>
								<span className="titles">
									{Constant.LABEL.SETTING}
								</span>
							</Dropdown.Item>
							<Dropdown.Item className="item">
								<img
									src="/asset/icons/dang-xuat.png"
									className="icon"
									alt=""
								/>
								<span
									className="titles"
									style={{ width: '100%' }}
									onClick={logout}
								>
									{Constant.LABEL.LOGOUT}
								</span>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
