import React from 'react';
import './index.css';
import App from './App';

import { SiteContextProvider } from './context/SiteContext';
import { BrowserRouter } from 'react-router-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';

function MyApp() {
	return (
		<SiteContextProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</SiteContextProvider>
	);
}

const root = document.getElementById('root');
if (root && root.innerHTML !== '') {
	hydrateRoot(root, <MyApp />);
} else {
	createRoot(root).render(<MyApp />);
}
