import './overviewreport.scss';
import Column from '../../components/charts/Column';
import Line from '../../components/charts/Line';
import Pie from '../../components/charts/Pie';
import { TextField, Box, FormControl, Select, MenuItem, ToggleButtonGroup, Divider } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { platformData } from '../../data/mockDataUser';
import { REVData, DAUData, MKTData, REVDetailData, KeepUserData, KPIData } from '../../data/dataChart';
import { DatePicker } from 'antd';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import ApiRequest from '../../api/axios';
import Constant from '../../common/constant/constant';
import { SiteContext } from '../../context/SiteContext';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const ToggleButton = styled(MuiToggleButton)(() => ({
	'&.Mui-selected, &.Mui-selected:hover': {
		color: 'white',
		backgroundColor: 'red',
	},
}));

const OverviewReport = () => {
	const { state, dispatch } = useContext(SiteContext);

	const [categoriesREV, setCategoriesREV] = useState([]);
	const [seriesREV, setSeriesREV] = useState([]);

	const [categoriesDAU, setCategoriesDAU] = useState([]);
	const [seriesDAU, setSeriesDAU] = useState([]);

	const [categoriesMKT, setCategoriesMKT] = useState([]);
	const [seriesMKT, setSeriesMKT] = useState([]);

	const [categoriesREVDetail, setCategoriesREVDetail] = useState([]);
	const [seriesREVDetail, setSeriesREVDetail] = useState([]);

	const [categoriesKeepUser, setCategoriesKeepUser] = useState([]);
	const [seriesKeepUser, setSeriesKeepUser] = useState([]);

	const [categoriesKPI, setCategoriesKPI] = useState([]);
	const [seriesKPI, setSeriesKPI] = useState([]);

	const [alignmentREV, setAlignmentREV] = useState('REV');
	const [alignmentDAU, setAlignmentDAU] = useState('DAU');
	const [alignmentMKT, setAlignmentMKT] = useState('Ads');
	const [alignmentREVDetail, setAlignmentREVDetail] = useState('percent');
	const [alignmentKeepUser, setAlignmentKeepUser] = useState('new');
	const [alignmentKPI, setAlignmentKPI] = useState('REVMonth');
	const [checked, setChecked] = useState(false);

	const [dataGame, setDataGame] = useState({});
	const [from, setFrom] = useState(moment().format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
	const [isLoading, setIsloading] = useState(false);

	const contextLogin = state?.login;
	const localLogin = JSON.parse(window.localStorage.getItem('LOGIN'));
	let loginToken = contextLogin?.email ? contextLogin : localLogin;
	const login = state?.login?.email ? state?.login : localLogin;

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	const handleChangeREV = (event, newAlignment) => {
		setCategoriesREV([]);
		setSeriesREV([]);
		setAlignmentREV(newAlignment);
		console.log(newAlignment);
		setDataChartREV(newAlignment);
	};

	const setDataChartREV = () => {
		let xaxis = [];
		let series = [];

		for (let item of REVData) {
			switch (item.tab) {
				case alignmentREV:
					console.log('data ' + item.tab + ': ' + JSON.stringify(item.data));
					xaxis = item.data.categories;
					series = item.data.series;
					break;
				default:
					break;
			}
		}

		console.log(xaxis);
		setCategoriesREV(xaxis);
		console.log(series);
		setSeriesREV(series);
	};

	const handleChangeDAU = (event, newAlignment) => {
		setCategoriesDAU([]);
		setSeriesDAU([]);
		setAlignmentDAU(newAlignment);
		console.log(newAlignment);
		setDataChartDAU(newAlignment);
	};

	const setDataChartDAU = () => {
		let xaxis = [];
		let series = [];

		for (let item of DAUData) {
			switch (item.tab) {
				case alignmentDAU:
					console.log('data ' + item.tab + ': ' + JSON.stringify(item.data));
					xaxis = item.data.categories;
					series = item.data.series;
					break;
				default:
					break;
			}
		}

		console.log(xaxis);
		setCategoriesDAU(xaxis);
		console.log(series);
		setSeriesDAU(series);
	};

	const handleChangeMKT = (event, newAlignment) => {
		setCategoriesMKT([]);
		setSeriesMKT([]);
		setAlignmentMKT(newAlignment);
		console.log(newAlignment);
		setDataChartMKT(newAlignment);
	};

	const setDataChartMKT = () => {
		let xaxis = [];
		let series = [];

		for (let item of MKTData) {
			switch (item.tab) {
				case alignmentMKT:
					console.log('data ' + item.tab + ': ' + JSON.stringify(item.data));
					xaxis = item.data.categories;
					series = item.data.series;
					break;
				default:
					break;
			}
		}

		console.log(xaxis);
		setCategoriesMKT(xaxis);
		console.log(series);
		setSeriesMKT(series);
	};

	const handleChangeREVDetail = (event, newAlignment) => {
		setCategoriesREVDetail([]);
		setSeriesREVDetail([]);
		console.log(newAlignment);
		setDataChartREVDetail(newAlignment);
		setAlignmentREVDetail(newAlignment);
	};

	const setDataChartREVDetail = () => {
		let categories = [];
		let series = [];

		categories = REVDetailData.labels;
		series = REVDetailData.series;

		console.log(categories);
		setCategoriesREVDetail(categories);
		console.log(series);
		setSeriesREVDetail(series);
		setChecked(alignmentREVDetail === 'percent' ? false : true);
	};

	const handleChangeKeepUser = (event, newAlignment) => {
		setCategoriesKeepUser([]);
		setSeriesKeepUser([]);
		setAlignmentKeepUser(newAlignment);
		console.log(newAlignment);
		setDataChartKeepUser(newAlignment);
	};

	const setDataChartKeepUser = () => {
		let xaxis = [];
		let series = [];

		for (let item of KeepUserData) {
			switch (item.tab) {
				case alignmentKeepUser:
					console.log('data ' + item.tab + ': ' + JSON.stringify(item.data));
					xaxis = item.data.categories;
					series = item.data.series;
					break;
				default:
					break;
			}
		}

		console.log(xaxis);
		setCategoriesKeepUser(xaxis);
		console.log(series);
		setSeriesKeepUser(series);
	};

	const handleChangeKPI = (event, newAlignment) => {
		setCategoriesKPI([]);
		setSeriesKPI([]);
		setAlignmentKPI(newAlignment);
		console.log(newAlignment);
		setDataChartKPI(newAlignment);
	};

	const setDataChartKPI = () => {
		let xaxis = [];
		let series = [];

		for (let item of KPIData) {
			switch (item.tab) {
				case alignmentKPI:
					console.log('data ' + item.tab + ': ' + JSON.stringify(item.data));
					xaxis = item.data.categories;
					series = item.data.series;
					break;
				default:
					break;
			}
		}

		console.log(xaxis);
		setCategoriesKPI(xaxis);
		console.log(series);
		setSeriesKPI(series);
	};

	const getDataApi = async () => {
		const gameId = state?.navbar?.gameId;
		if (!gameId) return;
		const response = await ApiRequest(Constant.GET, `${Constant.GAME_URL}/game/${gameId}/stats?from=${from}&to=${to}`, null, null, login?.accessToken);
		if (!response?.data) return;
		const data = response?.data?.data.game;
		setDataGame(data);
	};

	const onDateChange = (date, dateString) => {
		console.log('dateString', dateString);
		setFrom(dateString[0]);
		setTo(dateString[1]);
    };
    
    const handleSearch = async () => {
        setIsloading(true);
        await getDataApi();
        setIsloading(false);
    }

	useEffect(() => {
		getDataApi();
		setDataChartREV();
		setDataChartDAU();
		setDataChartMKT();
		setDataChartREVDetail();
		setDataChartKeepUser();
		setDataChartKPI();
	}, [state?.navbar]);

	return (
		<div className="home-overviewreport">
			<Box m="20px">
				<Box display="flex" alignItems="flex-end" flexDirection="row">
					<Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column">
						<span>Thời gian</span>
						<RangePicker
							format="YYYY-MM-DD"
							defaultValue={[dayjs(from, 'YYYY-MM-DD'), dayjs(to, 'YYYY-MM-DD')]}
							size="large"
							style={{ backgroundColor: '#F5F8FF' }}
							onChange={onDateChange}
						/>
					</Box>
					{/* <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column" ml="10px">
						<span>Platform</span>
						<FormControl sx={{ minWidth: 190 }} size="small">
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								// value={department}
								// onChange={handleChangeDepartment}
							>
								{listItems(platformData)}
							</Select>
						</FormControl>
					</Box> */}
					<Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column" ml="10px">
						<Button className="btnRed" onClick={handleSearch} disabled={isLoading}>
							{Constant.BUTTON.SEARCH}
						</Button>
					</Box>
				</Box>
				<Box mt="10px" display="flex" alignItems="center" justifyContent="space-around" flexDirection="row">
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>REV</h4>
						<h4>{dataGame?.revenue || 0}</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>DAU</h4>
						<h4>{dataGame?.dailyActivUser || 0}</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>NRU</h4>
						<h4>{dataGame?.newRegisterdUser || 0}</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>PU</h4>
						<h4>{dataGame?.paidUser || 0}</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>ARPU</h4>
						<h4>0</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>ARPPU</h4>
						<h4>0</h4>
					</Box>
					<Divider orientation="vertical" style={{ fontWeight: 'bold', backgroundColor: 'black', margin: '0' }} flexItem></Divider>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<h4>RETENTION</h4>
						<h4>0</h4>
					</Box>
				</Box>
				<Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>Chỉ số</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentREV || 'REV'}
							exclusive
							onChange={handleChangeREV}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="REV" aria-label="left aligned">
								REV
							</ToggleButton>
							<ToggleButton className="toggleButton" value="PU" aria-label="left aligned">
								PU
							</ToggleButton>
							<ToggleButton className="toggleButton" value="ARPPU" aria-label="left aligned">
								ARPPU
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Line categoriesData={categoriesREV} seriesData={seriesREV} />
				</Box>

				<Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>Tình hình online</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentDAU || 'DAU'}
							exclusive
							onChange={handleChangeDAU}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="DAU" aria-label="left aligned">
								DAU
							</ToggleButton>
							<ToggleButton className="toggleButton" value="New" aria-label="left aligned">
								New user
							</ToggleButton>
							<ToggleButton className="toggleButton" value="Old" aria-label="left aligned">
								Old user
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Line categoriesData={categoriesDAU} seriesData={seriesDAU} />
				</Box>

				{/* <Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>Buget MKT</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentMKT || 'Ads'}
							exclusive
							onChange={handleChangeMKT}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="Ads" aria-label="left aligned">
								Ads
							</ToggleButton>
							<ToggleButton className="toggleButton" value="Branding" aria-label="left aligned">
								Branding
							</ToggleButton>
							<ToggleButton className="toggleButton" value="Other" aria-label="left aligned">
								Khác
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Column categoriesData={categoriesMKT} seriesData={seriesMKT} />
				</Box> */}

				<Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>Doanh thu</span>
						<span style={{ marginLeft: '10px' }}>Đơn vị</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentREVDetail || 'percent'}
							exclusive
							onChange={handleChangeREVDetail}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="percent" aria-label="left aligned">
								Phần trăm
							</ToggleButton>
							<ToggleButton className="toggleButton" value="number" aria-label="left aligned">
								Số
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Pie categoriesData={categoriesREVDetail} seriesData={seriesREVDetail} checked={checked} />
				</Box>

				<Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>Giữ chân người dùng</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentKeepUser || 'new'}
							exclusive
							onChange={handleChangeKeepUser}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="new" aria-label="left aligned">
								Mới
							</ToggleButton>
							<ToggleButton className="toggleButton" value="old" aria-label="left aligned">
								Cũ
							</ToggleButton>
							<ToggleButton className="toggleButton" value="purchase" aria-label="left aligned">
								Nạp tiền
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Column categoriesData={categoriesKeepUser} seriesData={seriesKeepUser} />
				</Box>

				{/* <Box mt="20px" display="flex" alignItems="center" flexDirection="column" height="35vh" width="99%">
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" width="100%">
						<span>KPI</span>
						<ToggleButtonGroup
							color="primary"
							value={alignmentKPI || 'REVMonth'}
							exclusive
							onChange={handleChangeKPI}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton className="toggleButton" value="REVMonth" aria-label="left aligned">
								Doanh thu tháng
							</ToggleButton>
							<ToggleButton className="toggleButton" value="BugetMKT" aria-label="left aligned">
								Buget MKT
							</ToggleButton>
							<ToggleButton className="toggleButton" value="NRU" aria-label="left aligned">
								NRU
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Column categoriesData={categoriesKPI} seriesData={seriesKPI} />
				</Box> */}
			</Box>
		</div>
	);
};

export default OverviewReport;
