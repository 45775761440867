import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './custommodal.scss';
import { useEffect, useState } from 'react';
import Constant from '../../common/constant/constant';
import JSONView from 'react-json-view';

const ModalShowJSON = ({
	message,
	btnCloseShow,
	show,
	onHide,
}) => {
	const dataJSON = typeof message === 'string' ? JSON.parse(message) : message;

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName="modal-90w max-width-70"
			// centered
		>
			<Modal.Body className="bodyModal">
				<JSONView
					src={dataJSON}
					name={false}
					theme="monokai" 
					displayDataTypes={false}
					style={{ marginTop: '20px', textAlign: 'left' }}
				 />
			</Modal.Body>

			<Modal.Footer className="footerModal">
				<Button
					className={btnCloseShow ? 'btnModal' : 'd-none'}
					onClick={onHide}
				>
					{Constant.BUTTON.CLOSE}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalShowJSON;
