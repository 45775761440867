import "./manageggold.scss";
import { TextField, Box, Typography, FormGroup, FormControlLabel, Radio } from "@mui/material";
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataUsers, mockDataUserHistory } from "../../data/mockDataUser";
import Modal from "../../components/modal/CustomModal";
import Constant from "../../common/constant/constant";
import Message from "../../common/message/message";
import ApiRequest from "../../api/axios";
import { renameKey } from "../../common/util";

const ManageGGold = () => {

    const [modalShow, setModalShow] = useState(false);
    const [message, setMessage] = useState(Constant.EMPTY);
    const [inputType, setInputType] = useState(false);
    const [inputSearch, setInputSearch] = useState(Constant.EMPTY);
    const [flgInput, setFlgInput] = useState(Constant.LABEL.USERNAME);
    const [userInfo, setUserInfo] = useState();
    const [dataAfterUpdate, setDataAfterUpdate] = useState([]);

    const columnsAfterUpdate = [
        {
            field: "order",
            headerName: "STT",
            headerAlign: "center",
            align: "center",
            cellClassName: "order-column--cell"
        },
        {
            field: "player.username",
            headerName: "User name",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "playerName-column--cell",
            valueGetter: (params) => `${params?.row?.player?.username || Constant.EMPTY}`
        },
        {
            field: "player.id",
            headerName: "ID tài khoản",
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "playerId-column--cell",
            valueGetter: (params) => `${params?.row?.player?.id || Constant.EMPTY}`
        },
        {
            field: "player",
            headerName: "Email",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "playerEmail-column--cell",
            valueGetter: (params) => `${params?.row?.player?.email || Constant.EMPTY}`
        },
        {
            field: "beforeGGold",
            headerName: "GGold ban đầu",
            flex: 1,
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "gGold-column--cell"
        },
        {
            field: "afterGGold",
            headerName: "GGold sau khi đổi",
            flex: 1,
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "afterGGold-column--cell"
        },
        // {
        //     field: "",
        //     headerName: "Thao tác",
        //     flex: 1,
        //     headerAlign: "center",
        //     align: "center",
        //     renderCell: () => {
        //         return (
        //             <Box
        //                 width="60%"
        //                 m="0 auto"
        //                 p="5px"
        //                 display="flex"
        //                 justifyContent="center"
        //             >
        //             </Box>
        //         );
        //     },
        // },
    ];

    const handleIncrease = () => {
        setMessage(Message.MS0010);
        setInputType(true);
        setModalShow(true);
    }

    const handleDecrease = () => {
        setMessage(Message.MS0011);
        setInputType(false);
        setModalShow(true);
    }

    const handleChange = (event) => {
        setFlgInput(event.target.value);
        console.log(event.target.value);
    };

    const handleCloseModal = (e, data) => {
        console.log(data);
        handleUpdateGGold(data);
        setModalShow(false);
    }

    const handleUpdateGGold = async (ggold) => {
        if (!ggold) alert(Message.ER0012);
        else {
            setDataAfterUpdate([]);
            let body = {
                playerId: userInfo?.id,
                gGold: Number(ggold),
                type: inputType
            };
            const response = await ApiRequest(Constant.POST, Constant.MANAGERGGOLD_URL, body);
            if (!response?.data) return;
            const data = response?.data?.data;
            console.log(data?.managerggolds);
            renameKey(data.managerggolds, '_id', 'id');
            setDataAfterUpdate(dataAfterUpdate => [data?.managerggolds, ...dataAfterUpdate]);
        }
    }

    const handleSearch = async () => {
        if (!inputSearch) alert(Message.ER0011);
        else {
            setUserInfo();
            let username = "username=" + inputSearch;
            let id = "id=" + inputSearch;
            let query = `?${flgInput === Constant.LABEL.USERNAME ? username : id}`;
            let response = await ApiRequest(Constant.GET, Constant.PLAYER_URL + "/player" + query);
            if (!response?.data) return;
            if (!response?.data?.data?.player?.list[0]?.id) return alert(Message.ER0015);
            const data = response?.data?.data?.player?.list;
            if (data[0]?.id) {
                setUserInfo(data[0]);
            }

            response = await ApiRequest(Constant.GET, Constant.MANAGERGGOLD_URL + query);
            console.log(response?.data);
            if (!response?.data) return;
            const dataManagerGGolds = response?.data?.data?.managerggolds;
            console.log(dataManagerGGolds?.list);
            if (dataManagerGGolds?.list) {
                dataManagerGGolds?.list.forEach(obj => renameKey(obj, '_id', 'id'));
                for (let i = 0; i < dataManagerGGolds.list.length; i++) {
                    dataManagerGGolds.list[i].order = i + 1;
                }
                setDataAfterUpdate(dataManagerGGolds?.list);
            }
        }
    }

    return (
        <div className="home-manageggold">
            <Box m="20px">
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column">
                        <FormGroup row={false} sx={{ marginLeft: "10px", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                            <FormControlLabel control={<Radio onChange={handleChange} checked={flgInput === Constant.LABEL.USERNAME}
                                value={Constant.LABEL.USERNAME} name="radio-buttons" />} label={Constant.LABEL.USER_NAME} />
                            <FormControlLabel control={<Radio onChange={handleChange} checked={flgInput === Constant.LABEL.IDUSER}
                                value={Constant.LABEL.IDUSER} name="radio-buttons" />} label={Constant.LABEL.PLAYER_ID} />
                        </FormGroup>
                        <TextField
                            placeholder="Nhập thông tin"
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                            variant="outlined"
                            size="small"
                            sx={{ width: "100%" }}
                        />
                    </Box>
                    <Box display="flex" alignSelf="flex-end" flexDirection="column" ml="10px">
                        <Button className="btnRed" onClick={handleSearch}>{Constant.BUTTON.SEARCH}</Button>
                    </Box>
                </Box>
                {userInfo?.id &&
                    <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                        <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="userItem borderUserItem">{Constant.LABEL.USER_NAME}</span>
                                <TextField
                                    value={userInfo?.username || Constant.EMPTY}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    size="small"
                                    className="userItem"
                                />
                            </Box>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="userItem borderUserItem">{Constant.LABEL.PLAYER_ID}</span>
                                <TextField
                                    value={userInfo?.id || Constant.EMPTY}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    size="small"
                                    className="userItem"
                                />
                            </Box>
                        </Box>
                        <Box ml="10px" display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="userItem borderUserItem">{Constant.LABEL.EMAIL}</span>
                                <TextField
                                    value={userInfo?.email || Constant.EMPTY}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    size="small"
                                    className="userItem"
                                />
                            </Box>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="userItem borderUserItem">{Constant.LABEL.TOTALGGOLD}</span>
                                <TextField
                                    value={userInfo?.wallet?.gGold || 0}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    size="small"
                                    className="userItem"
                                />
                            </Box>
                        </Box>
                    </Box>
                }
                {userInfo?.id &&
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                        <Box>
                            <span style={{ fontWeight: "bold" }}>{Constant.LABEL.PLAYER_PURCHASEGG_HISTORY}</span>
                        </Box>
                        <Box>
                            <Button className="btnGreen" onClick={handleIncrease} >{Constant.BUTTON.INCREASE}</Button>
                            <Button className="btnRed" style={{ marginLeft: "10px" }} onClick={handleDecrease}>{Constant.BUTTON.DECREASE}</Button>
                        </Box>
                    </Box>
                }
                {dataAfterUpdate?.length > 0 &&
                    <Box
                        m="10px 0 0 0"
                        height="64vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // color: colors.greenAccent[300],
                                //borderBottom: "none",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiTablePagination-toolbar": {
                                display: "flex",
                                alignItems: "baseline"
                            },
                        }}
                    >
                        <DataGrid rows={dataAfterUpdate} columns={columnsAfterUpdate} autoPageSize />
                    </Box>
                }
            </Box>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message={message}
                btnCancelShow="true"
                btnAgreeShow="true"
                showInputNumber={true}
                onCloseModal={handleCloseModal}
            />
        </div>
    )
}

export default ManageGGold