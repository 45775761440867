import './sidebar.scss';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SiteContext } from '../../context/SiteContext';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ApiRequest from '../../api/axios';
import Constant from '../../common/constant/constant';

const Sidebar = () => {
	const { state, dispatch } = useContext(SiteContext);
	const [openReportMenu, setOpenReportMenu] = useState(true);
	const [openCategoryMenu, setOpenCategoryMenu] = useState(true);
	const [openProjectMenu, setOpenProjectMenu] = useState(true);
	const [openToolMenu, setOpenToolMenu] = useState(true);

	const [reportMenu, setReportMenu] = useState([]);
	const [categoryMenu, setCategoryMenu] = useState([]);
	const [projectMenu, setProjectMenu] = useState([]);
	const [toolMenu, setToolMenu] = useState([]);
	const navigate = useNavigate();
	const [isLoadingPage, setIsLoadingPage] = useState(true);

	// const dataLocal = JSON.parse(window.localStorage.getItem("LOGIN"));
	//assigning location variable
	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split('/');

	const localLogin = JSON.parse(window.localStorage.getItem('LOGIN'));
	const login = state?.login?.email ? state?.login : localLogin;

	const toggleReportMenu = () => {
		setOpenReportMenu(!openReportMenu);
	};

	const toggleCategoryMenu = () => {
		setOpenCategoryMenu(!openCategoryMenu);
	};

	const toggleProjectMenu = () => {
		setOpenProjectMenu(!openProjectMenu);
	};

	const toggleToolMenu = () => {
		setOpenToolMenu(!openToolMenu);
	};

	const movePage = (item) => {
		dispatch({ type: 'DEFAULTSTATE' });
		navigate(`/${item.code.toLowerCase()}`);
	};

	const listMenu = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item, i) => {
				return (
					// <Link
					// 	to={() => `/${item.code.toLowerCase()}`}
					// 	style={{ textDecoration: 'none' }}
					// 	key={item.code.toLowerCase()}
					// >
					<li
						className={
							splitLocation[1] === item.code.toLowerCase()
								? 'active'
								: ''
						}
						key={i}
						onClick={() => movePage(item)}
					>
						<FiberManualRecordOutlinedIcon
							className={
								splitLocation[1] === item.code.toLowerCase()
									? 'active'
									: 'icon'
							}
						/>
						<span
							className={
								splitLocation[1] === item.code.toLowerCase()
									? 'active'
									: ''
							}
						>
							{item.name}
						</span>
					</li>
					// </Link>
				);
			});
		}
	};

	const init = async () => {
		if (
			reportMenu?.lenght > 0 &&
			categoryMenu?.length > 0 &&
			projectMenu?.length > 0 &&
			toolMenu?.length > 0
		)
			return;
		const menu = { menu: login?.menu };
		const response = await ApiRequest(
			Constant.POST,
			Constant.MENUBYCODE_URL,
			menu,
			null,
			login?.accessToken
		);
		if (!response?.data) return;
		const data = response?.data?.data.menus;

		const report = [];
		const category = [];
		const project = [];
		const tool = [];

		for (let i = 0; i < data.length; i++) {
			if (data[i].menuType === Constant.VALUE.TYPE_REPORT) {
				report.push(data[i]);
			} else if (data[i].menuType === Constant.VALUE.TYPE_CATEGORY) {
				category.push(data[i]);
			} else if (data[i].menuType === Constant.VALUE.TYPE_PROJECT) {
				project.push(data[i]);
			} else {
				tool.push(data[i]);
			}
		}

		report.sort((a, b) => {
			return a.order - b.order;
		});

		category.sort((a, b) => {
			return a.order - b.order;
		});

		project.sort((a, b) => {
			return a.order - b.order;
		});

		tool.sort((a, b) => {
			return a.order - b.order;
		});

		setReportMenu([...report]);
		setCategoryMenu([...category]);
		setProjectMenu([...project]);
		setToolMenu([...tool]);
	};

	useEffect(() => {
		if (state?.login?.accessToken && isLoadingPage) {
			setIsLoadingPage(false);
			init();
		}
	}, [state]);

	return (
		<div className={state?.barMode ? 'sidebar' : 'sidebar hide'}>
			<div className="top">
				<Link to="/" style={{ textDecoration: 'none' }}>
					<img
						src="./asset/images/logongang.png"
						className="logo"
						alt=""
					/>
				</Link>
			</div>
			<hr />
			<div className="center">
				<ul>
					{reportMenu.length > Constant.ZERO ? (
						<div
							className="group-titles"
							onClick={toggleReportMenu}
						>
							<img
								src="./asset/icons/presention-chart.png"
								className="icon"
								alt=""
							/>
							<span className="title">
								{Constant.LABEL.MENU_REPORT}
							</span>
							{openReportMenu ? (
								<ArrowDropUpIcon className="iconArrow" />
							) : (
								<ArrowDropDownIcon className="iconArrow" />
							)}
						</div>
					) : null}
					{openReportMenu ? listMenu(reportMenu) : null}

					{categoryMenu.length > Constant.ZERO ? (
						<div
							className="group-titles"
							onClick={toggleCategoryMenu}
						>
							<img
								src="./asset/icons/hang-muc.png"
								className="icon"
								alt=""
							/>
							<span className="title">
								{Constant.LABEL.MENU_CATEGORY}
							</span>
							{openCategoryMenu ? (
								<ArrowDropUpIcon className="iconArrow" />
							) : (
								<ArrowDropDownIcon className="iconArrow" />
							)}
						</div>
					) : null}
					{openCategoryMenu ? listMenu(categoryMenu) : null}

					{projectMenu.length > Constant.ZERO ? (
						<div
							className="group-titles"
							onClick={toggleProjectMenu}
						>
							<img
								src="./asset/icons/bao-cao.png"
								className="icon"
								alt=""
							/>
							<span className="title">
								{Constant.LABEL.MENU_PJMANAGER}
							</span>
							{openProjectMenu ? (
								<ArrowDropUpIcon className="iconArrow" />
							) : (
								<ArrowDropDownIcon className="iconArrow" />
							)}
						</div>
					) : null}
					{openProjectMenu ? listMenu(projectMenu) : null}

					{toolMenu.length > Constant.ZERO ? (
						<div className="group-titles" onClick={toggleToolMenu}>
							<img
								src="./asset/icons/cong-cu.png"
								className="icon"
								alt=""
							/>
							<span className="title">
								{Constant.LABEL.MENU_TOOL}
							</span>
							{openToolMenu ? (
								<ArrowDropUpIcon className="iconArrow" />
							) : (
								<ArrowDropDownIcon className="iconArrow" />
							)}
						</div>
					) : null}
					{openToolMenu ? listMenu(toolMenu) : null}
				</ul>
			</div>
			<div className="bottom"></div>
		</div>
	);
};

export default Sidebar;
