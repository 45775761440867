import React, { useState } from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';

const Column = ({ categoriesData, seriesData }) => {

  const [seriesColumn, setSeriesColumn] = useState([]);
  const [categoriesColumn, setCategoriesColumn] = useState([]);

  useEffect(() => {
    setSeriesColumn(seriesData);
    setCategoriesColumn(categoriesData);
  }, [seriesData, categoriesData]);

  const options = () => {
    return {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categoriesColumn,
      },
      yaxis: {
        // title: {
        //   text: '$ (thousands)'
        // }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " players"
          }
        }
      }
    };
  }

  const series = () => {
    return seriesColumn;
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Chart options={options()} series={series()} type="bar" width="100%" height="100%" />
    </div>
  );
}

export default Column;