import "./resendggold.scss";
import { TextField, Box, Typography } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataResend } from "../../data/mockDataUser";
import Modal from "../../components/modal/CustomModal";

const ManageGGold = () => {

    const [modalShow, setModalShow] = useState(false);
    const [message, setMessage] = useState('');

    const columns = [
        {
            field: "order",
            headerName: "STT",
            headerAlign: "center",
            align: "center",
            cellClassName: "order-column--cell"
        },
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "user_name-column--cell",
        },
        {
            field: "game",
            headerName: "Game",
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "account_id-column--cell"
        },
        {
            field: "server_game",
            headerName: "Server Game",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "email-column--cell"
        },
        {
            field: "platform",
            headerName: "Platform",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "game-column--cell"
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 1,
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "sum_ggold-column--cell"
        },
        {
            field: "time",
            headerName: "Time",
            flex: 1,
            type: "dateTime",
            headerAlign: "center",
            align: "center",
            cellClassName: "sum_ggold-column--cell"
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row: { status } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                    >
                        <Typography sx={{ ml: "5px" }}>
                            <span style={{ color: status === "Fail" ? "red" : "green" }}>{status}</span>
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "",
            headerName: "",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: () => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                    >
                    </Box>
                );
            },
        },
    ];

    const columns2 = [
        {
            field: "order",
            headerName: "STT",
            headerAlign: "center",
            align: "center",
            cellClassName: "order-column--cell"
        },
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "transaction_id-column--cell",
        },
        {
            field: "game",
            headerName: "Game",
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "game-column--cell"
        },
        {
            field: "server_game",
            headerName: "Server Game",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "server_game-column--cell"
        },
        {
            field: "platform",
            headerName: "Platform",
            flex: 1,
            headerAlign: "center",
            align: "center",
            cellClassName: "platform-column--cell"
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 1,
            type: "number",
            headerAlign: "center",
            align: "center",
            cellClassName: "amount-column--cell"
        },
        {
            field: "time",
            headerName: "Time",
            flex: 1,
            type: "dateTime",
            headerAlign: "center",
            align: "center",
            cellClassName: "time-column--cell"
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row: { status } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                    >
                        <Typography sx={{ ml: "5px" }}>
                            <span style={{ color: status === "Fail" ? "red" : "green" }}>{status}</span>
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "",
            headerName: "Thao tác",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: () => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                    >
                    </Box>
                );
            },
        },
    ];

    return (
        <div className="home-resendggold">
            <Box m="20px">
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column">
                        <span>Transaction ID</span>
                        <TextField
                            placeholder="Nhập Transaction ID"
                            // onChange={(e) => setName(e.target.value)}
                            // value={name}
                            variant="outlined"
                            size="small"
                        />
                    </Box>
                    <Box display="flex" alignSelf="flex-end" flexDirection="column" ml="10px">
                        <Button className="btnRed">Tìm kiếm</Button>
                    </Box>
                </Box>

                <Box
                    m="10px 0 0 0"
                    height="40vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            // color: colors.greenAccent[300],
                            //borderBottom: "none",
                        },
                        "& .MuiDataGrid-row": {
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiTablePagination-toolbar": {
                            display: "flex",
                            alignItems: "baseline"
                        },
                    }}
                >
                    <DataGrid rows={mockDataResend} columns={columns} autoPageSize />
                </Box>

                <Box display="flex" alignSelf="flex-start" flexDirection="column" ml="10px">
                    <Button className="btnGreen">Resend</Button>
                </Box>

                <Box
                    m="10px 0 0 0"
                    height="40vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            // color: colors.greenAccent[300],
                            //borderBottom: "none",
                        },
                        "& .MuiDataGrid-row": {
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },

                        "& .MuiTablePagination-toolbar": {
                            display: "flex",
                            alignItems: "baseline"
                        },
                    }}
                >
                    <DataGrid rows={mockDataResend} columns={columns2} autoPageSize />
                </Box>
            </Box>
        </div>
    )
}

export default ManageGGold