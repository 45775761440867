import {
	Box,
	FormControl,
	Grid,
	MenuItem,
	Pagination,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker } from 'antd';
import './logAccount.scss';
import { Button } from 'react-bootstrap';
import Constant from '../../common/constant/constant';
import { useContext, useEffect, useState } from 'react';
import { messageLockAccount } from '../../data/mockDataUser';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import ApiRequest from '../../api/axios';
import { SiteContext } from '../../context/SiteContext';
import moment from 'moment';

const { RangePicker } = DatePicker;

const LogAccount = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(2);
	const [valueSearch, setValueSearch] = useState({
		mess: 'all',
		account: '',
		userId: '',
		from: '',
		to: '',
	});

	const { state } = useContext(SiteContext);
	const gameId = state?.navbar?.id;

	const columns = [
		{
			field: 'id',
			headerName: 'STT',
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) =>
				params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
		},
		{
			field: 'player.username',
			headerName: 'User name',
			headerAlign: 'center',
			align: 'center',
			flex: 1,
			renderCell: ({ row: { player } }) => player.username,
		},
		{
			field: 'player.id',
			headerName: 'User ID',
			headerAlign: 'center',
			align: 'center',
			flex: 1,
			renderCell: ({ row: { player } }) => player.id,
		},
		{
			field: 'player.email',
			headerName: 'Mail',
			headerAlign: 'center',
			align: 'center',
			flex: 1,
			renderCell: ({ row: { player } }) => player.email,
		},
		{
			field: 'desc',
			headerName: 'Nguyên nhân khoá',
			headerAlign: 'center',
			align: 'center',
			flex: 1,
		},
		{
			field: 'timeLock',
			headerName: 'Thời gian khoá',
			headerAlign: 'center',
			flex: 1,
			align: 'center',
			renderCell: ({ row: { createdAt } }) => {
				return dayjs(createdAt).format('DD-MM-YYYY HH:mm');
			},
		},
		{
			field: 'timeUnlock',
			headerName: 'Thời gian mở khoá',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { actionType, updatedAt } }) => {
				if (actionType === 'UNBAN')
					return dayjs(updatedAt).format('DD-MM-YYYY HH:mm');
				return '';
			},
		},
	];

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item} key={item}>
						<span>{item}</span>
					</MenuItem>
				);
			});
		}
	};

	const handleSearch = async (page = 1) => {
		setData([]);
		setIsLoading(true);
		let s = `?page=${page}&gameId=${gameId}`;
		for (const [key, value] of Object.entries(valueSearch)) {
			if (value === '') continue;
			if (key === 'mess' && value === 'all') continue;
			let vDate;
			if (key === 'from' || key === 'to') vDate = moment(value);
			s += `&${key}=${vDate || value}`;
		}
		let response = await ApiRequest(
			Constant.GET,
			`${Constant.PLAYER_URL}/log${s}`
		);
		setIsLoading(false);
		setPage(page);
		if (!response?.data) return;
		const dataList = response.data?.data?.player;
		setData(dataList);
		setPageTotal(response.data?.data?.totalPage || 2);
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		handleSearch(page);
	}, [state]);

	return (
		<div>
			<Box m="20px">
				<Grid container>
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ alignItems: 'end' }}
						>
							<Grid item xs={12} md={6} lg={2}>
								<Box>Username/Email</Box>
								<TextField
									placeholder="Nhập Username/Email"
									onChange={(e) =>
										setValueSearch({
											...valueSearch,
											account: e?.target?.value,
										})
									}
									value={valueSearch?.account}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>User ID</Box>
								<TextField
									placeholder="Nhập User ID"
									onChange={(e) =>
										setValueSearch({
											...valueSearch,
											userId: e.target.value,
										})
									}
									value={valueSearch?.userId}
									variant="outlined"
									size="small"
									className="inputWidth"
									type="number"
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<Box>Thời gian khoá</Box>
								<RangePicker
									size="large"
									format="DD-MM-YYYY"
									style={{ backgroundColor: '#F5F8FF' }}
									onChange={(_, dateString) => {
										setValueSearch({
											...valueSearch,
											from: dateString[0],
											to: dateString[1],
										});
									}}
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>Nguyên nhân khoá</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={valueSearch?.mess}
										onChange={(e) =>
											setValueSearch({
												...valueSearch,
												mess: e?.target?.value,
											})
										}
									>
										<MenuItem value="all">
											<span>Tất cả</span>
										</MenuItem>
										{listItems(messageLockAccount)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12} lg={2}>
								<Button
									className="btnRed"
									onClick={handleSearch}
									disabled={isLoading}
								>
									{Constant.BUTTON.SEARCH}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Box
					m="10px 0 0 0"
					height="78vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={data || []}
						columns={columns}
						loading={isLoading}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns status and traderName, the other columns will remain visible
								},
							},
						}}
						hideFooter={true}
					/>

					<Grid container justifyContent="center">
						<Pagination
							count={pageTotal}
							onChange={(e, v) => {
								handleSearch(v);
							}}
						/>
					</Grid>
				</Box>
			</Box>
		</div>
	);
};

export default LogAccount;
