import './editproject.scss';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Input,
    Switch,
    Grid,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import ApiRequest, { ApiRequestUpload } from '../../api/axios';
import Button from 'react-bootstrap/Button';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from 'antd';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Constant from '../../common/constant/constant';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { gameStatus } from '../../data/mockDataUser';

const EditProject = () => {
    const location = useLocation();
    const { pj, gameId } = location.state;
    const [whiteList, setWhiteList] = useState([]);
    const [listGameCategory, setListGameCategory] = useState([]);
    const [game, setGame] = useState({});
    const [listDel, setListDel] = useState([]);

    const textInput = useRef(Constant.NULL);

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateImage, setIsUpdateImage] = useState(false);
    const [valueTxtWhiteList, setValueTxtWhiteList] = useState({
        value: '',
        valueSearch: '',
    });

    const columns = [
        {
            field: '_id',
            headerName: '',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            cellClassName: 'id-column--cell',
        },
        {
            field: 'playerId',
            headerName: 'Player Id',
            headerAlign: 'start',
            flex: 1,
            align: 'start',
            cellClassName: 'project_code-column--cell',
        },
    ];

    let listItems = (datas) => {
        if (datas.length === 0) {
            return;
        } else {
            return datas.map((item) => {
                return (
                    <MenuItem value={item._id} key={item._id}>
                        <span>{item.title}</span>
                    </MenuItem>
                );
            });
        }
    };

    const handleChangeGame = (key, e) => {
        game[key] = e?.target?.value;
        if (key === 'endMaintainTime' || key === 'startMaintainTime' || key === 'startAt' || key === 'endAt') {
            game[key] = e?.toISOString();
        }
        if (key === 'isShow') {
            console.log('isShow', e?.target?.checked);
            game[key] = e?.target?.checked;
        }
        setGame({ ...game });
    };

    const handleChangeSelctGameStatus = (e) => {
        setGame({ ...game, status: Number(e?.target?.value) });
    };

    const updateGame = async () => {
        try {
            setIsLoading(true);
            const gameCategory = listGameCategory.find((e) => e._id === game?.categoryIds);
            const dataPost = {
                status: game?.status,
                title: game?.title,
                code: game?.code,
                thumbnail: game?.thumbnail,
                categoryIds: String(gameCategory._id),
                message: game?.message || '',
                hotline: game?.hotline || '',
                fanPage: game?.fanPage || '',
                isShow: String(game?.isShow),
                emailSupport: game?.emailSupport,
                urlService: game?.urlService || '',
                urlGameInfo: game?.urlGameInfo || '',
                urlCallbackGame: game?.urlCallbackGame || '',
                urlCallbackPayment: game?.urlCallbackPayment || '',
                ticket: game?.ticket || '',
            };
            if (dataPost?.status === 3) {
                dataPost.startMaintainTime = game?.startMaintainTime;
                dataPost.endMaintainTime = game?.endMaintainTime;
            } else {
                dataPost.startAt = game?.startAt;
                dataPost.endAt = game?.endAt;
            }

            if (game?.thumbnail && isUpdateImage) {
                const dataForm = new FormData();
                dataForm.append('file', game.thumbnail);
                const upload = await ApiRequestUpload(dataForm);
                dataPost.thumbnail = upload?.data?.name;
            }

            const result = await ApiRequest('PUT', `${Constant.GAME_URL}/update/${gameId}`, dataPost).catch((e) =>
                setIsLoading(false)
            );
            setIsLoading(false);
            if (!result?.data) return;
            setIsUpdateImage(false);
            enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
        } catch (e) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRowCheckClick = (param) => {
        setListDel(param);
    };

    const getWhiteList = async (query) => {
        let s = '';
        if (query !== '') s = `&playerId=${query}`;
        const result = await ApiRequest(Constant.GET, `${Constant.WHITE_LIST_URL}?gameId=${gameId}${s}`);
        return result;
    };

    const addWhiteList = async () => {
        setIsLoading(true);
        const result = await ApiRequest(Constant.POST, Constant.WHITE_LIST_URL, {
            gameId,
            playerId: Number(valueTxtWhiteList.value),
            gameVersionId: game?.gameVersion?.id,
            status: true,
        });
        setIsLoading(false);
        if (result?.data) {
            enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
            const data = { ...result?.data?.data?.gameWhiteList };
            data.playerId = data?.player?.id;
            setWhiteList([data, ...whiteList]);
        }
    };

    const removeList = async () => {
        setIsLoading(true);
        const result = await ApiRequest(Constant.DELETE, `${Constant.WHITE_LIST_URL}/${listDel.toString()}`);
        if (result?.data) {
            enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
            const data = [...whiteList];
            for (const v of listDel) {
                const index = data.findIndex((e) => e?.id === v);
                if (index !== -1) {
                    data.splice(index, 1);
                }
            }
            setWhiteList(data);
            setListDel([]);
        }
        setIsLoading(false);
    };

    const init = async () => {
        let [responseGameCategory, responseGameDetail, responseWhiteList] = await Promise.all([
            ApiRequest(Constant.GET, Constant.GAMECATEGORY_URL),
            ApiRequest(Constant.GET, `${Constant.GAMEDETAIL_URL}/${gameId}`),
            getWhiteList(valueTxtWhiteList?.valueSearch),
        ]);
        const dataGameCategory = responseGameCategory?.data?.data?.gamescategory;
        if (dataGameCategory.list.length > Constant.ZERO) {
            setListGameCategory(dataGameCategory.list);
        }

        const dataGame = responseGameDetail?.data?.data.game;
        if (dataGame) {
            dataGame.categoryIds = dataGame.categoryIds.toString();
            setGame({ ...dataGame });
        }

        const dataWhiteList = responseWhiteList?.data?.data?.gameWhiteList;
        if (dataWhiteList) {
            for (const e of dataWhiteList) {
                e.playerId = e?.player?.id;
            }
            setWhiteList(dataWhiteList);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="home-editproject">
            <Box m="20px">
                {/* Header */}
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <Link
                        to={Constant.PATH_SLASH + Constant.PATH_PROJECT}
                        style={{ textDecoration: 'none', color: 'black' }}
                    >
                        <KeyboardBackspaceIcon />
                    </Link>
                    <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{Constant.LABEL.PROJECT_EDIT}</span>
                </Box>

                {/* body */}
                <Box marginY="30px" p="15px" sx={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                    <Grid container>
                        {/* name, category */}
                        <Grid xs={12} md={6}>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom">{Constant.LABEL.PROJECT_NAME}</span>
                                <TextField
                                    placeholder="Nhập tên dự án"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.title || ''}
                                    onChange={(e) => handleChangeGame('title', e)}
                                />
                            </Box>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">
                                    {Constant.LABEL.PROJECT_CATEGORY}
                                </span>
                                <FormControl
                                    variant="standard"
                                    sx={{ minWidth: 190 }}
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                >
                                    <InputLabel id="demo-simple-select-standard-label" sx={{ opacity: 0.7 }}>
                                        Nhập thể loại
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={game?.categoryIds || ''}
                                        onChange={(e) => handleChangeGame('categoryIds', e)}
                                    >
                                        {listItems(listGameCategory)}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        {/* code, icon */}
                        <Grid xs={12} md={6}>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom">{Constant.LABEL.PROJECT_CODE}</span>
                                <TextField
                                    placeholder="Nhập mã dự án"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.code || ''}
                                    onChange={(e) => handleChangeGame('code', e)}
                                />
                            </Box>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">
                                    {Constant.LABEL.PROJECT_ICON}
                                </span>
                                <input
                                    type="file"
                                    id="upload-photo"
                                    name="upload-photo"
                                    style={{ marginTop: '16px', width: '100%' }}
                                    ref={textInput}
                                    accept="image/*"
                                    onChange={(e) => {
                                        setIsUpdateImage(true);
                                        setGame({
                                            ...game,
                                            thumbnail: e.target.files[0],
                                        });
                                    }}
                                />
                            </Box>
                        </Grid>
                        {/* hotline */}
                        <Grid xs={12} md={6}>
                            <Box my="20px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom">Hotline</span>
                                <TextField
                                    placeholder="Nhập hotline"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.hotline || ''}
                                    onChange={(e) => handleChangeGame('hotline', e)}
                                />
                            </Box>
                        </Grid>
                        {/* fanPage */}
                        <Grid xs={12} md={6}>
                            <Box my="20px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">Fanpage</span>
                                <TextField
                                    placeholder="Nhập fanPage"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.fanPage || ''}
                                    onChange={(e) => handleChangeGame('fanPage', e)}
                                />
                            </Box>
                        </Grid>
                        {/* email */}
                        <Grid xs={12} md={6}>
                            <Box my="20px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">Email hỗ trợ</span>
                                <TextField
                                    placeholder="Nhập email hỗ trợ"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.emailSupport || ''}
                                    onChange={(e) => handleChangeGame('emailSupport', e)}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box my="20px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">Ticket</span>
                                <TextField
                                    placeholder="Nhập link ticket"
                                    variant="standard"
                                    size="small"
                                    className="inputProjectItem inputChangeBorderBottom"
                                    value={game?.ticket || ''}
                                    onChange={(e) => handleChangeGame('ticket', e)}
                                />
                            </Box>
                        </Grid>
                        {/* url */}
                        <Grid xs={12}>
                            <Box display="flex" alignItems="center" flexDirection="row">
                                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                                    <Box my="10px" display="flex" flexDirection="row">
                                        <span className="labelProjectItem borderBottom">
                                            {Constant.LABEL.URL_GAME_INFO}
                                        </span>
                                        <TextField
                                            value={game?.urlGameInfo || ''}
                                            placeholder={`Nhập ${Constant.LABEL.URL_GAME_INFO}`}
                                            variant="standard"
                                            size="small"
                                            className="inputProjectItem inputChangeBorderBottom"
                                            onChange={(e) => handleChangeGame('urlGameInfo', e)}
                                        />
                                    </Box>
                                    <Box my="10px" display="flex" flexDirection="row">
                                        <span className="labelProjectItem borderBottom spanSelect">
                                            {Constant.LABEL.URL_GAME}
                                        </span>
                                        <TextField
                                            value={game?.urlCallbackGame || ''}
                                            placeholder={`Nhập ${Constant.LABEL.URL_GAME}`}
                                            variant="standard"
                                            size="small"
                                            className="inputProjectItem inputChangeBorderBottom"
                                            sx={{ marginTop: '16px' }}
                                            onChange={(e) => handleChangeGame('urlCallbackGame', e)}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                                    <Box my="10px" display="flex" flexDirection="row">
                                        <span className="labelProjectItem borderBottom">
                                            {Constant.LABEL.URL_PAYMENT}
                                        </span>
                                        <TextField
                                            value={game?.urlCallbackPayment || ''}
                                            placeholder={`Nhập ${Constant.LABEL.URL_PAYMENT}`}
                                            variant="standard"
                                            size="small"
                                            className="inputProjectItem inputChangeBorderBottom"
                                            onChange={(e) => handleChangeGame('urlCallbackPayment', e)}
                                        />
                                    </Box>
                                    <Box my="10px" display="flex" flexDirection="row">
                                        <span className="labelProjectItem borderBottom spanSelect">
                                            {Constant.LABEL.URL_SERVICE}
                                        </span>
                                        <TextField
                                            value={game?.urlService || ''}
                                            placeholder={`Nhập ${Constant.LABEL.URL_SERVICE}`}
                                            variant="standard"
                                            size="small"
                                            className="inputProjectItem inputChangeBorderBottom"
                                            sx={{ marginTop: '16px' }}
                                            onChange={(e) => handleChangeGame('urlService', e)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {/* status */}
                        <Grid xs={12}>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="select-game-status-label borderBottom spanSelect">Trạng thái</span>
                                <FormControl
                                    variant="standard"
                                    sx={{ minWidth: 190 }}
                                    size="small"
                                    className="input-game-status inputChangeBorderBottom"
                                >
                                    <InputLabel id="demo-simple-select-standard-label" sx={{ opacity: 0.7 }}>
                                        Nhập trạng thái
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={String(game?.status)}
                                        onChange={handleChangeSelctGameStatus}
                                    >
                                        {gameStatus.map((e) => (
                                            <MenuItem key={e?.id} value={Number(e?.id)}>
                                                {e?.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        {/* time */}
                        <Grid xs={12} md={6}>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">
                                    {game?.status === 1 || game?.status === 2
                                        ? Constant.LABEL.PROJECT_OPEN
                                        : Constant.LABEL.START}
                                </span>
                                <DatePicker
                                    size="large"
                                    className="inputProjectItem"
                                    style={{
                                        border: 'none',
                                        borderRadius: 0,
                                        borderBottom: '1px solid #d8d8d8',
                                    }}
                                    showTime
                                    format="DD-MM-YYYY HH:mm"
                                    disabled={game?.status === 2}
                                    onChange={(e) =>
                                        handleChangeGame(game?.status === 3 ? 'startMaintainTime' : 'startAt', e)
                                    }
                                    value={dayjs(game?.startMaintainTime || game?.startAt)}
                                />
                            </Box>
                        </Grid>
                        {/* check */}
                        <Grid xs={12} md={6}>
                            <Box my="10px" display="flex" flexDirection="row">
                                <span className="labelProjectItem borderBottom spanSelect">
                                    {game?.status === 1 || game?.status === 2
                                        ? Constant.LABEL.PROJECT_CLOSE
                                        : Constant.LABEL.END}
                                </span>
                                <DatePicker
                                    size="large"
                                    className="inputProjectItem"
                                    style={{
                                        border: 'none',
                                        borderRadius: 0,
                                        borderBottom: '1px solid #d8d8d8',
                                    }}
                                    showTime
                                    format="DD-MM-YYYY HH:mm"
                                    disabled={game?.status === 1}
                                    onChange={(e) =>
                                        handleChangeGame(game?.status === 3 ? 'endMaintainTime' : 'endAt', e)
                                    }
                                    value={dayjs(game?.endMaintainTime || game?.endAt)}
                                />
                            </Box>
                        </Grid>
                        {/* noti */}
                        <Grid xs={12}>
                            <Box my="10px" display="flex" flexDirection="row" alignItems="center">
                                <span className="select-game-status-label ">{Constant.LABEL.PROJECT_IS_SHOW}</span>
                                <div className="inputProjectItem ">
                                    <Switch checked={game?.isShow} onChange={(e) => handleChangeGame('isShow', e)} />
                                </div>
                            </Box>
                            <Box>
                                <TextField
                                    sx={{ width: '95%' }}
                                    placeholder="Tin nhắn thông báo (nếu không có thì không cần điền)"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    value={game?.message}
                                    onChange={(e) => handleChangeGame('message', e)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                    <Button className="btnRed" disabled={isLoading} onClick={() => updateGame()}>
                        {Constant.BUTTON.CONFIRM}
                    </Button>
                </Box>

                <Box m="20px 0 0 0" sx={{ backgroundColor: 'white', borderRadius: '15px' }}>
                    <Box p="10px 15px 0 15px" display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                        <Box gridColumn="span 8">
                            <Box my="10px" display="flex" flexDirection="row" alignItems="center">
                                <span className=" ">White list</span>
                                <Box ml="15px" mr="10px">
                                    <TextField
                                        placeholder="Nhập player ID ..."
                                        size="small"
                                        onChange={(e) =>
                                            setValueTxtWhiteList({
                                                ...valueTxtWhiteList,
                                                value: e?.target?.value,
                                            })
                                        }
                                    />
                                </Box>
                                <span className="btdAddWhiteList" onClick={() => addWhiteList()}>
                                    Add
                                </span>
                            </Box>
                        </Box>
                        <Box gridColumn="span 4" display="flex" alignItems="center" justifyContent="space-between">
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                className="pointer"
                                                disabled={isLoading}
                                                onClick={async () => {
                                                    const result = await getWhiteList(valueTxtWhiteList?.valueSearch);
                                                    if (result?.data) {
                                                        const data = [];
                                                        for (const v of result?.data?.data?.gameWhiteList) {
                                                            v.playerId = v?.player?.id;
                                                            data.push(v);
                                                        }
                                                        setWhiteList(data);
                                                    }
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                placeholder="Tìm kiếm"
                                onChange={(e) =>
                                    setValueTxtWhiteList({
                                        ...valueTxtWhiteList,
                                        valueSearch: e?.target?.value,
                                    })
                                }
                            />
                            <Button
                                style={{
                                    backgroundColor: '#d8d8d8d8',
                                    color: 'black',
                                }}
                                disaled={isLoading}
                                onClick={() => removeList()}
                            >
                                Xoá
                            </Button>
                        </Box>
                    </Box>
                    <hr />
                    <Box
                        m="10px 0 0 0"
                        height="70vh"
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                // color: colors.greenAccent[300],
                                //borderBottom: "none",
                            },
                            '& .MuiDataGrid-row': {
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            },
                            '& .MuiTablePagination-toolbar': {
                                display: 'flex',
                                alignItems: 'baseline',
                            },
                        }}
                    >
                        <DataGrid
                            rows={whiteList}
                            columns={columns}
                            onSelectionModelChange={(ids) => handleRowCheckClick(ids)}
                            autoPageSize
                            checkboxSelection
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns status and traderName, the other columns will remain visible
                                        _id: false,
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default EditProject;
