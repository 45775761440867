import { Link } from "react-router-dom";
import "./profile.scss";
import { Input, Box } from "@mui/material";
import { useState, useEffect } from "react";
import ApiRequest from "../../api/axios";
import Constant from "../../common/constant/constant";

const Profile = () => {

    const [name, setName] = useState(Constant.EMPTY);
    const [department, setDepartment] = useState(Constant.EMPTY);
    const [role, setRole] = useState(Constant.EMPTY);

    const dataLocal = JSON.parse(window.localStorage.getItem("LOGIN"));
    // const accessToken = state.login.tokens.accessToken;

    const init = async () => {
        const user = {
            _id: dataLocal?.user?.id
        }
        console.log(JSON.stringify({ user }));
        const response = await ApiRequest(Constant.GET, Constant.PROFILE_URL, user);
        console.log(JSON.stringify(response?.data));
        if (!response?.data) return;
        const data = response?.data?.data;
        setName(data.name);
        setDepartment(data.departments[0].code);
        setRole(data.roles[0].code);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="home-profile">
            <Box m="20px" className="infomation">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h3>{Constant.LABEL.PROFILE_INFO}</h3>
                </Box>
                <Box className="flex">
                    <Input disabled defaultValue="Tên" />
                    <Input disabled value={name} />
                </Box>
                <Box className="flex">
                    <Input disabled defaultValue="Bộ Phận" />
                    <Input disabled value={department} />
                </Box>
                <Box className="flex">
                    <Input disabled defaultValue="Vị trí" />
                    <Input disabled value={role} />
                </Box>
            </Box>
            <Box m="20px" className="infomation">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h3>{Constant.LABEL.PROFILE_PASSWORD}</h3>
                </Box>
                <Box className="flex">
                    <Input disabled defaultValue="*********" />
                    <Link to={Constant.PATH_SLASH + Constant.PATH_CHANGEPWD} style={{ textDecoration: "none" }}>
                        <span>{Constant.LABEL.PROFILE_CHANGPWD}</span>
                    </Link>
                </Box>
            </Box>
        </div>
    )
}

export default Profile