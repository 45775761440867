import * as React from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useContext, useState, useEffect, useRef } from 'react';
import { SiteContext } from '../../context/SiteContext';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ApiRequest from '../../api/axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Modal from '../../components/modal/CustomModal';
import './setting.scss';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';
import { enqueueSnackbar } from 'notistack';

const Setting = () => {
	const [listTemplate, setListTemplate] = useState([]);
	const [listGameCategory, setListGameCategory] = useState([]);
	const [inputDisabled, setInputDisabled] = useState(true);
	const { state, dispatch } = useContext(SiteContext);
	const [templateId, setTemplateId] = useState(Constant.EMPTY);
	const [templateName, setTemplateName] = useState(Constant.EMPTY);
	const [templateContent, setTemplateContent] = useState(Constant.EMPTY);
	const [title, setTitle] = useState(Constant.EMPTY);
	const [id, setId] = useState(Constant.EMPTY);
	const [modalShow, setModalShow] = useState(false);
	const [inputGameCategory, setInputGameCategory] = useState(true);

	const [dense] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(Constant.EMPTY_SELECTED);
	const [selectedGameCategoryIndex, setSelectedGameCategoryIndex] = useState(
		Constant.EMPTY_SELECTED
	);
	const inputRef = useRef();
	const [message, setMessage] = useState(Constant.EMPTY);

	let listItems = (datas) => {
		if (datas.length === 0) {
			return <></>;
		} else {
			return datas.map((item, index) => {
				return (
					<ListItemButton
						className="ListItem"
						selected={selectedIndex === index}
						onClick={() => handleListItemClick(index, item)}
						key={item._id}
					>
						{item.name}
						<div>
							<IconButton
								edge="end"
								aria-label="edit"
								onClick={() =>
									handleListItemEditClick(index, item)
								}
							>
								<EditOutlinedIcon />
							</IconButton>
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={(event) =>
									handleDeleteTemplate(event, item)
								}
							>
								<DeleteIcon />
							</IconButton>
						</div>
					</ListItemButton>
				);
			});
		}
	};

	let listItemsGameCategory = (datas) => {
		if (datas.length === 0) {
			return <></>;
		} else {
			return datas.map((item, index) => {
				return (
					<ListItemButton
						className="ListItem"
						selected={selectedGameCategoryIndex === index}
						key={item.id}
					>
						{item.title}
						<div>
							<IconButton
								edge="end"
								aria-label="edit"
								onClick={() =>
									handleListGameCategoryItemEditClick(
										index,
										item
									)
								}
							>
								<EditOutlinedIcon />
							</IconButton>
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() =>
									handleDeleteGameCategory(item, index)
								}
							>
								<DeleteIcon />
							</IconButton>
						</div>
					</ListItemButton>
				);
			});
		}
	};

	const handleListItemClick = (index, item) => {
		setSelectedIndex(index);
		setTemplateName(item.name);
		setTemplateContent(item.content);
	};

	const handleListItemEditClick = (index, item) => {
		setInputDisabled(false);
		setSelectedIndex(index);
		setTemplateId(item._id);
		setTemplateName(item.name);
		setTemplateContent(item.content);
	};

	const handleListGameCategoryItemEditClick = (index, item) => {
		setId(item.id);
		setTitle(item.title);
		setMessage(Message.MS0005);
		setSelectedGameCategoryIndex(index);
		setModalShow(true);
	};

	const setInputEnable = () => {
		setInputDisabled(false);
		setSelectedIndex(Constant.EMPTY_SELECTED);
		setTemplateId(Constant.EMPTY);
		setTemplateName(Constant.EMPTY);
		setTemplateContent(Constant.EMPTY);
		setTimeout(() => {
			inputRef.current.focus();
		}, 100);
	};

	const setClearControl = () => {
		setInputDisabled(true);
		setSelectedIndex(Constant.EMPTY_SELECTED);
		setTemplateId(Constant.EMPTY);
		setTemplateName(Constant.EMPTY);
		setTemplateContent(Constant.EMPTY);
	};

	const setClearControlGameCategory = () => {
		setId(Constant.EMPTY);
		setTitle(Constant.EMPTY);
		setSelectedGameCategoryIndex(Constant.EMPTY_SELECTED);
	};

	const handleCloseModal = (event, data) => {
		// console.log(data);
		setTitle(data);
		if (id !== Constant.EMPTY) {
			handleUpdateGameCategory(data);
		} else {
			handleAddGameCategory(data);
		}
		setModalShow(false);
	};

	const handleAddTemplate = async () => {
		if (!templateName) alert(Message.ER0008);
		else if (!templateContent) alert(Message.ER0009);
		else {
			const response = await ApiRequest(
				Constant.POST,
				Constant.SETTING_URL,
				{
					name: templateName,
					content: templateContent,
				}
			);
			// console.log(JSON.stringify(response?.data));
			if (!response?.data) return;
			const data = response?.data?.data;
			setListTemplate([...data?.templatereplys]);
			setClearControl();
			// console.log("reload list!!!!!")
		}
	};

	const handleUpdateTemplate = async () => {
		if (!templateName) alert(Message.ER0008);
		else if (!templateContent) alert(Message.ER0009);
		else {
			const response = await ApiRequest(
				Constant.PUT,
				Constant.SETTING_URL,
				{
					id: templateId,
					name: templateName,
					content: templateContent,
				}
			);
			// console.log(JSON.stringify(response?.data));
			if (!response?.data) return;
			const data = response?.data?.data;
			setListTemplate(data.templatereplys);
			setClearControl();
			// console.log("reload list!!!!!")
		}
	};

	const handleDeleteTemplate = async (event, item) => {
		event.preventDefault();
		// console.log(JSON.stringify({
		//     id: item._id
		// }));
		const response = await ApiRequest(
			Constant.DELETE,
			Constant.SETTING_URL,
			{
				id: item._id,
			}
		);
		// console.log(JSON.stringify(response?.data));
		if (!response?.data) return;
		const data = response?.data?.data;
		setListTemplate(data.templatereplys);
		setClearControl();
		// console.log("reload list!!!!!");
	};

	const handleAddGameCategory = async (title) => {
		// console.log(title);
		if (!title) alert(Message.ER0010);
		else {
			const response = await ApiRequest(
				Constant.POST,
				Constant.GAMECATEGORY_URL,
				{
					title: title,
				}
			);
			// console.log(JSON.stringify(response?.data));
			if (!response?.data) return;
			const data = response?.data?.data?.gamescategory;
			setListGameCategory([data, ...listGameCategory]);
			enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
			setClearControlGameCategory();
			// console.log("reload list!!!!!");
		}
	};

	const handleUpdateGameCategory = async (item) => {
		// console.log(item);
		const response = await ApiRequest(
			Constant.PUT,
			Constant.GAMECATEGORY_URL,
			{
				title: item,
				id: id,
			}
		);
		// console.log(JSON.stringify(response?.data));
		if (!response?.data) return;
		const data = response?.data?.data?.gamescategory;
		setListGameCategory(data.list);
		setClearControlGameCategory();
		// console.log("reload list!!!!!");
	};

	const handleDeleteGameCategory = async (item, i) => {
		// console.log(item);
		const response = await ApiRequest(
			Constant.DELETE,
			`${Constant.GAMECATEGORY_URL}/${item.id}`
		);
		// console.log(JSON.stringify(response?.data));
		if (!response?.data) return;
		enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
		listGameCategory.splice(i, 1);
		// const data = response?.data?.data?.gamescategory;
		setListGameCategory([...listGameCategory]);
		setClearControlGameCategory();
		// console.log("reload list!!!!!");
	};

	const init = async () => {
		let response = await ApiRequest(Constant.GET, Constant.SETTING_URL);
		if (!response?.data) return;
		const data = response?.data?.data;
		// console.log(data.templatereplys);
		if (data.templatereplys.length > Constant.ZERO) {
			setListTemplate(data.templatereplys);
		}

		response = await ApiRequest(Constant.GET, Constant.GAMECATEGORY_URL);
		if (!response?.data) return;
		const dataGameCategory = response?.data?.data?.gamescategory;
		// console.log(dataGameCategory.list);
		if (dataGameCategory.list.length > Constant.ZERO) {
			setListGameCategory(dataGameCategory.list);
		}
		setClearControl();
		setClearControlGameCategory();
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="home-templatereply">
			<Box m="10px" className="infomation">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<h3>{Constant.LABEL.SETTING_TEMPLATE_P}</h3>
				</Box>
				<Box mx="10px" display="flex" alignItems="center">
					<p>{Constant.LABEL.TEMPLATE}</p>
				</Box>
				<Box mb="10px" display="flex" flexDirection="row">
					<Box
						sx={{ width: '100%', height: 270, overflowY: 'auto' }}
						display="flex"
						flex="1"
						flexDirection="column"
						border="1px solid"
					>
						<List dense={dense}>{listItems(listTemplate)}</List>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						flex="2"
						border="1px solid"
					>
						<Box m="10px" display="flex" flexDirection="column">
							<p>{Constant.LABEL.TEMAPLATE_NAME}</p>
							<TextField
								id="outlined-basic"
								variant="outlined"
								size="small"
								onChange={(e) =>
									setTemplateName(e.target.value)
								}
								value={templateName}
								disabled={inputDisabled}
								inputRef={inputRef}
							/>
						</Box>
						<Box m="10px" display="flex" flexDirection="column">
							<p>{Constant.LABEL.TEMPLATE_CONTENT}</p>
							<TextField
								id="outlined-basic"
								variant="outlined"
								size="small"
								multiline
								rows={4}
								onChange={(e) =>
									setTemplateContent(e.target.value)
								}
								value={templateContent}
								disabled={inputDisabled}
							/>
						</Box>
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Button variant="outlined" onClick={setInputEnable}>
						<AddIcon />
						{Constant.BUTTON.CREATE}
					</Button>
					<Button
						variant="outlined"
						className="btnTemplateSave"
						disabled={inputDisabled}
						onClick={
							templateId
								? handleUpdateTemplate
								: handleAddTemplate
						}
					>
						<SaveIcon />
						{Constant.BUTTON.SAVE}
					</Button>
					<Button
						variant="outlined"
						className="btnTemplateSave"
						disabled={inputDisabled}
						onClick={setClearControl}
					>
						<ClearIcon />
						{Constant.BUTTON.CLEAR}
					</Button>
				</Box>

				<Box
					mt="20px"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<h3>{Constant.LABEL.SETTING_GAME_CATEGORY}</h3>
				</Box>
				<Box mx="10px" display="flex" alignItems="center">
					<p>{Constant.LABEL.GAME_CATEGORY_P}</p>
				</Box>
				<Box mb="10px" display="flex" flexDirection="row">
					<Box
						sx={{ width: '100%', height: 270, overflowY: 'auto' }}
						display="flex"
						flex="1"
						flexDirection="column"
						border="1px solid"
					>
						<List dense={dense}>
							{listItemsGameCategory(listGameCategory)}
						</List>
					</Box>
				</Box>

				<Box display="flex" alignItems="center">
					<Button
						variant="outlined"
						onClick={() => {
							setModalShow(true);
							setMessage(Message.MS0012);
							setClearControlGameCategory();
						}}
					>
						<AddIcon />
						{Constant.BUTTON.CREATE}
					</Button>
				</Box>
			</Box>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				message={message}
				btnCancelShow="true"
				btnAgreeShow="true"
				showInputGameCategory={inputGameCategory}
				inputGameCategory={title}
				onCloseModal={handleCloseModal}
			/>
		</div>
	);
};

export default Setting;
