/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { SiteContext } from './context/SiteContext';
import './style/dark.scss';
import Constant from './common/constant/constant';
import Authen from './common/helper';
import ApiRequest from './api/axios';
import { SnackbarProvider } from 'notistack';
import RouterApp from './RouterApp';

function App() {
	const { state, dispatch } = useContext(SiteContext);
	const contextLogin = state?.login;
	const localLogin = JSON.parse(window.localStorage.getItem('LOGIN'));
	let loginToken = contextLogin?.email ? contextLogin : localLogin;
	const navigate = useNavigate();

	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (e) {
			return null;
		}
	};

	const getProfile = async (id) => {
		const user = {
			_id: id,
		};

		const response = await ApiRequest(
			Constant.GET,
			Constant.PROFILE_URL,
			user,
			null,
			null,
			null,
			() => {
				navigate(Constant.PATH_LOGIN);
			}
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		const email = data.email;
		const accessToken = loginToken?.accessToken;
		const menu = data.menus;
		const name = data.name;
		dispatch({
			type: 'LOGIN',
			login: { email, name, menu, accessToken, id },
		});
	};

	useEffect(() => {
		if (loginToken?.accessToken) {
			const decodedJwt = parseJwt(loginToken?.accessToken);
			if (decodedJwt.exp * 1000 < Date.now()) {
				window.localStorage.clear();
				dispatch({ type: 'LOGOUT' });
				navigate(Constant.PATH_LOGIN);
			} else {
				Authen(loginToken?.accessToken);
				getProfile(decodedJwt.sub);
			}
		} else {
			navigate(Constant.PATH_LOGIN);
		}
	}, []);

	return (
		<SnackbarProvider maxSnack={3}>
			<div
				className={
					state?.colorMode === 'dark'
						? 'app dark'
						: state?.colorMode === 'green'
						? 'app green'
						: state?.colorMode === 'pink'
						? 'app pink'
						: 'app'
				}
			>
				<RouterApp isAllowed={!!loginToken?.accessToken} />
			</div>
		</SnackbarProvider>
	);
}

export default App;
