import './ticket.scss';
import { Box, TextField, Divider, Collapse } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState, useEffect, useContext } from 'react';
import { mockTicketData } from '../../data/mockDataUser';
import Modal from '../../components/modal/CustomModal';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiRequest from '../../api/axios';
import Message from '../../common/message/message';
import Constant from '../../common/constant/constant';
import Editor from '../../components/editor/Editor';
import ViewImage from '../../components/dialog/ViewImage';
import { SiteContext } from '../../context/SiteContext';
import { parserStringHTMLToArray, replaceClassEditor } from '../../common/util';
import htmlParse from 'html-react-parser';
import dayjs from 'dayjs';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { enqueueSnackbar } from 'notistack';

const Ticket = () => {
	const navigate = useNavigate();
	const { state } = useContext(SiteContext);
	const [listTemplate, setListTemplate] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [reviewShow, setReviewShow] = useState(false);
	// const [btnShow, setBtnShow] = useState(true);
	const [content, setContent] = useState(Constant.EMPTY);
	const location = useLocation();
	const [ticket, setTicket] = useState([]);
	const [dataTicket, setDataTicket] = useState();
	const [convertedText, setConvertedText] = useState(Constant.EMPTY);
	const [imageShow, setImageShow] = useState(Constant.EMPTY);
	const [open, setOpen] = useState([]);
	const user = state?.login;

	let listItems = (datas) => {
		if (datas.length === Constant.ZERO) {
			return <></>;
		} else {
			return datas.map((item, index) => {
				return (
					<Dropdown.Item
						onClick={(event) =>
							handleListItemClick(event, index, item)
						}
						key={index}
					>
						<span>{item.name}</span>
					</Dropdown.Item>
				);
			});
		}
	};

	const loadMessage = (message) => {
		let dataMess;
		if (Constant.REGEXP.HTMLCHECK.test(message)) {
			dataMess = JSON.parse(message);
		} else {
			dataMess = message;
		}
		if (dataMess.length > 0) {
			return dataMess.map((mess, i) => {
				// console.log('data mess1 ', htmlParse(mess));
				if (mess.includes('img')) {
					return (
						<Button
							variant="text"
							onClick={() => {
								setReviewShow(true);
								setImageShow(mess);
							}}
							key={i}
						>
							{htmlParse(mess)}
						</Button>
					);
				} else {
					return htmlParse(mess);
				}
			});
		} else {
			// console.log('data mess2 ', dataMess);
			return dataMess;
		}
	};

	const showBody = (item, index) => (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			flexDirection="row"
			key={index}
		>
			<Box ml="40px" width="100%">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="row"
				>
					<Box display="flex" alignItems="center" flexDirection="row">
						<h4>
							{item.authId === user?.id
								? user?.name
								: dataTicket?.playerId.username}
						</h4>
						<span>
							(
							{item.authId === user?.id
								? user?.email
								: dataTicket?.playerId.email}
							)
						</span>
					</Box>
					<Box display="flex" alignItems="center" flexDirection="row">
						<span>
							{dayjs(item.createdAt).format(
								Constant.FORMAT.FULLDATE
							)}{' '}
							{dayjs(item.createdAt).format(Constant.FORMAT.TIME)}
						</span>
						<StarBorderIcon />
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="row"
				>
					<Box display="flex" flexDirection="column" flex={1}>
						{loadMessage(item.message)}
					</Box>
				</Box>
			</Box>
		</Box>
	);

	const handleClick = (clickedIndex) => {
		if (open.includes(clickedIndex)) {
			const openCopy = open.filter((element) => {
				return element !== clickedIndex;
			});
			setOpen(openCopy);
		} else {
			const openCopy = [...open];
			openCopy.push(clickedIndex);
			setOpen(openCopy);
		}
	};

	const showListMessage = (listTicket) => {
		if (listTicket.length === Constant.ZERO) {
			return <></>;
		} else {
			if (listTicket.length > 1) {
				return listTicket.map((item, index) => {
					return (
						<>
							<Card
								sx={{
									minWidth: 300,
									backgroundColor: 'transparent',
								}}
								key={index}
							>
								<CardHeader
									title={`${
										item.authId === user?.id
											? user?.name
											: dataTicket?.playerId.username
									} (${
										item.authId === user?.id
											? user?.email || ''
											: dataTicket?.playerId.email || ''
									})`}
									// titleTypographyProps={{ variant: 'h4' }}
									action={
										<IconButton
											onClick={() => handleClick(index)}
											aria-label="expand"
											size="small"
										>
											{open.includes(index) ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)}
										</IconButton>
									}
									subheader={`${dayjs(item.createdAt).format(
										Constant.FORMAT.FULLDATE
									)} 
                                    ${dayjs(item.createdAt).format(
										Constant.FORMAT.TIME
									)}`}
								/>
								<Collapse
									in={open.includes(index)}
									timeout="auto"
									unmountOnExit
								>
									<CardContent>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											flexDirection="row"
										>
											<Box
												display="flex"
												flexDirection="column"
												flex={1}
											>
												{loadMessage(item.message)}
											</Box>
										</Box>
									</CardContent>
								</Collapse>
							</Card>
						</>
					);
				});
			}
			return listTicket.map((item, index) => {
				return showBody(item, index);
			});
		}
	};

	const handleListItemClick = (event, index, item) => {
		setConvertedText(item.content);
	};

	const handleCloseModal = async (e, data) => {
		const response = await ApiRequest(
			Constant.PUT,
			`${Constant.TICKETS_URL}/status/${ticket[0]?.ticketId}`,
			{ status: Number(data) }
		);
		if (response?.status === 200) {
			setDataTicket({ ...dataTicket, status: data });
			enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
		}
		setModalShow(false);
	};

	const handleCloseDialog = () => {
		setReviewShow(false);
	};

	const handleSendTicket = async () => {
		let arrayTags = parserStringHTMLToArray(convertedText);
		let body = {
			email: state?.login?.email,
			message: arrayTags,
			name: state?.login?.name,
		};
		let response = await ApiRequest(
			Constant.POST,
			`${Constant.TICKETS_URL}/${ticket[0]?.ticketId}`,
			body
		);
		if (!response?.data) return;
		const dataTicketMessage = response?.data?.data;
		setTicket([...ticket, { ...dataTicketMessage?.ticketMessage }]);
		setOpen([0, ticket.length]);
		setDataTicket({ ...dataTicket, status: Constant.STATUS_PROCESSING });
	};

	const init = async (dataTicket) => {
		let response = await ApiRequest(Constant.GET, Constant.SETTING_URL);
		if (!response?.data) return;
		const data = response?.data?.data;
		if (data.templatereplys.length > Constant.ZERO) {
			setListTemplate(data.templatereplys);
		}
		response = await ApiRequest(
			Constant.GET,
			Constant.TICKETS_URL + `/${dataTicket._id}`
		);
		if (!response?.data) return;
		const dataTicketMessage = response?.data?.data;
		if (dataTicketMessage.ticketMessage.length > 0) {
			const data = dataTicketMessage.ticketMessage;
			setTicket(data);
			if (data?.length === 1) setOpen([0]);
			if (data?.length > 1) setOpen([0, data.length - 1]);
		}
	};

	useEffect(() => {
		if (!location.state || !user?.accessToken) {
			navigate(Constant.PATH_SLASH + Constant.PATH_MANAGETICKET);
		} else {
			setDataTicket(location.state.dataTicket);
			init(location.state.dataTicket);
		}
	}, []);

	return (
		<div className="home-ticket" style={{ height: '92%' }}>
			<Box m="20px" height="100%" display="flex" flexDirection="column">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="row"
				>
					<Box display="flex" alignItems="flex-start">
						<Link
							to={
								Constant.PATH_SLASH + Constant.PATH_MANAGETICKET
							}
							style={{ textDecoration: 'none', color: 'black' }}
						>
							<KeyboardBackspaceIcon />
						</Link>
						<h4 style={{ marginLeft: '20px' }}>
							<Badge bg="secondary">{dataTicket?.title}</Badge>{' '}
							<Badge bg="danger">({dataTicket?.errorType})</Badge>{' '}
							/ <Badge bg="primary">S-01</Badge> /{' '}
							<Badge bg="success">{dataTicket?.ingameId}</Badge>
						</h4>
					</Box>
					<div>
						<Button
							variant="text"
							style={{
								color:
									Number(dataTicket?.status) ===
									Constant.STATUS_NO_PROCESS
										? Constant.FONTCOLOR.RED
										: Number(dataTicket?.status) ===
										  Constant.STATUS_PROCESSING
										? Constant.FONTCOLOR.GREEN
										: Constant.FONTCOLOR.GREY,
							}}
							onClick={() => setModalShow(true)}
						>
							{Constant.LABEL.STATUS}{' '}
							{Number(dataTicket?.status) ===
							Constant.STATUS_NO_PROCESS
								? Constant.LABEL.NO_PROCESS
								: Number(dataTicket?.status) ===
								  Constant.STATUS_PROCESSING
								? Constant.LABEL.PROCESSING
								: Constant.LABEL.PROCESSED}
						</Button>
					</div>
				</Box>
				{showListMessage(ticket)}

				{/* <Divider variant="middle" style={{ fontWeight: "bold", backgroundColor: "black", margin: "10px 0px" }}></Divider> */}

				<Box
					marginTop="20px"
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					flexDirection="row"
				>
					<Box
						ml="25px"
						p="10px"
						display="flex"
						alignItems="center"
						flexDirection="column"
						flex={1}
						border="1px solid black"
						borderRadius="10px"
					>
						<Box
							display="flex"
							alignItems="center"
							alignSelf="flex-start"
							flexDirection="row"
						>
							<img
								src="./asset/icons/ticket-tra-loi-2.png"
								width="20px"
								alt=""
							/>
							<span>
								(
								{!dataTicket?.playerId?.email
									? dataTicket?.playerId?.username
									: dataTicket?.playerId?.email}
								)
							</span>
						</Box>

						<Box
							mt="10px"
							display="flex"
							justifyContent="start"
							alignItems="flex-start"
							flexDirection="row"
							width="100%"
						>
							<Editor
								value={convertedText}
								setValue={setConvertedText}
								style={{ width: '100%' }}
							/>
						</Box>
						<Box
							mt="50px"
							display="flex"
							alignItems="center"
							alignSelf="flex-start"
							flexDirection="row"
						>
							<Button
								className="btnReply"
								onClick={handleSendTicket}
							>
								{Constant.BUTTON.SEND}
							</Button>

							<Dropdown>
								<Dropdown.Toggle
									variant=""
									id="dropdown-basic"
									className="dropdownEdit"
								>
									<EditOutlinedIcon />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{listItems(listTemplate)}
								</Dropdown.Menu>
							</Dropdown>
						</Box>
					</Box>
				</Box>
			</Box>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				message={Message.MS0004}
				btnConfirmShow="true"
				showProcess="true"
				onCloseModal={handleCloseModal}
				valueSelect={Number(dataTicket?.status)}
			/>
			<ViewImage
				open={reviewShow}
				onCloseDialog={handleCloseDialog}
				data={imageShow}
			/>
		</div>
	);
};

export default Ticket;
