import React from 'react';
import Chart from 'react-apexcharts'

const Line = ({ categoriesData, seriesData, show }) => {

    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: categoriesData,
        }
    }
    const series = seriesData;

    return (
        <div style={{ width: "100%", height: "100%", display: !show ? "block" : "none" }}>
            <Chart options={options} series={series} type="line" width="100%" height="100%" />
        </div>
    );
}

export default Line;