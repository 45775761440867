import { Route, Routes } from 'react-router';

import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Layout from './pages/Layout';

import Profile from './pages/Profile/Profile';
import ChangePwd from './pages/ChangePwd/ChangePwd';
import Setting from './pages/Setting/Setting';

import Accounts from './pages/Accounts/Accounts';
import AccountDetail from './pages/AccountDetail/AccountDetail';
import ManageGGold from './pages/ManageGGold/ManageGGold';
import ResendGGold from './pages/ResendGGold/ResendGGold';
import ManageUserAccounts from './pages/ManageUserAccounts/ManageUserAccounts';
import Mailing from './pages/Mailing/Mailing';
import Notification from './pages/Notification/Notification';
import DeviceStatistics from './pages/DeviceStatistics/DeviceStatistics';
import LogPurchaseGame from './pages/LogPurchaseGame/LogPurchaseGame';
import LogPurchaseGGold from './pages/LogPurchaseGGold/LogPurchaseGGold';
import TransactionReport from './pages/TransactionReport/TransactionReport';
import OverviewReport from './pages/OverviewReport/OverviewReport';
import OperationalData from './pages/OperationalData/OperationalData';
import ManageTicket from './pages/ManageTicket/ManageTicket';
import Ticket from './pages/Ticket/Ticket';
import Project from './pages/Project/Project';
import EditProject from './pages/EditProject/EditProject';
import Missing from './pages/Missing/Missing';

import Constant from './common/constant/constant';
import LogAccount from './pages/LogAccount/LogAccount';

const RouterApp = ({ isAllowed }) => {
	return (
		<Routes>
			{/* public routes */}
			<Route path={Constant.PATH_LOGIN} element={<Login />} />
			<Route path={Constant.PATH_REGISTER} element={<Register />} />
			{/* protect these routes */}
			<Route element={<Layout isAllowed={isAllowed} />}>
				<Route path={Constant.PATH_SLASH} element={<Home />} />

				{/* profile */}
				<Route path={Constant.PATH_PROFILE} element={<Profile />} />
				<Route path={Constant.PATH_CHANGEPWD} element={<ChangePwd />} />
				<Route path={Constant.PATH_SETTING} element={<Setting />} />

				{/* menu report */}
				<Route
					path={Constant.PATH_OVERVIEWREPORT}
					element={<OverviewReport />}
				/>
				<Route
					path={Constant.PATH_OPERATIONALDATA}
					element={<OperationalData />}
				/>

				{/* menu category */}
				<Route
					path={Constant.PATH_DEVICESTATISTICS}
					element={<DeviceStatistics />}
				/>
				<Route
					path={Constant.PATH_LOGPURCHASEGGOLD}
					element={<LogPurchaseGGold />}
				/>
				<Route
					path={Constant.PATH_LOGPURCHASEGAME}
					element={<LogPurchaseGame />}
				/>
				<Route
					path={Constant.PATH_TRANSACTIONREPORT}
					element={<TransactionReport />}
				/>

				{/* menu project manager */}
				<Route path={Constant.PATH_PROJECT} element={<Project />} />
				<Route
					path={Constant.PATH_EDITPROJECT}
					element={<EditProject />}
				/>
				<Route
					path={Constant.PATH_MANAGEUSERACCOUNTS}
					element={<ManageUserAccounts />}
				/>
				<Route
					path={Constant.PATH_LOGACCOUNT}
					element={<LogAccount />}
				/>
				<Route
					path={Constant.PATH_NOTIFICATION}
					element={<Notification />}
				/>
				<Route path={Constant.PATH_MAILING} element={<Mailing />} />
				<Route
					path={Constant.PATH_MANAGETICKET}
					element={<ManageTicket />}
				/>
				<Route path={Constant.PATH_TICKET} element={<Ticket />} />

				{/* menu tool */}
				<Route path={Constant.PATH_ACCOUNTS} element={<Accounts />} />
				<Route
					path={Constant.PATH_ACCOUNTDETAIL}
					element={<AccountDetail />}
				/>
				<Route
					path={Constant.PATH_MANAGEGGOLD}
					element={<ManageGGold />}
				/>
				<Route
					path={Constant.PATH_RESENDGGOLD}
					element={<ResendGGold />}
				/>

				{/* catch all */}
				<Route path={Constant.PATH_ASTERISK} element={<Missing />} />
			</Route>
		</Routes>
	);
};

export default RouterApp;
