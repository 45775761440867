import React from 'react';
import Chart from 'react-apexcharts'

const Pie = ({ categoriesData, seriesData, checked }) => {

    const options = {
        labels: categoriesData,
        dataLabels: {
            formatter: function (val, opts) {
                console.log(checked);
                return checked ? opts.w.config.series[opts.seriesIndex] : val.toFixed(2) + "%"
            },
        },
    };
    const series = seriesData;

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Chart options={options} series={series} type="pie" width="100%" height="100%" />
        </div>
    );
}

export default Pie;