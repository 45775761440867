import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import Constant from '../../common/constant/constant';
import htmlParse from 'html-react-parser';
import './Review.scss';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		wordWrap: 'break-word',
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
	'& .MuiPaper-root': {
		width: '500px',
		height: '500px',
	},
	'& .MuiDialog-container': {
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#efefef' }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const Review = ({ open, onCloseDialog, data, reasons }) => {
	const [message, setMessage] = useState(Constant.EMPTY);
	const [flgCheck, setFlgCheck] = useState(false);
	const handleClose = () => {
		onCloseDialog();
	};

	const loadMessage = () => htmlParse(message);

	useEffect(() => {
		if (Constant.REGEXP.HTMLCHECK.test(data?.message)) {
			setMessage(data?.message || Constant.EMPTY);
			setFlgCheck(true);
		} else {
			setMessage(data?.message ? data?.message : Constant.EMPTY);
			setFlgCheck(false);
		}
	}, [data?.message]);

	return (
		<div>
			<BootstrapDialog
				open={open}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					<span>{data?.gameId?.title}</span>
					<span style={{ marginLeft: '20px' }}>
						{data?.deviceType}
					</span>
					<span style={{ marginLeft: '20px' }}>{reasons?.name}</span>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>{data?.title}</Typography>
					<Divider
						sx={{
							borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
							marginBottom: '0.35em',
						}}
					/>
					{loadMessage()}
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
};

export default Review;
