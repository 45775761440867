import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import htmlParse from 'html-react-parser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        maxWidth: "unset"
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#efefef" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ViewImage = ({
    open,
    onCloseDialog,
    data,
}) => {

    const [image, setImage] = useState('');
    const handleClose = () => {
        onCloseDialog();
    };

    React.useEffect(() => {
        if (data) {
            const doc = new DOMParser().parseFromString(data, 'text/html');
            for (const elm of doc.querySelectorAll('*')) {
                if (elm.matches('img')) {
                    console.log("htmlparse ", elm);
                    elm.removeAttribute("classname");
                }
            }
            setImage(doc.body.innerHTML);
        }
    }, [data]);

    return (
        <div>
            <BootstrapDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
            >
                <DialogContent dividers>
                    {htmlParse(image)}
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default ViewImage