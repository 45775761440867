import {Button as AntdBTN, Table, Form, Input, Select, InputNumber, DatePicker, Modal} from "antd";
import {Box, Typography,} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ApiRequest from "../../api/axios";
import Constant from "../../common/constant/constant";
import dayjs from "dayjs";
import {enqueueSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SiteContext } from "../../context/SiteContext";

const {Option} = Select;

const Package = ({games}) => {

    const [status, setStatus] = useState('table');
    const [dataTable, setDataTable] = useState([]);
    const [dataForm, setDataForm] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [itemActive, setItemActive] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { state } = useContext(SiteContext);
    const gameId = state?.navbar?.gameId;

    const columns = [
        {
            title: 'ID', dataIndex: 'id', key: 'id',
        },
        {
            title: 'Product Id', dataIndex: 'SKU', key: 'SKU',
        },
        {
            title: 'Loại', dataIndex: 'type', key: 'type',
        }
        , {
            title: 'Game', dataIndex: 'game', key: 'game', render: (_, {game}) => {
                const index = games.findIndex((e) => e.id === game.id);
                return games[index].title;
            }
        }, {
            title: 'Tiêu đề', dataIndex: 'title', key: 'title',

        }, {
            title: 'Mã code', dataIndex: 'code', key: 'code',

        }, {
            title: 'Giá', dataIndex: 'price', key: 'price',

        }, {
            title: 'Giá local', dataIndex: 'amountInWallet', key: 'amountInWallet',

        }, {
            title: 'Mô tả', dataIndex: 'description', key: 'description',
        },
        {
            title: '', dataIndex: '', key: 'action',
            render: (_, data) => {
                return (
                    <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center">
                        <Typography>
                            <IconButton aria-label="edit" onClick={() => onActiveItem(data)}>
                                <EditOutlinedIcon/>
                            </IconButton>
                        </Typography>
                        <Typography>
                            <IconButton aria-label="edit" onClick={() => showModal(data)}>
                                <DeleteForeverIcon/>
                            </IconButton>
                        </Typography>
                    </Box>
                );
            },
        }
    ];

    const fetchData = async () => {
        const resp = await ApiRequest(Constant.GET, `${Constant.PACKAGE_URL}/server/${gameId}`);
        if (resp?.data) {
            setDataTable(resp.data?.data?.games);
        }
    }

    const onFinish = async (values) => {
        setIsloading(true);
        try {
            let resp;
            if (status === 'create') {
                resp = await ApiRequest(Constant.POST, Constant.PACKAGE_URL + '/create', values);
                if (resp?.data?.data?.game) {
                    enqueueSnackbar('Cập nhật thành công', {variant: 'success'});
                    setDataForm([]);
                    dataTable.unshift(resp?.data?.data?.game);
                    setDataTable(dataTable);
                    setStatus('table');
                }
            }
            if (status === 'update') {
                resp = await ApiRequest(Constant.PUT, Constant.PACKAGE_URL + '/update/' + itemActive, values)
                if (resp?.data?.data?.statusCode > 200) {
                    enqueueSnackbar(resp?.data?.data?.message, {variant: 'error'});
                    return;
                }
                if (resp?.data?.data) {
                    enqueueSnackbar('Cập nhật thành công', {variant: 'success'});
                    setDataForm([]);
                    const i = dataTable.findIndex((e) => Number(e.id) === Number(itemActive));
                    dataTable[i] = {...dataTable[i], ...values};
                    setDataTable(dataTable);
                    setStatus('table');
                }
            }
        } catch (e) {
            setIsloading(false)
        } finally {
            setIsloading(false);
        }
    };

    const onActiveItem = async (data) => {
        let dataActive = [];
        for (const [k, v] of Object.entries(data)) {
            if (k === 'id') {
                setItemActive(data.id);
                continue;
            }
            let obj = {name: [k], value: v};
            if (k === 'game') obj = {name: ['gameId'], value: v._id};
            dataActive.push(obj);
        }
        console.log('onActiveItem', dataActive)
        setDataForm(dataActive);
        setStatus('update');
    }

    const onFinishFailed = (errorInfo) => {
        enqueueSnackbar('Đã có lỗi xảy ra vui lòng thử lại sau!', {variant: 'error'})
    };

    const onChangeFields = (fields) => {
        console.log(fields);
        setDataForm(fields)
    }

    const showModal = (data) => {
        setDataForm(data);
        setItemActive(data.id);
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsloading(true);
        try {
            const resp = await ApiRequest(Constant.DELETE, `${Constant.PACKAGE_URL}/remove/${itemActive}`);
            if (resp?.data) {
                const i = dataTable.findIndex((e) => e.id === itemActive);
                dataTable.splice(i, 1);
                setDataTable([...dataTable]);
            }
        } catch (e) {
            setIsloading(false);
        } finally {
            setDataForm({});
            setItemActive({});
            setIsModalOpen(false);
            setIsloading(false);
            enqueueSnackbar('Cập nhật thành công!')
        }
    };

    const handleCancel = () => {
        setDataForm({});
        setItemActive({});
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, [gameId]);

    const renderTable = () => <Table
        scroll={{x: 2000}}
        style={{marginTop: '20px'}}
        dataSource={dataTable}
        columns={columns}
        pagination={{
            pageSize: 10,
        }}
        virtual/>;

    const renderForm = () => <Form
        name="basic"
        labelCol={{
            span: 8,
        }}
        wrapperCol={{
            span: 16,
        }}
        style={{
            marginTop: '20px', maxWidth: 600,
        }}
        onFieldsChange={(_, allFields) => onChangeFields(allFields)}
        fields={dataForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[{
                required: true, message: 'Please input your username!',
            },]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="Mô tả"
            name="description"
            rules={[{
                required: true, message: 'Please input your username!',
            },]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="ProductID"
            name="SKU"
            rules={[{
                required: true, message: 'Please input your username!',
            },]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="Giá"
            name="price"
            rules={[{
                required: true, message: 'Please input your username!',
            },]}
        >
            <InputNumber/>
        </Form.Item>

        <Form.Item
            label="Giá local"
            name="amountInWallet"
            rules={[{
                required: true, message: 'Please input local price!',
            },]}
        >
            <InputNumber/>
        </Form.Item>

        <Form.Item
            label="Mã Code"
            name="code"
            rules={[{
                required: true, message: 'Please input your username!',
            },]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="Game"
            name="gameId"
            rules={[{
                required: true, message: 'Vui lòng chọn Game!',
            },]}
        >
            <Select placeholder="Chọn Game">
                {games?.map((e) => <Option value={e?._id} key={e?._id}>{e?.title}</Option>)}
            </Select>
        </Form.Item>

        <Form.Item
            label="Loại"
            name="type"
            rules={[{
                required: true, message: 'Vui lòng chọn loại!',
            },]}
        >
            <Select placeholder="Please select a country">
                <Option value="APPLE">APPLE</Option>
                <Option value="GOOGLE">GOOGLE</Option>
            </Select>
        </Form.Item>


        <Form.Item
            wrapperCol={{
                offset: 8, span: 16,
            }}
        >
            <AntdBTN type="primary" htmlType="submit" disabled={isLoading}>
                {status === 'create' ? 'Thêm' : 'Cập nhật'}
            </AntdBTN>
        </Form.Item>
    </Form>

    const renderModal = () => <Modal title="Xoá gói nạp" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Bạn có muốn xoá gói
            nạp <b>{dataForm?.SKU}</b> - <b>{dataForm?.type}</b> - <b>{games.find((e) => e?.id === dataForm?.game?.id)?.title}</b>
        </p>
    </Modal>

    return <Box mb="20px">
        <AntdBTN type="primary"
                 onClick={() => setStatus(status === 'create' ? 'table' : 'create')}>{status === 'table' ? 'Thêm mới' : 'Danh sách'}</AntdBTN>
        {status === 'table' && renderTable()}
        {status !== 'table' && renderForm()}
        {renderModal()}
    </Box>
};

export default Package;