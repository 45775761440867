import './notification.scss';
import {
	TextField,
	Box,
	FormControl,
	Select,
	MenuItem,
	Typography,
	Switch,
} from '@mui/material';
import { Button } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, useContext } from 'react';
import ApiRequest from '../../api/axios';
import Modal from '../../components/modal/CustomModal';
import Review from '../../components/dialog/Review';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';
import { SiteContext } from '../../context/SiteContext';
import { makeStyles } from '@material-ui/core/styles';
import { renameKey } from '../../common/util';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

const usePlaceholderStyles = makeStyles((theme) => ({
	placeholder: {
		color: '#aaa',
	},
}));

const Placeholder = ({ children }) => {
	const classes = usePlaceholderStyles();
	return <div className={classes.placeholder}>{children}</div>;
};

const Notification = () => {
	const { state } = useContext(SiteContext);
	const [rows, setRows] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [inputReason, setInputReason] = useState(false);
	const [reviewShow, setReviewShow] = useState(false);
	const [finalClickInfo, setFinalClickInfo] = useState(null);
	const [reasonData, setReasonData] = useState([]);
	const [notificationData, setNotificationData] = useState([]);

	const [title, setTitle] = useState(Constant.EMPTY);
	const [idNotification, setIdNotification] = useState(Constant.EMPTY);
	const [convertedText, setConvertedText] = useState(Constant.EMPTY);
	const [platform, setPlatform] = useState(Constant.EMPTY);
	const [reason, setReason] = useState(Constant.EMPTY);

	const [message, setMessage] = useState(Constant.EMPTY);
	const addReason = {
		id: Constant.LABEL.DROPDOWN_ID_INSERT,
		name: Constant.LABEL.DROPDOWN_NAME_INSERT,
	};

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'title',
			headerName: 'Tiêu đề',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'title-column--cell',
		},
		{
			field: 'createdAt',
			headerName: 'Thời gian tạo',
			flex: 1,
			type: 'dateTime',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'createdAt-column--cell',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value)).format(
					Constant.FORMAT.FULLDATE
				) +
				' ' +
				dayjs(new Date(params?.value)).format(Constant.FORMAT.TIME),
		},
		{
			field: 'userName',
			headerName: 'Người tạo',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'userName-column--cell',
		},
		{
			field: 'errorType',
			headerName: 'Nguyên nhân',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'errorType-column--cell',
		},
		{
			field: 'statusMessage',
			headerName: 'Status',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { statusMessage } }) => {
				return (
					<Box
						width="60%"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<Typography sx={{ ml: '5px' }}>
							<Switch checked={statusMessage} />
						</Typography>
					</Box>
				);
			},
		},
	];

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item.name} key={item.id}>
						<span>{item.name}</span>
					</MenuItem>
				);
			});
		}
	};

	const handleOnCellClick = (params) => {
		setFinalClickInfo(params);
		if (params.field === Constant.FIELD.STATUS) {
			handleUpdateStatusNotification(params.row);
		} else {
			setRows(params.row);
			setReviewShow(true);
		}
	};

	const handleCloseModal = (event, data) => {
		if (inputReason) {
			handleInsertReason(data);
		} else {
			handleNotification();
		}
		setModalShow(false);
	};

	const handleCloseDialog = () => {
		setReviewShow(false);
	};

	const handleChangeReason = (event) => {
		if (event.target.value === addReason.name) {
			setShowModal(true);
		} else {
			setReason(event.target.value);
		}
	};

	const setClearControl = () => {
		setIdNotification(Constant.EMPTY);
		setTitle(Constant.EMPTY);
		setConvertedText(Constant.EMPTY);
		setReason(Constant.EMPTY);
		setPlatform(Constant.EMPTY);
	};

	const setShowModal = (check) => {
		if (check) {
			setInputReason(check);
			setMessage(Message.MS0008);
		} else {
			setInputReason(check);
			setMessage(Message.MS0009);
		}
		setModalShow(true);
	};

	const handleInsertReason = async (name) => {
		let response;
		let body = {
			name: name,
		};
		response = await ApiRequest(
			Constant.POST,
			Constant.REASON_URL + '/add',
			body
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		if (data.reasons.length > 0) {
			data.reasons.forEach((obj) => renameKey(obj, '_id', 'id'));
			data.reasons.push(addReason);
			setReasonData(data.reasons);
		}
		setInputReason(false);
	};

	const handleUpdateStatusNotification = async (row) => {
		let response;
		let body = {
			gameId: row.gameId.id,
			status: !row.statusMessage,
		};
		response = await ApiRequest(
			Constant.PUT,
			Constant.NOTIFICATION_URL + `/${row.id}`,
			body
		);
		if (!response?.data) return;
		enqueueSnackbar('Cập nhật thành công!', { variant: 'success' });
		const index = notificationData.findIndex((e) => e.id === row.id);
		for (const value of notificationData) {
			value.statusMessage = false;
		}
		const newArray = [...notificationData];
		newArray.splice(index, 1);
		row.statusMessage = body.status;
		setNotificationData([row, ...newArray]);
	};

	const handleNotification = async () => {
		let response;
		let body = {
			gameId: state?.navbar?.id || window.localStorage.getItem('GAME'),
			errorType: reason,
			title: title,
			message: convertedText,
			userId: state?.login?.id,
			userName: state?.login?.name,
		};
		response = await ApiRequest(
			Constant.POST,
			Constant.NOTIFICATION_URL,
			body
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		for (const v of notificationData) {
			v.statusMessage = false;
		}
		enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
		renameKey(data.notifications, '_id', 'id');
		setNotificationData((notificationData) => [
			data.notifications,
			...notificationData,
		]);
		setClearControl();
	};

	const init = async () => {
		let response = await ApiRequest(
			Constant.GET,
			Constant.REASON_URL,
			null,
			null,
			state?.login?.accessToken
		);
		if (!response?.data) return;
		const dataReason = response?.data?.data;
		if (dataReason.reasons.length > 0) {
			dataReason.reasons.forEach((obj) => renameKey(obj, '_id', 'id'));
			dataReason.reasons.push(addReason);
			setReasonData(dataReason.reasons);
		} else {
			dataReason.reasons.push(addReason);
			setReasonData(dataReason.reasons);
		}

		response = await ApiRequest(
			Constant.GET,
			Constant.NOTIFICATION_URL + `?gameId=${state?.navbar?.id}`
		);
		if (!response?.data) return;
		const dataNotification = response?.data?.data;
		if (dataNotification.notifications.length > 0) {
			dataNotification.notifications.forEach((obj) =>
				renameKey(obj, '_id', 'id')
			);
			setNotificationData(dataNotification.notifications);
		}
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		init();
	}, [state]);

	return (
		<div className="home-notification">
			<Box m="20px">
				<Box display="flex" alignItems="center" flexDirection="row">
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex={1}
					>
						<FormControl
							sx={{ width: '100%', top: -8 }}
							size="small"
							variant="standard"
						>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-select-small"
								label="Reason"
								value={reason}
								onChange={handleChangeReason}
								displayEmpty
								renderValue={
									reason !== Constant.EMPTY
										? undefined
										: () => (
												<Placeholder>
													{Constant.LABEL.REASON_EN}
												</Placeholder>
										  )
								}
							>
								{listItems(reasonData)}
							</Select>
						</FormControl>
					</Box>
				</Box>

				<Box display="flex" alignItems="center" flexDirection="row">
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex="1"
					>
						<TextField
							placeholder="Tiêu đề"
							variant="standard"
							size="small"
							style={{ width: '100%' }}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</Box>
				</Box>

				<Box
					mt="20px"
					display="flex"
					alignItems="center"
					flexDirection="row"
				>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex="1"
					>
						<TextField
							placeholder="Nội dung"
							variant="standard"
							size="small"
							fullWidth
							InputProps={{
								disableUnderline: true,
							}}
							multiline
							rows={10}
							maxRows={20}
							value={convertedText}
							onChange={(e) => setConvertedText(e.target.value)}
						/>
					</Box>
				</Box>

				<Box
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexDirection="row"
				>
					<Button
						className="btnRed"
						onClick={() => setShowModal(false)}
					>
						{Constant.BUTTON.SEND}
					</Button>
				</Box>

				<Box
					m="10px 0 0 0"
					height="80vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={notificationData}
						columns={columns}
						autoPageSize
						onCellClick={handleOnCellClick}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns status and traderName, the other columns will remain visible
									id: false,
								},
							},
						}}
					/>
				</Box>
			</Box>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				message={message}
				btnCancelShow="true"
				btnAgreeShow="true"
				showInputReason={inputReason}
				onCloseModal={handleCloseModal}
			/>
			<Review
				open={reviewShow}
				onCloseDialog={handleCloseDialog}
				data={rows}
				reasons={rows?.errorType}
			/>
		</div>
	);
};

export default Notification;
