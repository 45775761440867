import Header from "../../components/header/Header";
import "./home.scss";
import { Box } from "@mui/material";

const Home = () => {

    return (
        <div className="home-main">
            <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="DASHBOARD" subtitle="Welcome to your dashboard!" />
                </Box>
            </Box>
        </div>
    )
}

export default Home