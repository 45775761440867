import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Constant from '../common/constant/constant';

const Layout = ({ isAllowed, redirectPath = '/login', children }) => {
	const [isMobile, setIsMobile] = useState(false);

	//choose the screen size
	const handleResize = () => {
		console.log('resize', window.innerWidth);
		if (window.innerWidth <= 768) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	});

	// if (isMobile) {
	// 	return <main> Hiện trang web không cho dùng ở chế độ response </main>;
	// }

	return (
		<main className="App">
			<Grid container>
				{!isMobile && (
					<Grid item md={2}>
						<Sidebar />
					</Grid>
				)}

				<Grid item xs={12} md={10}>
					<div className="homeContainer">
						{!isMobile && <Navbar />}
						{children ? children : <Outlet />}
					</div>
				</Grid>
			</Grid>
		</main>
	);
};

export default Layout;
