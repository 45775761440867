import { Link, useNavigate } from 'react-router-dom';
import './accounts.scss';
import Modal from '../../components/modal/CustomModal';
import { Typography, Button, Box } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid } from '@mui/x-data-grid';
import ApiRequest from '../../api/axios';
import { SiteContext } from '../../context/SiteContext';
import Message from '../../common/message/message';
import Constant from '../../common/constant/constant';
import { renameKey } from '../../common/util';
import { enqueueSnackbar } from 'notistack';

const Accounts = () => {
	const { state, dispatch } = useContext(SiteContext);
	const [listAccount, setListAccount] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const navigate = useNavigate();
	const [dense] = useState(false);
	const [lockEmail, setLockEmail] = useState();
	const token = state.login?.accessToken;

	// let listItems = (datas) => {
	//     if (datas.length == 0) {
	//         return <></>;
	//     } else {
	//         return datas.map((item, index) => {
	//             return (
	//                 <ListItemButton className="ListItem"
	//                     selected={selectedIndex === index}
	//                     onClick={(event) => handleListItemClick(event, index, item)}
	//                     key={item._id}
	//                 >
	//                     <FormControlLabel value={item._id} control={<Radio />} label={item.name} />
	//                     <div>
	//                         <Link to="/accountdetail" state={{ from: item._id }} style={{ textDecoration: "none" }}>
	//                             <IconButton aria-label="edit"
	//                                 onClick={(event) => handleListItemEditClick(event, index, item)}>
	//                                 <EditOutlinedIcon />
	//                             </IconButton>
	//                         </Link>
	//                         <IconButton aria-label="delete"
	//                             onClick={(event) => handleDeleteAccount(event, item)}>
	//                             <DeleteIcon />
	//                         </IconButton>
	//                     </div>
	//                 </ListItemButton>
	//             )
	//         });
	//     }
	// };

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'id-column--cell',
		},
		{
			field: 'email',
			headerName: 'Email',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'email-column--cell',
		},
		{
			field: 'roles',
			headerName: 'Vị trí',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'roles-column--cell',
			valueGetter: (params) =>
				`${params?.row?.roles[0]?.code || Constant.EMPTY}`,
		},
		{
			field: 'departments',
			headerName: 'Bộ phận',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'departments-column--cell',
			valueGetter: (params) =>
				`${params?.row?.departments[0]?.code || Constant.EMPTY}`,
		},
		{
			field: '',
			headerName: 'Thao tác',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row }) => {
				if (row?.id === '64aba33f0a53c581e1e1052b') return;
				return (
					<Box
						width="60%"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<Typography sx={{ ml: '5px' }}>
							{row.status ? (
								<IconButton
									aria-label="lock"
									onClick={(event) => setModalShow(true)}
								>
									<LockOutlinedIcon />
								</IconButton>
							) : (
								<IconButton
									aria-label="unlock"
									onClick={(event) => setModalShow(true)}
								>
									<LockOpenOutlinedIcon />
								</IconButton>
							)}

							<IconButton
								aria-label="edit"
								onClick={(event) =>
									handleListItemEditClick(event, row)
								}
							>
								<EditOutlinedIcon />
							</IconButton>
							<IconButton
								aria-label="delete"
								onClick={(event) =>
									handleDeleteAccount(event, row)
								}
							>
								<DeleteIcon />
							</IconButton>
						</Typography>
					</Box>
				);
			},
		},
	];

	const handleRowClick = (item) => {
		setLockEmail(item.row);
	};

	const handleListItemEditClick = (event, item) => {
		event.preventDefault();
		const path = Constant.PATH_SLASH + Constant.PATH_ACCOUNTDETAIL;
		navigate(path, {
			state: {
				from: item.email,
			},
		});
	};

	const handleCloseModal = () => {
		setModalShow(false);
		handleLockAccount(lockEmail);
	};

	const handleLockAccount = async (item) => {
		let response;
		const body = {
			email: item.email,
			status: !item.status,
		};
		response = await ApiRequest(
			Constant.PUT,
			Constant.ACCOUNTS_LOCKLOGIN_URL,
			body
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		data.accounts.forEach((obj) => renameKey(obj, '_id', 'id'));
		setListAccount(data.accounts);
	};

	const handleDeleteAccount = async (event, item) => {
		event.preventDefault();
		const response = await ApiRequest(
			Constant.DELETE,
			`${Constant.ACCOUNTS_URL}/${item.email}`
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		data.accounts.forEach((obj) => renameKey(obj, '_id', 'id'));
		setListAccount(data.accounts);
		enqueueSnackbar(`Xoá tài khoản ${item.email} thành công!`, {
			variant: 'success',
		});
	};

	const init = async () => {
		const response = await ApiRequest(Constant.GET, Constant.ACCOUNTS_URL);
		if (!response?.data) return;
		const data = response?.data?.data;
		if (data.accounts.length > 0) {
			data.accounts.forEach((obj) => renameKey(obj, '_id', 'id'));
			setListAccount(data.accounts);
		}
	};

	useEffect(() => {
		if (!token) return;
		init();
	}, [state]);

	return (
		<div className="home-accounts">
			<Box m="20px">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<span>{Constant.LABEL.ACCOUNT_EMPLOYEE}</span>
					<div>
						<Link
							to={
								Constant.PATH_SLASH +
								Constant.PATH_ACCOUNTDETAIL
							}
							state={{ from: '' }}
							style={{ textDecoration: 'none' }}
						>
							<Button
								variant="text"
								className="btnAccounts btnFontGreen"
							>
								{Constant.BUTTON.ADD_NEW_ACCOUNT}
							</Button>
						</Link>
					</div>
				</Box>
				<Box
					m="10px 0 0 0"
					height="78vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={listAccount}
						columns={columns}
						onRowClick={handleRowClick}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns status and traderName, the other columns will remain visible
									id: false,
								},
							},
						}}
					/>
				</Box>
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					message={Message.MS0001}
					btnCancelShow="true"
					btnAgreeShow="true"
					onCloseModal={handleCloseModal}
				/>
			</Box>
		</div>
	);
};

export default Accounts;
