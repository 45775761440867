import { useNavigate } from 'react-router-dom';
import './changepwd.scss';
import {
	TextField,
	Button,
	Box,
	InputAdornment,
	IconButton,
	OutlinedInput,
} from '@mui/material';
import { useContext, useState } from 'react';
import { SiteContext } from '../../context/SiteContext';
import ApiRequest from '../../api/axios';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';
import md5 from 'md5';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ChangePwd = () => {
	const navigate = useNavigate();
	const { state, dispatch } = useContext(SiteContext);
	const [oldPwd, setOldPwd] = useState(Constant.EMPTY);
	const [newPwd, setNewPwd] = useState(Constant.EMPTY);
	const [confirmPwd, setConfirmPwd] = useState(Constant.EMPTY);
	const [typeInput, setTypeInput] = useState({
		isOldHiden: true,
		isNewHiden: true,
		isConfimHiden: true,
	});

	const handleClickShowPassword = (key, value) => {
		typeInput[key] = value;
		setTypeInput({ ...typeInput });
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleUpdate = async () => {
		const email = state.login.email;
		const dataLocal = JSON.parse(window.localStorage.getItem('LOGIN'));
		// const accessToken = state.login.tokens.accessToken;
		const id = state.login.id;
		if (!newPwd) alert(Message.ER0005);
		else if (!confirmPwd) alert(Message.ER0006);
		else if (newPwd !== confirmPwd) alert(Message.ER0007);
		else {
			const response = await ApiRequest(
				Constant.PUT,
				Constant.CHANGEPWD_URL,
				{
					email: email,
					password: md5(newPwd),
				}
			);
			console.log(JSON.stringify(response?.data));
			if (!response?.data) return;
			dispatch({ type: 'CHANGEPWD', login: { id, email, newPwd } });
			console.log('Go back Profile!!!!!');
			navigate(Constant.PATH_SLASH + Constant.PATH_PROFILE);
		}
	};

	return (
		<div className="home-changepwd">
			<form>
				<Box m="20px" className="infomation">
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<h3>{Constant.LABEL.PROFILE_PASSWORD}</h3>
					</Box>
					<Box m="10px" display="flex" flexDirection="row">
						<span className="changepwditem borderUserItem">
							{Constant.LABEL.CURENT_PASSWORD}
						</span>
						<TextField
							placeholder={Constant.PLACEHOLDER.CURRENT_PASSWORD}
							type={typeInput?.isOldHiden ? 'password' : 'text'}
							onChange={(e) => setOldPwd(e.target.value)}
							value={oldPwd}
							variant="standard"
							className="changepwditem"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												handleClickShowPassword(
													'isOldHiden',
													!typeInput?.isOldHiden
												)
											}
											onMouseDown={
												handleMouseDownPassword
											}
										>
											{typeInput?.isOldHiden ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box m="10px" display="flex" flexDirection="row">
						<span className="changepwditem borderUserItem">
							{Constant.LABEL.NEW_PASSWORD}
						</span>
						<TextField
							placeholder={Constant.PLACEHOLDER.NEW_PASSWORD}
							type={typeInput?.isNewHiden ? 'password' : 'text'}
							onChange={(e) => setNewPwd(e.target.value)}
							value={newPwd}
							variant="standard"
							className="changepwditem"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												handleClickShowPassword(
													'isNewHiden',
													!typeInput?.isNewHiden
												)
											}
											onMouseDown={
												handleMouseDownPassword
											}
										>
											{typeInput?.isNewHiden ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box m="10px" display="flex" flexDirection="row">
						<span className="changepwditem borderUserItem">
							{Constant.LABEL.CONFIRM_PASSWORD}
						</span>
						<TextField
							variant="standard"
							placeholder={Constant.PLACEHOLDER.CONFRIM_PASSWORD}
							type={
								typeInput?.isConfimHiden ? 'password' : 'text'
							}
							onChange={(e) => setConfirmPwd(e.target.value)}
							value={confirmPwd}
							className="changepwditem"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												handleClickShowPassword(
													'isConfimHiden',
													!typeInput?.isConfimHiden
												)
											}
											onMouseDown={
												handleMouseDownPassword
											}
										>
											{typeInput?.isConfimHiden ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box display="flex" alignItems="center">
						<Button variant="contained" onClick={handleUpdate}>
							{Constant.BUTTON.UPDATE}
						</Button>
					</Box>
				</Box>
			</form>
		</div>
	);
};

export default ChangePwd;
