import './project.scss';
import {useNavigate} from 'react-router-dom';
import {
    TextField,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Input,
    Switch,
} from '@mui/material';
import {useState, useEffect, useRef, useContext} from 'react';
import ApiRequest, {ApiRequestUpload} from '../../api/axios';
import Button from 'react-bootstrap/Button';
import {DataGrid} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Constant from '../../common/constant/constant';
import dayjs from 'dayjs';
import {enqueueSnackbar} from 'notistack';
import {SiteContext} from '../../context/SiteContext';
import {gameStatus} from '../../data/mockDataUser';
import {AddCard} from '@mui/icons-material';
import {Col, Modal, Row, Button as AntdBTN} from 'antd';
import Package from "./Package";

const Project = () => {
    const navigate = useNavigate();
    const [listGameCategory, setListGameCategory] = useState([]);
    const [game, setGame] = useState({});
    const [errorTexts, setErrorTexts] = useState({});
    const [listGame, setListGame] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const textInputI = useRef();
    const {state} = useContext(SiteContext);
    const [status, setStatus] = useState('product');

    const columns = [
        {
            field: '_id',
            headerName: '',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            cellClassName: 'id-column--cell',
        },
        {
            field: 'code',
            headerName: 'Mã dự án',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            cellClassName: 'project_code-column--cell',
        },
        {
            field: 'title',
            headerName: 'Tên dự án',
            flex: 1,
            headerAlign: 'center',
            align: 'start',
            cellClassName: 'project_name-column--cell',
        },
        {
            field: 'categoryIds.title',
            headerName: 'Thể loại',
            headerAlign: 'center',
            flex: 1,
            align: 'start',
            cellClassName: 'game_category-column--cell',
            valueGetter: (params) => `${params?.row?.categoryIds[0]?.title || Constant.EMPTY}`,
        },
        {
            field: 'thumbnail',
            headerName: 'Icon',
            flex: 1,
            headerAlign: 'center',
            align: 'start',
            cellClassName: 'icon-column--cell',
            renderCell: ({row: {thumbnail}}) => {
                if (thumbnail === 'catrestaurent.webp') return <></>;
                return (
                    <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center">
                        <Typography sx={{ml: '5px'}}>
                            <img
                                src={`https://upload.goodgames.vn/uploads/${thumbnail}`}
                                className="iconGrid"
                                alt="icon-game"
                            />
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Ngày tạo',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            type: 'date',
            cellClassName: 'createdAt-column--cell',
            valueFormatter: (params) =>
                params?.value ? dayjs(new Date(params?.value)).format(Constant.FORMAT.FULLDATE) : Constant.EMPTY,
        },
        {
            field: 'startAt',
            headerName: 'Ngày mở game',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            type: 'date',
            cellClassName: 'openDate-column--cell',
            valueFormatter: (params) =>
                params?.value ? dayjs(new Date(params?.value)).format(Constant.FORMAT.FULLDATE) : Constant.EMPTY,
        },
        {
            field: 'endAt',
            headerName: 'Ngày đóng game',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            type: 'date',
            cellClassName: 'closeDate-column--cell',
            valueFormatter: (params) =>
                params?.value ? dayjs(new Date(params?.value)).format(Constant.FORMAT.FULLDATE) : Constant.EMPTY,
        },
        // {
        // 	field: 'creator',
        // 	headerName: 'Người tạo',
        // 	flex: 1,
        // 	headerAlign: 'center',
        // 	align: 'center',
        // 	cellClassName: 'creator-column--cell',
        // },
        {
            field: 'status',
            headerName: 'Trạng thái',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'status-column--cell',
            renderCell: ({row: {status}}) => {
                return (
                    <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center">
                        <Typography sx={{ml: '5px'}}>
                            <span
                                style={{
                                    color: status === 3 ? 'red' : status === 1 ? 'green' : 'gray',
                                }}
                            >
                                {gameStatus.find((e) => e.id === status)?.label || ''}
                            </span>
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: '',
            headerName: '',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'last_login-column--cell',
            renderCell: ({row}) => {
                return (
                    <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center">
                        <Typography>
                            <IconButton aria-label="edit" onClick={(event) => handleListItemEditClick(event, row)}>
                                <EditOutlinedIcon/>
                            </IconButton>
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    const disableKeyValueNoChecks = ['emailSupport', 'fanPage', 'hotline', 'ticket'];
    const handleChangeField = (key, value, isUrl) => {
        if (value === '' && !disableKeyValueNoChecks.includes(key)) {
            errorTexts[key] = 'Không được để trống!';
            setErrorTexts({...errorTexts});
            setIsLoading(true);
        } else {
            if (errorTexts[key]?.length > 0) {
                delete errorTexts[key];
                setErrorTexts({...errorTexts});
            }
            setIsLoading(false);
        }

        if (isUrl) {
            const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
            // const checkValue = regex.test(value);
            const checkValue = true;
            if (!checkValue) {
                errorTexts[key] = 'Url không đúng định dạng!';
                setErrorTexts({...errorTexts});
                setIsLoading(true);
            } else {
                if (errorTexts[key]?.length > 0) {
                    delete errorTexts[key];
                    setErrorTexts({...errorTexts});
                }
                setIsLoading(false);
            }
        }
        game[key] = value;
        setGame({...game});
    };

    const createNewGame = async () => {
        console.log(game);

        setIsLoading(true);
        try {
            if (game?.thumbnail) {
                const dataForm = new FormData();
                dataForm.append('file', game.thumbnail);
                const upload = await ApiRequestUpload(dataForm);
                game.thumbnail = upload?.data?.name;
            }
            game.isShow = String(game?.isShow || false);
            const response = await ApiRequest(Constant.POST, `${Constant.GAME_URL}/create`, game);
            setIsLoading(false);
            if (!response?.data) return;
            enqueueSnackbar('Thêm mới thành công!', {variant: 'success'});
            const data = response?.data?.data?.game;
            for (const e of listGameCategory) {
                if (e?._id === data.categoryIds[0]) {
                    data.categoryIds[0] = e;
                    break;
                }
            }
            setListGame([...listGame, data]);
            setGame({});
        } catch (e) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    let listItems = (datas) => {
        if (datas.length === 0) {
            return;
        } else {
            return datas.map((item) => {
                return (
                    <MenuItem value={item._id} key={item._id}>
                        <span>{item.title}</span>
                    </MenuItem>
                );
            });
        }
    };

    const handleListItemEditClick = (event, item) => {
        event.preventDefault();
        navigate(Constant.PATH_SLASH + Constant.PATH_EDITPROJECT, {
            state: {
                pj: item._id,
                gameId: item?.id,
            },
        });
    };

    const init = async () => {
        let [responseGameCategory, responseGame] = await Promise.all([
            ApiRequest(Constant.GET, Constant.GAMECATEGORY_URL),
            ApiRequest(Constant.GET, Constant.GAME_URL),
        ]);
        if (!responseGameCategory?.data || !responseGame?.data) return;
        const dataGameCategory = responseGameCategory?.data?.data?.gamescategory;
        if (dataGameCategory.list.length > Constant.ZERO) {
            setListGameCategory(dataGameCategory.list);
        }

        const dataGame = responseGame?.data?.data.games.list;
        if (dataGame.length > Constant.ZERO) {
            setListGame(dataGame);
        }
    };

    useEffect(() => {
        if (!state?.navbar?.id) return;
        init();
    }, [state]);

    const renderProduct = () => (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <span style={{fontWeight: 'bold'}}>{Constant.LABEL.PROJECT_NEW}</span>
            </Box>

            <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">{Constant.LABEL.PROJECT_NAME}*</span>
                        <TextField
                            error={errorTexts?.title?.length > 0}
                            helperText={errorTexts?.title || ''}
                            placeholder="Nhập tên dự án"
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('title', e?.target?.value, false)}
                            value={game?.title || ''}
                        />
                    </Box>
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">
                            {Constant.LABEL.PROJECT_CATEGORY}*
                        </span>
                        <FormControl
                            variant="standard"
                            sx={{minWidth: 190}}
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                        >
                            <InputLabel id="demo-simple-select-standard-label" sx={{opacity: 0.7}}>
                                Nhập thể loại
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={game?.categoryIds || ''}
                                onChange={(e) => handleChangeField('categoryIds', e?.target?.value, false)}
                                error={errorTexts?.categoryIds?.length > 0}
                            >
                                {listItems(listGameCategory)}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box ml="10px" display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">{Constant.LABEL.PROJECT_CODE}*</span>
                        <TextField
                            value={game?.code || ''}
                            error={errorTexts?.code?.length > 0}
                            helperText={errorTexts?.code || ''}
                            placeholder="Nhập mã dự án"
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('code', e?.target?.value, false)}
                        />
                    </Box>
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">{Constant.LABEL.PROJECT_ICON}</span>
                        <input
                            type="file"
                            id="upload-photo"
                            name="upload-photo"
                            style={{marginTop: '16px', width: '100%'}}
                            ref={textInputI}
                            accept="image/*"
                            // value={game?.thumbnail || ''}
                            onChange={(e) =>
                                setGame({
                                    ...game,
                                    thumbnail: e.target.files[0],
                                })
                            }
                        />
                    </Box>
                </Box>
            </Box>

            {/* link hotline and fanPage */}
            <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">Hotline*</span>
                        <TextField
                            value={game?.hotline || ''}
                            error={errorTexts?.hotline?.length > 0}
                            helperText={errorTexts?.hotline || ''}
                            placeholder={`Nhập hotline`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('hotline', e?.target?.value, false)}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">Fanpage</span>
                        <TextField
                            value={game?.fanPage || ''}
                            error={errorTexts?.fanPage?.length > 0}
                            helperText={errorTexts?.fanPage || ''}
                            placeholder={`Nhập Fanpage`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('fanPage', e?.target?.value, true)}
                        />
                    </Box>
                </Box>
            </Box>

            {/* url */}
            <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">{Constant.LABEL.URL_GAME_INFO}*</span>
                        <TextField
                            value={game?.urlGameInfo || ''}
                            error={errorTexts?.urlGameInfo?.length > 0}
                            helperText={errorTexts?.urlGameInfo || ''}
                            placeholder={`Nhập ${Constant.LABEL.URL_GAME_INFO}`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('urlGameInfo', e?.target?.value, true)}
                        />
                    </Box>
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">{Constant.LABEL.URL_GAME}*</span>
                        <TextField
                            value={game?.urlCallbackGame || ''}
                            error={errorTexts?.urlCallbackGame?.length > 0}
                            helperText={errorTexts?.urlCallbackGame || ''}
                            placeholder={`Nhập ${Constant.LABEL.URL_GAME}`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            sx={{marginTop: '16px'}}
                            onChange={(e) => handleChangeField('urlCallbackGame', e?.target?.value, true)}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom">{Constant.LABEL.URL_PAYMENT}*</span>
                        <TextField
                            value={game?.urlCallbackPayment || ''}
                            error={errorTexts?.urlCallbackPayment?.length > 0}
                            helperText={errorTexts?.urlCallbackPayment || ''}
                            placeholder={`Nhập ${Constant.LABEL.URL_PAYMENT}`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            onChange={(e) => handleChangeField('urlCallbackPayment', e?.target?.value, true)}
                        />
                    </Box>
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">{Constant.LABEL.URL_SERVICE}*</span>
                        <TextField
                            value={game?.urlService || ''}
                            error={errorTexts?.urlService?.length > 0}
                            helperText={errorTexts?.urlService || ''}
                            placeholder={`Nhập ${Constant.LABEL.URL_SERVICE}`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            sx={{marginTop: '16px'}}
                            onChange={(e) => handleChangeField('urlService', e?.target?.value, true)}
                        />
                    </Box>
                </Box>
            </Box>

            {/* ticket & email support */}
            <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">Ticket</span>
                        <TextField
                            value={game?.ticket || ''}
                            error={errorTexts?.ticket?.length > 0}
                            helperText={errorTexts?.ticket || ''}
                            placeholder={`Nhập link ticket`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            sx={{marginTop: '16px'}}
                            onChange={(e) => handleChangeField('ticket', e?.target?.value, true)}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem borderBottom spanSelect">Email hỗ trợ</span>
                        <TextField
                            value={game?.emailSupport || ''}
                            error={errorTexts?.emailSupport?.length > 0}
                            helperText={errorTexts?.emailSupport || ''}
                            placeholder={`Nhập email hỗ trợ`}
                            variant="standard"
                            size="small"
                            className="inputProjectItem inputChangeBorderBottom"
                            sx={{marginTop: '16px'}}
                            onChange={(e) => handleChangeField('emailSupport', e?.target?.value, true)}
                        />
                    </Box>
                </Box>
            </Box>

            {/* status */}
            <Box m="10px" display="flex" alignItems="center" flexDirection="row">
                <Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
                    <Box my="10px" display="flex" flexDirection="row">
                        <span className="labelProjectItem " style={{width: '20%'}}>
                            {Constant.LABEL.PROJECT_IS_SHOW}
                        </span>
                        <div className="inputProjectItem spanSelect">
                            <Switch
                                checked={game?.isShow}
                                onChange={(e) => handleChangeField('isShow', e?.target?.checked, false)}
                            />
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row">
                <Button className="btnRed" onClick={() => createNewGame()} disabled={isLoading}>
                    {Constant.BUTTON.CONFIRM}
                </Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        // color: colors.greenAccent[300],
                        //borderBottom: "none",
                    },
                    '& .MuiDataGrid-row': {
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiTablePagination-toolbar': {
                        display: 'flex',
                        alignItems: 'baseline',
                    },
                }}
            >
                <DataGrid
                    rows={listGame}
                    columns={columns}
                    autoPageSize
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'id', sort: 'desc'}],
                        },
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                _id: false,
                            },
                        },
                    }}
                />
            </Box>
        </>
    );

    const renderPackage = () => <Package games={listGame}/>

    const renderBtnChangeStatus = () => (
        <Row style={{marginBottom: '10px'}}>
            <AntdBTN type={status === 'product' ? 'primary' : 'dashed'} onClick={() => setStatus('product')}>
                Dự án{' '}
            </AntdBTN>
            <AntdBTN style={{marginLeft: '10px'}} type={status === 'package' ? 'primary' : 'dashed'}
                     onClick={() => setStatus('package')}>
                Gói nạp
            </AntdBTN>
        </Row>
    );

    return (
        <div className="home-project">
            <Box m="20px">
                {renderBtnChangeStatus()}
                {status === 'product' && renderProduct()}
                {status === 'package' && renderPackage()}
            </Box>
        </div>
    );
};

export default Project;
