import React, { useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.scss';
// import { ImageUpload } from 'services/image/ImageUpload';
import ImageUploader from 'quill-image-uploader';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';

Quill.register('modules/imageUploader', ImageUploader);

var Image = Quill.import('formats/image');
Image.className = 'imageThumbnail';
Quill.register(Image, true);

export default function Editor({ value, setValue, style }) {
	const quillRef = useRef();

	const saveToServer = (formData) => {
		try {
			fetch(
				'https://api.imgbb.com/1/upload?key=334ecea9ec1213784db5cb9a14dac265',
				{
					method: 'POST',
					body: formData,
				}
			)
				.then((response) => response.json())
				.then((result) => {
					const editor = quillRef.current.getEditor();
					return editor.insertEmbed(
						editor.getSelection(),
						'image',
						result.data.url
					);
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		} catch (err) {
			if (!err?.response) {
				alert(Message.ER0014);
			} else if (err.response?.status) {
				alert(err.response?.data.message);
			} else {
				alert(Message.ER0013);
			}
		}
	};

	const imageHandler = (e) => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();
		input.onchange = () => {
			console.log(input.files);
			const file = input.files[0];
			if (/^image\//.test(file.type)) {
				console.log(file);
				const formData = new FormData();
				formData.append('image', file);
				// console.log(formData);
				saveToServer(formData); // upload data into server or aws or cloudinary
			} else {
				// ErrorToast('You could only upload images.');
			}
		};
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					['link'],
					['image'],
					[{ font: [] }],
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', { color: [] }],
					[{ align: [] }],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						{ indent: '-1' },
						{ indent: '+1' },
						'blockquote',
					],
					['strike'],
					['clean'],
				],
				handlers: {
					image: imageHandler,
				},
			},
		}),
		[]
	);

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'align',
		'color',
		'imageBlot', // #5 Optinal if using custom formats
	];

	return (
		<>
			<ReactQuill
				theme="snow"
				ref={quillRef}
				value={value}
				modules={modules}
				formats={formats}
				onChange={setValue}
				style={style}
			/>
		</>
	);
}
