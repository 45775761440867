import './transactionreport.scss';
import {
	TextField,
	Box,
	FormControl,
	Select,
	MenuItem,
	Typography,
	InputLabel,
	Grid,
} from '@mui/material';
import Button from 'react-bootstrap/Button';
import {
	platformData,
	mockTransactionData,
	paymentData,
} from '../../data/mockDataUser';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from 'antd';
import ApiRequest from '../../api/axios';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useContext, useEffect, useState } from 'react';
import Constant from '../../common/constant/constant';
import { makeStyles } from '@material-ui/core/styles';
import { SiteContext } from '../../context/SiteContext';
const { RangePicker } = DatePicker;

const usePlaceholderStyles = makeStyles((theme) => ({
	placeholder: {
		color: '#aaa',
	},
}));

const Placeholder = ({ children }) => {
	const classes = usePlaceholderStyles();
	return <div className={classes.placeholder}>{children}</div>;
};

const TransactionReport = () => {
	const [game, setGame] = useState(Constant.EMPTY);
	const [listGame, setListGame] = useState([]);
	const [platform, setPlatform] = useState(Constant.EMPTY);
	const [from, setFrom] = useState(Constant.EMPTY);
	const [to, setTo] = useState(Constant.EMPTY);
	const [type, setType] = useState(Constant.EMPTY);
	const [inputSearch, setInputSearch] = useState({});
	const [listPurrchase, setListPurrchase] = useState([]);
	const { state } = useContext(SiteContext);

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item, i) => {
				return (
					<MenuItem value={item?._id} key={i}>
						<span>{item?.code}</span>
					</MenuItem>
				);
			});
		}
	};

	let listItemsGame = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item, i) => {
				return (
					<MenuItem value={item?._id} key={i}>
						<SportsEsportsIcon />
						<span className="game">{item?.title}</span>
					</MenuItem>
				);
			});
		}
	};

	const columns = [
		{
			field: 'order',
			headerName: 'STT',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'order-column--cell',
		},
		{
			field: 'transaction_id',
			headerName: 'Transaction ID',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'transaction_id-column--cell',
		},
		{
			field: 'app_id',
			headerName: 'App ID',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'app_id-column--cell',
		},
		{
			field: 'server_game',
			headerName: 'Server game',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'server_game-column--cell',
		},
		{
			field: 'user_id',
			headerName: 'User ID',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'user_id-column--cell',
		},
		{
			field: 'user_name',
			headerName: 'User name',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'user_name-column--cell',
		},
		{
			field: 'role_id',
			headerName: 'Role ID',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'role_id-column--cell',
		},
		{
			field: 'role_name',
			headerName: 'Role Name',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'role_name-column--cell',
		},
		{
			field: 'gpa',
			headerName: 'GPA',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'gpa-column--cell',
		},
		{
			field: 'amount',
			headerName: 'Amount',
			flex: 1,
			type: 'number',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'amount-column--cell',
		},
		{
			field: 'time',
			headerName: 'Time',
			flex: 1,
			type: 'dateTime',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'time-column--cell',
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { status } }) => {
				return (
					<Box
						width="60%"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<Typography sx={{ ml: '5px' }}>
							<span
								style={{
									color: status === 'Fail' ? 'red' : 'green',
								}}
							>
								{status}
							</span>
						</Typography>
					</Box>
				);
			},
		},
	];

	// const handleChangePlatform = (event) => {
	//     console.log(event.target.value);
	//     setPlatform(event.target.value);
	// };

	// const handleChangeGame = (event) => {
	//     console.log(event.target.value);
	//     setGame(event.target.value);
	// };

	// const handleChangeType = (event) => {
	//     console.log(event.target.value);
	//     setType(event.target.value);
	// };

	const handleSelectChange = (event) => {
		console.log(event.target.value);
		switch (event.target.name) {
			case Constant.NAME.SELECT_GAME:
				setGame(event.target.value);
				break;
			case Constant.NAME.SELECT_PLATFORM:
				setPlatform(event.target.value);
				break;
			case Constant.NAME.SELECT_PAYMENT:
				setType(event.target.value);
				break;
		}
	};

	const onDateChange = (date, dateString) => {
		console.log(dateString);
		setFrom(dateString[0]);
		setTo(dateString[1]);
	};

	const handleSearch = async () => {
		let response = await ApiRequest(
			Constant.GET,
			Constant.PURCHASE_URL,
			null,
			{
				transactionId: inputSearch?.transactionId,
				username: inputSearch?.userId,
				type: inputSearch?.type,
				from: from,
				to: to,
			}
		);
		if (!response?.data) return;
		const data = response?.data?.data?.purchase;
		console.log(data.list);
		if (data.list.length > Constant.ZERO) {
			setListPurrchase(data.list);
		} else {
			setListPurrchase([]);
		}
	};

	const onReset = () => {
		setPlatform(Constant.EMPTY);
		setFrom(Constant.EMPTY);
		setTo(Constant.EMPTY);
		setType(Constant.EMPTY);
		setInputSearch({});
	};

	const init = async () => {
		const response = await ApiRequest(Constant.GET, Constant.GAME_URL);
		if (!response?.data) return;
		const data = response?.data?.data.games.list;
		console.log(data);
		if (data.length > Constant.ZERO) {
			setListGame(data);
			setGame(data[0]?._id);
		}
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		init();
	}, [state]);

	return (
		<div className="home-transactionreport">
			<Box m="20px">
				<Grid container mb="10px">
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.GAME}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										id="demo-select-small"
										value={game}
										onChange={handleSelectChange}
										name={Constant.NAME.SELECT_GAME}
									>
										{listItemsGame(listGame)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.PLATFORM}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										name={Constant.NAME.SELECT_PLATFORM}
										value={platform}
										onChange={handleSelectChange}
										displayEmpty
										renderValue={
											platform !== Constant.EMPTY
												? undefined
												: () => (
														<Placeholder>
															{
																Constant
																	.PLACEHOLDER
																	.PLATFORM
															}
														</Placeholder>
												  )
										}
									>
										{listItems(platformData)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.PAYMENT}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										name={Constant.NAME.SELECT_PAYMENT}
										value={type}
										onChange={handleSelectChange}
										displayEmpty
										renderValue={
											type !== Constant.EMPTY
												? undefined
												: () => (
														<Placeholder>
															{
																Constant
																	.PLACEHOLDER
																	.PAYMENT
															}
														</Placeholder>
												  )
										}
									>
										{listItems(paymentData)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.PAYMENT_FORMAT}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										disabled
										// value={department}
										// onChange={handleChangeDepartment}
									>
										{/* {listItems(paymentData)} */}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.TRANSACTION_ID}</Box>
								<TextField
									placeholder={
										Constant.PLACEHOLDER.TRANSACTIONID
									}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												transactionId: e.target.value,
											};
										})
									}
									value={
										inputSearch?.transactionId ||
										Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container mb="10px">
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.USER_ID}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.USERID}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												userId: e.target.value,
											};
										})
									}
									value={
										inputSearch?.userId || Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.SERVER_ID}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.SERVERID}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												serverId: e.target.value,
											};
										})
									}
									value={
										inputSearch?.serverId || Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.ROLE_ID}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.ROLEID}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												roleId: e.target.value,
											};
										})
									}
									value={
										inputSearch?.roleId || Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.AMOUNT}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.AMOUNT}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												amount: e.target.value,
											};
										})
									}
									value={
										inputSearch?.amount || Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.GPA_CODE}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.GPA}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												gpa: e.target.value,
											};
										})
									}
									value={inputSearch?.gpa || Constant.EMPTY}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container mb="10px">
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.IP}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.IP}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												ip: e.target.value,
											};
										})
									}
									value={inputSearch?.ip || Constant.EMPTY}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<Box>{Constant.LABEL.PACKAGE_ID}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.PACKAGEID}
									onChange={(e) =>
										setInputSearch((previousState) => {
											return {
												...previousState,
												packageId: e.target.value,
											};
										})
									}
									value={
										inputSearch?.packageId || Constant.EMPTY
									}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Box>{Constant.LABEL.TIME}</Box>
								<RangePicker
									size="large"
									style={{ backgroundColor: '#F5F8FF' }}
									onChange={onDateChange}
									className="inputWidth"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12} md={6} lg={5}>
						<Grid container>
							<Grid item xs={4}>
								<Button
									className="btnRed"
									onClick={handleSearch}
								>
									{Constant.BUTTON.SEARCH}
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									className="btnGray"
									onClick={() => onReset()}
								>
									{Constant.BUTTON.RESET}
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									className="btnGreen"
									variant="secondary"
									disabled={
										listPurrchase.length > 0 ? false : true
									}
								>
									{Constant.BUTTON.EXPORT}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Box
						m="10px 0 0 0"
						height="58vh"
						sx={{
							'& .MuiDataGrid-root': {
								border: 'none',
							},
							'& .MuiDataGrid-cell': {
								borderBottom: 'none',
							},
							'& .MuiDataGrid-columnHeaders': {
								// color: colors.greenAccent[300],
								//borderBottom: "none",
							},
							'& .MuiDataGrid-row': {
								borderBottom:
									'1px solid rgba(224, 224, 224, 1)',
							},
							'& .MuiTablePagination-toolbar': {
								display: 'flex',
								alignItems: 'baseline',
							},
						}}
					>
						<DataGrid
							rows={mockTransactionData}
							columns={columns}
							sx={{ overflowX: 'scroll' }}
							autoPageSize
						/>
					</Box>
				</Grid>
			</Box>
		</div>
	);
};

export default TransactionReport;
