const Message = {
    MS0001: "Xác nhận kết thúc tài khoản đăng nhập đã chọn?",
    MS0002: "Xác nhận gửi thư?",
    MS0003: "Bạn muốn xóa Tickets không?",
    MS0004: "Bạn muốn chuyển tiếp ticket sang...",
    MS0005: "Chỉnh sửa thể loại",
    MS0006: "Chỉnh sửa thể loại",
    MS0007: "Bạn có muốn khóa tài khoản không?",
    MS0008: "Thêm mới lý do",
    MS0009: "Xác nhận gửi thông báo?",
    MS0010: "Số lượng GGold muốn tăng?",
    MS0011: "Số lượng GGold muốn giảm?",
    MS0012: "Thêm mới thể loại",

    ER0001: "Chưa nhập tên",
    ER0002: "Chưa nhập email",
    ER0003: "Chưa chọn bộ phận",
    ER0004: "Chưa chọn vị trí",
    ER0005: "Chưa nhập mật khẩu mới",
    ER0006: "Chưa nhập xác nhận mật khẩu mới",
    ER0007: "Mật khẩu không khớp",
    ER0008: "Chưa nhập tên mẫu",
    ER0009: "Chưa nhập nội dung",
    ER0010: "Chưa nhập tên thể loại",
    ER0011: "Chưa nhập thông tin tìm kiếm",
    ER0012: "Chưa nhập GGold cần cập nhật",
    ER0013: "Vui lòng liên hệ với quản tri viên",
    ER0014: "Không có phản hồi",
    ER0015: "Thông tin tìm kiếm sai",
}

export default Message;