export const replaceClassEditor = (string) => {
	return string
		.replace(`class="ql-align-right"`, `class="text-right"`)
		.replace(`class="ql-align-center"`, `class="text-center"`)
		.replace(`class="ql-align-justify"`, `class="text-justify"`);
};

export const renameKey = (obj, oldKey, newKey) => {
	obj[newKey] = obj[oldKey];
	delete obj[oldKey];
};

export const parserStringHTMLToArray = (htmlText) => {
	const parser = new DOMParser();
	const parsedHtml = parser.parseFromString(htmlText, 'text/html');
	let pTags = parsedHtml.getElementsByTagName('p');
	let arrayTags = [];
	for (let p of pTags) {
		arrayTags.push(replaceClassEditor(p.outerHTML));
		// console.log("outerHTML ", replaceClassEditor(p.outerHTML));
	}
	// console.log("array string parser outerHTML ", arrayTags);
	return arrayTags;
};
