import { createContext, useReducer } from 'react';
import SiteReducer from './SiteReducer';

// const INITIAL_STATE = {
//   login: {},
//   darkMode: false,
//   barMode: true,
// };

export const SiteContext = createContext({
	login: {},
	navbar: {},
	colorMode: 'light',
	barMode: true,
	toast: {
		isShow: true,
		message: '',
		status: false,
	},
});

export const SiteContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(SiteReducer, {
		login: {},
		navbar: {},
		colorMode: 'light',
		barMode: true,
		toast: {
			isShow: true,
			message: '',
			status: false,
		},
	});

	return (
		<SiteContext.Provider value={{ state, dispatch }}>
			{children}
		</SiteContext.Provider>
	);
};
