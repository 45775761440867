// line chart data
export const REVData = [
    {
        tab: "REV",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tất cả",
                    data: [56, 51, 73, 99, 84, 98, 93]
                },
                {
                    name: "Apple",
                    data: [18, 35, 37, 60, 44, 45, 63]
                },
                {
                    name: "Google",
                    data: [38, 16, 36, 39, 40, 43, 30]
                }
            ]
        }
    },
    {
        tab: "PU",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tất cả",
                    data: [58, 50, 63, 79, 75, 64, 63]
                },
                {
                    name: "Apple",
                    data: [20, 35, 37, 40, 35, 35, 33]
                },
                {
                    name: "Google",
                    data: [38, 15, 26, 39, 40, 29, 30]
                }
            ]
        }
    },
    {
        tab: "ARPPU",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tất cả",
                    data: [53, 57, 70, 96, 74, 94, 83]
                },
                {
                    name: "Apple",
                    data: [18, 35, 37, 57, 34, 43, 53]
                },
                {
                    name: "Google",
                    data: [35, 18, 33, 39, 40, 41, 30]
                }
            ]
        }
    },
];

// line chart data
export const DAUData = [
    {
        tab: "DAU",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30]
                }
            ]
        }
    },
    {
        tab: "New",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Android",
                    data: [20, 35, 37, 40, 35, 35, 33]
                },
                {
                    name: "IOS",
                    data: [38, 15, 26, 39, 40, 29, 30]
                }
            ]
        }
    },
    {
        tab: "Old",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 57, 34, 43, 53]
                },
                {
                    name: "IOS",
                    data: [35, 18, 33, 39, 40, 41, 30]
                }
            ]
        }
    },
];

// column chart data
export const MKTData = [
    {
        tab: "Ads",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tiktok",
                    data: [56, 51, 73, 99, 84, 98, 93]
                },
                {
                    name: "Facebook",
                    data: [18, 35, 37, 60, 44, 45, 63]
                },
                {
                    name: "Google",
                    data: [38, 16, 36, 39, 40, 43, 30]
                }
            ]
        }
    },
    {
        tab: "Branding",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tiktok",
                    data: [58, 50, 63, 79, 75, 64, 63]
                },
                {
                    name: "Facebook",
                    data: [20, 35, 37, 40, 35, 35, 33]
                },
                {
                    name: "Google",
                    data: [38, 15, 26, 39, 40, 29, 30]
                }
            ]
        }
    },
    {
        tab: "Other",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "Tiktok",
                    data: [53, 57, 70, 96, 74, 94, 83]
                },
                {
                    name: "Facebook",
                    data: [18, 35, 37, 57, 34, 43, 53]
                },
                {
                    name: "Google",
                    data: [35, 18, 33, 39, 40, 41, 30]
                }
            ]
        }
    },
];

// pie chart data
export const REVDetailData = {
    series: [15, 15, 15, 15, 15, 15, 15],
    labels: ['Google', 'Apple', 'Nạp trực tiếp', 'Gate', 'Vcoin', 'ATM - Visa', 'Khác']
};

// column chart data
export const KeepUserData = [
    {
        tab: "new",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "new",
                    data: [56, 51, 73, 99, 84, 98, 93]
                },
            ]
        }
    },
    {
        tab: "old",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "old",
                    data: [20, 35, 37, 40, 35, 35, 33]
                },
            ]
        }
    },
    {
        tab: "purchase",
        data: {
            categories: ['19/03', '20/03', '21/03', '22/03', '23/03', '24/03', '25/03'],
            series: [
                {
                    name: "purchase",
                    data: [35, 18, 33, 39, 40, 41, 30]
                }
            ]
        }
    },
];

// column chart data
export const KPIData = [
    {
        tab: "REVMonth",
        data: {
            categories: ['12/2022', '01/2023', '02/2023', '03/2023', '04/2023', '05/2013', '06/2023'],
            series: [
                {
                    name: "Kết quả",
                    data: [56, 51, 73, 99, 84, 98, 93]
                },
                {
                    name: "Mục tiêu",
                    data: [18, 35, 37, 60, 44, 45, 63]
                },
            ]
        }
    },
    {
        tab: "BugetMKT",
        data: {
            categories: ['12/2022', '01/2023', '02/2023', '03/2023', '04/2023', '05/2013', '06/2023'],
            series: [
                {
                    name: "Kết quả",
                    data: [58, 50, 63, 79, 75, 64, 63]
                },
                {
                    name: "Mục tiêu",
                    data: [38, 15, 26, 39, 40, 29, 30]
                }
            ]
        }
    },
    {
        tab: "NRU",
        data: {
            categories: ['12/2022', '01/2023', '02/2023', '03/2023', '04/2023', '05/2013', '06/2023'],
            series: [
                {
                    name: "Kết quả",
                    data: [18, 35, 37, 57, 34, 43, 53]
                },
                {
                    name: "Mục tiêu",
                    data: [35, 18, 33, 39, 40, 41, 30]
                }
            ]
        }
    },
];

// line chart data
export const ALLData = [
    {
        tab: "ALL",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "REV",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "DAU",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "NRU",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "PU",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "ARPU",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "ARPPU",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
    {
        tab: "RETENTION",
        data: {
            categories: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            series: [
                {
                    name: "Android",
                    data: [18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37, 60, 44, 45, 63, 18, 35, 37]
                },
                {
                    name: "IOS",
                    data: [38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36, 39, 40, 43, 30, 38, 16, 36]
                }
            ]
        }
    },
];