import './manageticket.scss';
import { Box, Tabs, Tab } from '@mui/material';
import Button from 'react-bootstrap/Button';
import { useState, useContext, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { mockTicketData } from '../../data/mockDataUser';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/modal/CustomModal';
import Message from '../../common/message/message';
import { SiteContext } from '../../context/SiteContext';
import Constant from '../../common/constant/constant';
import ApiRequest from '../../api/axios';
import dayjs from 'dayjs';
import htmlParse from 'html-react-parser';
import { replaceClassEditor } from '../../common/util';
import { enqueueSnackbar } from 'notistack';

const ManageTicket = () => {
	const { state } = useContext(SiteContext);
	const [value, setValue] = useState(0);
	const navigate = useNavigate();
	const [modalShow, setModalShow] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [rows, setRows] = useState([]);
	const gameId = state?.navbar?.id || window.localStorage.getItem('GAME');

	const columns = [
		{
			field: 'isFavorite',
			headerName: 'Ưu tiên',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			onclick: () => {
				console.log('aaaa');
			},
			cellClassName: 'isFavorite-column--cell',
			renderCell: ({ row: { isFavorite } }) => {
				return (
					<Box
						width="50px"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						{isFavorite === true ? (
							<StarIcon sx={{ color: '#ffe500' }} />
						) : (
							<StarBorderIcon />
						)}
					</Box>
				);
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { status } }) => {
				return (
					<Box
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<span
							style={{
								color:
									status === Constant.STATUS_PROCESSING
										? Constant.FONTCOLOR.GREEN
										: status === Constant.STATUS_NO_PROCESS
										? Constant.FONTCOLOR.RED
										: Constant.FONTCOLOR.GREY,
							}}
						>
							{status === Constant.STATUS_PROCESSING
								? Constant.LABEL.PROCESSING
								: status === Constant.STATUS_NO_PROCESS
								? Constant.LABEL.NO_PROCESS
								: Constant.LABEL.PROCESSED}
						</span>
					</Box>
				);
			},
		},
		{
			field: 'title',
			flex: 2,
			headerName: 'Tiêu đề',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'content-column--cell',
		},
		{
			field: 'lastMessage',
			flex: 5,
			headerName: 'Nội dung',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'lastMessage-column--cell',
			renderCell: ({ row: { lastMessage, isRead } }) => {
				const parser = new DOMParser();
				const parsedHtml = parser.parseFromString(
					lastMessage,
					'text/html'
				);
				let pTags = parsedHtml.getElementsByTagName('p');

				return (
					<Box fontWeight={isRead ? '400' : 'bold'}>
						{replaceClassEditor(pTags[0].innerHTML)}
					</Box>
				);
			},
		},
		{
			field: 'updatedAt',
			headerName: 'Ngày gửi',
			flex: 2,
			type: 'date',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'updatedAt-column--cell',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value)).format(Constant.FORMAT.FULLDATE),
		},
	];

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const changeRowFilter = () => {
		const ar = [];
		switch (value) {
			case 1:
				for (const e of rows) {
					if (e.isFavorite) ar.push(e);
				}
				return ar;

			case 2:
				for (const e of rows) {
					if (e.status === 0) ar.push(e);
				}
				return ar;
			case 3:
				for (const e of rows) {
					if (e.status === 1) ar.push(e);
				}
				return ar;
			case 4:
				for (const e of rows) {
					if (e.status === 2) ar.push(e);
				}
				return ar;
			default:
				return ar;
		}
	};

	const handleFavorite = async (params) => {
		if (params.field === 'isFavorite') {
			const i = params.id - 1;
			rows[i].isFavorite = !rows[i].isFavorite;
			setRows([...rows]);
			const response = await ApiRequest(
				Constant.PUT,
				`${Constant.TICKETS_URL}/favorite/${rows[i]?._id}`,
				{ isFavorite: rows[i].isFavorite }
			);
			if (response?.status === 200) {
				enqueueSnackbar(Constant.MESSAGE_SUCCESS, {
					variant: 'success',
				});
			} else {
				rows[i].isFavorite = !rows[i].isFavorite;
				setRows([...rows]);
			}
		} else {
			navigate(Constant.PATH_SLASH + Constant.PATH_TICKET, {
				state: {
					dataTicket: params.row,
				},
			});
		}
	};

	const handleRowClick = (params, event) => {
		// console.log('params', params, event);
		// navigate(Constant.PATH_SLASH + Constant.PATH_TICKET, {
		// 	state: {
		// 		dataTicket: params.row,
		// 	},
		// });
	};

	const handleRowCheckClick = (ids, a) => {
		// console.log('ids', ids, a);
		// const selectedIDs = new Set(ids);
		// const selectedRowData = mockTicketData.filter((row) =>
		// 	selectedIDs.has(row._id)
		// );
		// // console.log(selectedRowData);
		// setSelectedRows(selectedRowData);
	};

	const handleCloseModal = () => {
		let arrIdRemove = [];
		selectedRows.forEach((item) => arrIdRemove.push(item.id));
		// console.log(arrIdRemove);
		handleRemoveItem(arrIdRemove);
		setModalShow(false);
	};

	const handleRemoveItem = async (arrIdRemove) => {
		const body = {
			ids: arrIdRemove,
		};
		const response = await ApiRequest(
			Constant.PUT,
			Constant.TICKETS_URL,
			body
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
		if (data?.tickets?.list > 0) {
			setRows(data?.tickets?.list);
		}
	};

	const init = async () => {
		setRows([]);
		const response = await ApiRequest(
			Constant.GET,
			Constant.TICKETS_URL + `?gameId=${gameId}`
		);
		if (!response?.data) return;
		const data = response?.data?.data?.tickets;
		if (data?.length > 0) {
			for (let i = 0; i < data.length; i++) {
				data[i].id = i + 1;
			}
			// console.log("data ", data);
			setRows(data);
		}
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		init();
	}, [state]);

	return (
		<div className="home-manageticket">
			<Box m="20px">
				<Box
					mt="10px"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="row"
				>
					<RefreshIcon onClick={() => init()} />
					<Button
						className="btnRed"
						disabled={selectedRows.length > 0 ? false : true}
						variant="secondary"
						onClick={() => setModalShow(true)}
					>
						Xóa
					</Button>
				</Box>
				<Box
					mt="20px"
					display="flex"
					alignItems="center"
					flexDirection="row"
				>
					{/* <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column" flex="1"> */}
					<Box
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
							width: '100%',
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
							variant="fullWidth"
							textColor="inherit"
							TabIndicatorProps={{
								style: {
									backgroundColor: 'black',
								},
							}}
						>
							<Tab label={Constant.LABEL.TOTAL_TICKETS} />
							<Tab label={Constant.LABEL.TICKETS_FAVORITE} />
							<Tab label={Constant.LABEL.TICKETS_NO_PROCESS} />
							<Tab label={Constant.LABEL.TICKETS_PROCESSING} />
							<Tab label={Constant.LABEL.TICKETS_PROCESSED} />
							{/* <Tab label={Constant.LABEL.TICKETS_REMOVE} /> */}
						</Tabs>
					</Box>
					{/* </Box> */}
				</Box>
				<Box
					m="10px 0 0 0"
					height="78vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
						'& .MuiDataGrid-footerContainer': {
							justifyContent: 'center',
						},
					}}
				>
					<DataGrid
						// checkboxSelection
						// rows={mockTicketData}
						rows={value === 0 ? rows : changeRowFilter()}
						columns={columns}
						autoPageSize
						onRowClick={handleRowClick}
						onCellClick={handleFavorite}
						onCellModesModelChange={handleRowCheckClick}
					/>
				</Box>
			</Box>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				message={Message.MS0003}
				btnCancelShow="true"
				btnAgreeShow="true"
				onCloseModal={handleCloseModal}
			/>
		</div>
	);
};

export default ManageTicket;
