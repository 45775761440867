import './accountdetail.scss';
import {
	TextField,
	Box,
	InputAdornment,
	Input,
	ListItemText,
} from '@mui/material';
import { useState, useEffect } from 'react';
import ApiRequest from '../../api/axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from 'react-bootstrap/Button';
import * as listMenu from '../../data/menu';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import md5 from 'md5';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';
import { makeStyles } from '@material-ui/core/styles';
import { enqueueSnackbar } from 'notistack';

const usePlaceholderStyles = makeStyles((theme) => ({
	placeholder: {
		color: '#aaa',
	},
}));

const Placeholder = ({ children }) => {
	const classes = usePlaceholderStyles();
	return <div className={classes.placeholder}>{children}</div>;
};

const AccountDetail = () => {
	const location = useLocation();
	const { from } = location.state;
	const navigate = useNavigate();
	const [name, setName] = useState(Constant.EMPTY);
	const [email, setEmail] = useState(Constant.EMPTY);
	const [password, setPassword] = useState(Constant.EMPTY);
	const [role, setRole] = useState(Constant.EMPTY);
	const [department, setDepartment] = useState(Constant.EMPTY);
	const [showPassword, setShowPassword] = useState(false);
	const [listGame, setListGame] = useState([]);
	const [gameSelectActives, setGameSelectActives] = useState([]);

	const [roles, setRoles] = useState(Constant.EMPTY);
	const [departments, setDepartments] = useState(Constant.EMPTY);

	const [selectedValue, setSelectedValue] = useState(Constant.VALUE.ALL);
	const [reportCheckedState, setReportCheckedState] = useState(
		new Array(listMenu.report.length).fill(false)
	);
	const [categoryCheckedState, setCategoryCheckedState] = useState(
		new Array(listMenu.category.length).fill(false)
	);
	const [projectCheckedState, setProjectCheckedState] = useState(
		new Array(listMenu.project.length).fill(false)
	);
	const [toolCheckedState, setToolCheckedState] = useState(
		new Array(listMenu.tool.length).fill(false)
	);

	const [reportMenu, setReportMenu] = useState([]);
	const [categoryMenu, setCategoryMenu] = useState([]);
	const [projectMenu, setProjectMenu] = useState([]);
	const [toolMenu, setToolMenu] = useState([]);
	const dataLocal = JSON.parse(window.localStorage.getItem('LOGIN'));
	// const accessToken = state.login.tokens.accessToken;

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item.code} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	let listItemsSelect = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	let listItemSelect = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item, i) => {
				return (
					<MenuItem key={i} value={item.id}>
						<Checkbox
							checked={gameSelectActives.indexOf(item.id) > -1}
						/>
						<ListItemText primary={item.title} />
					</MenuItem>
				);
			});
		}
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
		switch (event.target.value) {
			case Constant.VALUE.REPORT:
				setReportCheckedState(reportCheckedState.map(() => true));
				setCategoryCheckedState(categoryCheckedState.map(() => false));
				setProjectCheckedState(projectCheckedState.map(() => false));
				setToolCheckedState(toolCheckedState.map(() => false));
				break;
			case Constant.VALUE.CATEGORY:
				setCategoryCheckedState(categoryCheckedState.map(() => true));
				setReportCheckedState(reportCheckedState.map(() => false));
				setProjectCheckedState(projectCheckedState.map(() => false));
				setToolCheckedState(toolCheckedState.map(() => false));
				break;
			case Constant.VALUE.PROJECT:
				setToolCheckedState(toolCheckedState.map(() => false));
				setReportCheckedState(reportCheckedState.map(() => false));
				setCategoryCheckedState(categoryCheckedState.map(() => false));
				setProjectCheckedState(projectCheckedState.map(() => true));
				break;
			case Constant.VALUE.TOOL:
				setToolCheckedState(toolCheckedState.map(() => true));
				setReportCheckedState(reportCheckedState.map(() => false));
				setCategoryCheckedState(categoryCheckedState.map(() => false));
				setProjectCheckedState(projectCheckedState.map(() => false));
				break;
			default:
				setReportCheckedState(reportCheckedState.map(() => true));
				setCategoryCheckedState(categoryCheckedState.map(() => true));
				setToolCheckedState(toolCheckedState.map(() => true));
				setProjectCheckedState(projectCheckedState.map(() => true));
				break;
		}
	};

	const handleChangeReport = (position) => {
		const updatedCheckedState = reportCheckedState.map((item, index) =>
			index === position ? !item : item
		);
		setReportCheckedState(updatedCheckedState);
	};

	const handleChangeCategory = (position) => {
		const updatedCheckedState = categoryCheckedState.map((item, index) =>
			index === position ? !item : item
		);
		setCategoryCheckedState(updatedCheckedState);
	};

	const handleChangeProject = (position) => {
		const updatedCheckedState = projectCheckedState.map((item, index) =>
			index === position ? !item : item
		);
		setProjectCheckedState(updatedCheckedState);
	};

	const handleChangeTool = (position) => {
		const updatedCheckedState = toolCheckedState.map((item, index) =>
			index === position ? !item : item
		);
		setToolCheckedState(updatedCheckedState);
	};

	const handleChangeDepartment = (event) => {
		setDepartment(event.target.value);
	};

	const handleChangeRole = (event) => {
		setRole(event.target.value);
	};

	const setData = async () => {
		const arrMenu = [];
		for (let i = 0; i < reportCheckedState.length; i++) {
			if (reportCheckedState[i] === true)
				arrMenu.push(listMenu.report[i].value);
		}
		for (let i = 0; i < categoryCheckedState.length; i++) {
			if (categoryCheckedState[i] === true)
				arrMenu.push(listMenu.category[i].value);
		}
		for (let i = 0; i < projectCheckedState.length; i++) {
			if (projectCheckedState[i] === true)
				arrMenu.push(listMenu.project[i].value);
		}
		for (let i = 0; i < toolCheckedState.length; i++) {
			if (toolCheckedState[i] === true)
				arrMenu.push(listMenu.tool[i].value);
		}
		if (!name) alert(Message.ER0001);
		else if (!email) alert(Message.ER0002);
		else if (!department) alert(Message.ER0003);
		else if (!role) alert(Message.ER0004);
		else {
			let response;
			const body = {
				name: name,
				email: email,
				department: department,
				role: role,
				menus: arrMenu,
				project: gameSelectActives,
			};
			console.log(body);
			if (!from) {
				body.password = md5(password);
				response = await ApiRequest(
					Constant.POST,
					Constant.ACCOUNTS_URL,
					body
				);
			} else {
				password !== '' && (body.password = md5(password));
				response = await ApiRequest(
					Constant.PUT,
					Constant.ACCOUNTS_URL,
					body
				);
			}
			if (!response?.data) return;
			enqueueSnackbar(Constant.MESSAGE_SUCCESS, { variant: 'success' });
			const data = response?.data?.data;
			navigate(Constant.PATH_SLASH + Constant.PATH_ACCOUNTS);
		}
	};

	const init = async () => {
		const body = {
			email: from,
		};
		const [response, responseGame] = await Promise.all([
			ApiRequest(Constant.POST, Constant.ACCOUNTSDETAIL_URL, body),
			ApiRequest(Constant.GET, Constant.GAME_URL),
		]);
		const dataGame = responseGame?.data?.data.games.list;
		if (dataGame.length > Constant.ZERO) {
			setListGame(dataGame);
		}
		const data = response?.data?.data;
		if (!response?.data) return;
		let report = [];
		let category = [];
		let project = [];
		let tool = [];
		let arrMenu = data?.user?.menus;

		if (data.allMenus.length > 0) {
			for (let i = 0; i < data.allMenus.length; i++) {
				if (data.allMenus[i].menuType === Constant.VALUE.REPORT) {
					report.push(data.allMenus[i]);
				}
				if (data.allMenus[i].menuType === Constant.VALUE.CATEGORY) {
					category.push(data.allMenus[i]);
				}
				if (data.allMenus[i].menuType === Constant.VALUE.TYPE_PROJECT) {
					project.push(data.allMenus[i]);
				}
				if (data.allMenus[i].menuType === Constant.VALUE.TOOL) {
					tool.push(data.allMenus[i]);
				}
			}

			console.log('project', project, listMenu);

			setReportMenu([...report]);
			setCategoryMenu([...category]);
			setProjectMenu([...project]);
			setToolMenu([...tool]);
		}
		if (from) {
			setName(data?.user?.name);
			setEmail(data?.user?.email);
			setDepartment(data?.user?.departments[0].code);
			setRole(data?.user?.roles[0].code);
			if (data?.user?.project) {
				setGameSelectActives(data?.user?.project);
			} else {
				for (const game of dataGame) {
					gameSelectActives.push(game.id);
				}
				setGameSelectActives([...gameSelectActives]);
			}

			let arrReport = [];
			let arrCategory = [];
			let arrProject = [];
			let arrTool = [];

			for (let i = 0; i < report.length; i++) {
				const index = arrMenu.findIndex((e) => e === report[i]?.code);
				arrReport.push(index !== -1);
			}
			setReportCheckedState(arrReport);

			for (let i = 0; i < category.length; i++) {
				const index = arrMenu.findIndex((e) => e === category[i]?.code);
				arrCategory.push(index !== -1);
			}
			setCategoryCheckedState(arrCategory);

			for (let i = 0; i < project.length; i++) {
				const index = arrMenu.findIndex((e) => e === project[i]?.code);
				arrProject.push(index !== -1);
			}
			setProjectCheckedState(arrProject);

			for (let i = 0; i < tool.length; i++) {
				const index = arrMenu.findIndex((e) => e === tool[i]?.code);
				arrTool.push(index !== -1);
			}
			setToolCheckedState(arrTool);
		} else {
			setReportCheckedState(reportCheckedState.map(() => true));
			setCategoryCheckedState(categoryCheckedState.map(() => true));
			setProjectCheckedState(projectCheckedState.map(() => true));
			setToolCheckedState(toolCheckedState.map(() => true));
		}

		if (data.roles.length > 0) setRoles(data.roles);
		if (data.departments.length > 0) setDepartments(data.departments);
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="home-accountdetail">
			<Box m="20px">
				<Box>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="center"
						alignSelf="flex-start"
						mb="20px"
					>
						<Link
							to={Constant.PATH_SLASH + Constant.PATH_ACCOUNTS}
							style={{ textDecoration: 'none', color: 'black' }}
						>
							<KeyboardBackspaceIcon />
						</Link>{' '}
						<Box
							display="flex"
							justifyContent="start"
							alignItems="center"
							alignSelf="flex-start"
						>
							<span style={{ fontWeight: 'bold' }}>
								{Constant.LABEL.ACCOUNT_INFO}
							</span>
						</Box>
					</Box>

					<Box width="50%" mb="20px">
						<Box my="10px" display="flex" flexDirection="row">
							<span className="accountItem borderUserItem">
								{Constant.LABEL.ACCOUNT_NAME}
							</span>
							<TextField
								placeholder={Constant.PLACEHOLDER.NAME}
								onChange={(e) => setName(e.target.value)}
								value={name}
								variant="standard"
								size="small"
								className="accountItemInput inputChangeBorderBottom"
							/>
						</Box>
						<Box my="10px" display="flex" flexDirection="row">
							<span
								className="accountItem borderUserItem"
								width="15%"
							>
								{Constant.LABEL.EMAIL}
							</span>
							<TextField
								placeholder={Constant.PLACEHOLDER.EMAIL}
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								variant="standard"
								size="small"
								disabled={from ? true : false}
								className="accountItemInput inputChangeBorderBottom"
							/>
						</Box>
						<Box my="10px" display="flex" flexDirection="row">
							<span className="accountItem borderUserItem">
								{Constant.LABEL.PROFILE_PASSWORD}
							</span>
							<FormControl
								className="accountItemInput inputChangeBorderBottom"
								variant="standard"
								size="small"
							>
								<Input
									id="standard-adornment-password"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={
													handleClickShowPassword
												}
												onMouseDown={
													handleMouseDownPassword
												}
											>
												{showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
									onChange={(e) =>
										setPassword(e.target.value)
									}
									value={password}
								/>
							</FormControl>
						</Box>
						<Box my="10px" display="flex" flexDirection="row">
							<span className="accountItem borderUserItem">
								{Constant.LABEL.DEPARTMENT}
							</span>
							<FormControl
								variant="standard"
								className="accountItemInput inputChangeBorderBottom"
								size="small"
							>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={department}
									onChange={handleChangeDepartment}
									displayEmpty
									renderValue={
										department
											? undefined
											: () => (
													<Placeholder>
														{
															Constant.PLACEHOLDER
																.DEPARTMENT
														}
													</Placeholder>
											  )
									}
								>
									{listItems(departments)}
								</Select>
							</FormControl>
						</Box>
						<Box my="10px" display="flex" flexDirection="row">
							<span
								className="accountItem borderUserItem"
								width="15%"
							>
								{Constant.LABEL.ROLE}
							</span>
							<FormControl
								variant="standard"
								className="accountItemInput inputChangeBorderBottom"
								size="small"
							>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={role}
									onChange={handleChangeRole}
									displayEmpty
									renderValue={
										role
											? undefined
											: () => (
													<Placeholder>
														{
															Constant.PLACEHOLDER
																.ROLE
														}
													</Placeholder>
											  )
									}
								>
									{listItems(roles)}
								</Select>
							</FormControl>
						</Box>
						<Box my="10px" display="flex" flexDirection="row">
							<span
								className="accountItem borderUserItem"
								width="15%"
							>
								{Constant.LABEL.PROJECT}
							</span>
							<FormControl
								variant="standard"
								className="accountItemInput inputChangeBorderBottom"
								size="small"
							>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={gameSelectActives}
									multiple
									onChange={(e) =>
										setGameSelectActives(e?.target?.value)
									}
									renderValue={(selected) => {
										let s = '';
										if (selected.length <= 0) return s;
										for (const v of selected) {
											const index = listGame.find(
												(e) => e.id === v
											);
											if (index)
												s +=
													s?.length <= 0
														? `${index.title}`
														: `, ${index.title}`;
										}
										return s;
									}}
								>
									{listItemSelect(listGame)}
								</Select>
							</FormControl>
						</Box>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
				>
					<Box
						display="flex"
						alignItems="center"
						alignSelf="flex-start"
					>
						<span style={{ fontWeight: 'bold' }}>
							{Constant.LABEL.ACCOUNT_MENU}
						</span>
						<FormControl
							sx={{ minWidth: 190, marginLeft: '10px' }}
							size="small"
						>
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={selectedValue}
								onChange={handleChange}
							>
								{listItemsSelect(listMenu.menu)}
							</Select>
						</FormControl>
					</Box>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						flexDirection="row"
						width="100%"
					>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							flexDirection="column"
							width="100%"
						>
							<span className="spanMenu">
								{Constant.LABEL.MENU_REPORT}
							</span>
							<FormGroup className="spanMenu">
								{listMenu.report.map(
									({ name, value }, index) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														onChange={() =>
															handleChangeReport(
																index
															)
														}
														name={value}
														checked={
															reportCheckedState[
																index
															]
														}
													/>
												}
												label={name}
												key={name}
											/>
										);
									}
								)}
							</FormGroup>
							<span className="spanMenu">
								{Constant.LABEL.MENU_PJMANAGER}
							</span>
							<FormGroup className="spanMenu">
								{listMenu.project.map(
									({ name, value }, index) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														onChange={() =>
															handleChangeProject(
																index
															)
														}
														name={value}
														checked={
															projectCheckedState[
																index
															]
														}
													/>
												}
												label={name}
												key={name}
											/>
										);
									}
								)}
							</FormGroup>
						</Box>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							flexDirection="column"
							width="100%"
						>
							<span className="spanMenu">
								{Constant.LABEL.MENU_CATEGORY}
							</span>
							<FormGroup className="spanMenu">
								{listMenu.category.map(
									({ name, value }, index) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														onChange={() =>
															handleChangeCategory(
																index
															)
														}
														name={value}
														checked={
															categoryCheckedState[
																index
															]
														}
													/>
												}
												label={name}
												key={name}
											/>
										);
									}
								)}
							</FormGroup>
							<span className="spanMenu">
								{Constant.LABEL.MENU_TOOL}
							</span>
							<FormGroup className="spanMenu">
								{listMenu.tool.map(({ name, value }, index) => {
									return (
										<FormControlLabel
											control={
												<Checkbox
													onChange={() =>
														handleChangeTool(index)
													}
													name={value}
													checked={
														toolCheckedState[index]
													}
												/>
											}
											label={name}
											key={name}
										/>
									);
								})}
							</FormGroup>
						</Box>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
				>
					<Button className="btnAccept" onClick={setData}>
						{Constant.BUTTON.CONFIRM}
					</Button>
				</Box>
			</Box>
		</div>
	);
};

export default AccountDetail;
