import axios from 'axios';
import Constant from '../common/constant/constant';
import { enqueueSnackbar } from 'notistack';

const ApiRequest = async (
	method = Constant.GET,
	endpoint = '',
	body,
	params,
	token,
	type,
	callbackErr
) => {
	let obj = {
		headers: {
			'x-api-key': 'GCMUDiuY5a7WvyUNt9n3QztToSHzK7Uj',
			'Content-Type': 'application/json',
		},
		method,
		url: `${process.env.REACT_APP_BACKEND_HOST}/${endpoint}`,
	};
	console.log("🚀 ~ obj--------:", obj)
	token && (obj.headers['Authorization'] = 'Bearer ' + token);
	body && (obj.data = type ? body : JSON.stringify(body));
	params && (obj.params = params);
	try {
		return await axios(obj);
	} catch (err) {
		callbackErr && callbackErr();
		throw enqueueSnackbar(err.response?.data.message, { variant: 'error' });
	}
};

export const ApiRequestUpload = async (data) => {
	let obj = {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
		},
		method: 'POST',
		data: data,
		url: `https://upload.goodgames.vn/upload`,
		// url: `http://127.0.0.1:3116/upload`,
	};
	console.log('data', data);
	try {
		return await axios(obj);
	} catch (err) {
		throw enqueueSnackbar(err.response?.data.message, { variant: 'error' });
	}
};

export default ApiRequest;
