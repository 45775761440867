import './mailing.scss';
import {
	TextField,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Divider,
	Typography,
} from '@mui/material';
import Button from 'react-bootstrap/Button';
import 'react-quill/dist/quill.snow.css';
import { platformData } from '../../data/mockDataUser';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, useContext } from 'react';
import Editor from '../../components/editor/Editor';
import Modal from '../../components/modal/CustomModal';
import Review from '../../components/dialog/Review';
import ApiRequest from '../../api/axios';
import Message from '../../common/message/message';
import Constant from '../../common/constant/constant';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { SiteContext } from '../../context/SiteContext';
import {
	parserStringHTMLToArray,
	renameKey,
	replaceClassEditor,
} from '../../common/util';
import { enqueueSnackbar } from 'notistack';

const usePlaceholderStyles = makeStyles((theme) => ({
	placeholder: {
		color: '#aaa',
	},
}));

const Placeholder = ({ children }) => {
	const classes = usePlaceholderStyles();
	return <div className={classes.placeholder}>{children}</div>;
};

const Mailing = () => {
	const { state } = useContext(SiteContext);
	const [convertedText, setConvertedText] = useState(Constant.EMPTY);
	const [title, setTitle] = useState(Constant.EMPTY);
	const [idMail, setIdMail] = useState(Constant.EMPTY);
	const [idMails, setIdMails] = useState([]);
	const [rows, setRows] = useState();
	const [listMail, setListMail] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [reviewShow, setReviewShow] = useState(false);
	const [platform, setPlatform] = useState(Constant.EMPTY);
	const gameId = state?.navbar?.id;

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'title',
			headerName: 'Tiêu đề',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'title-column--cell',
		},
		{
			field: 'deviceType',
			headerName: 'Platform',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'platform-column--cell',
			valueGetter: (params) =>
				`${
					params?.row?.deviceType !== Constant.VALUE.ANDROID &&
					params?.row?.deviceType !== Constant.VALUE.IOS
						? Constant.LABEL.ALL_DEVICETYPE
						: params?.row?.deviceType || Constant.EMPTY
				}`,
		},
		{
			field: 'createdAt',
			headerName: 'Thời gian tạo',
			flex: 1,
			type: 'date',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value)).format('DD/MM/YYYY HH:mm'),
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'createAt-column--cell',
		},
		{
			field: 'userName',
			headerName: 'Người tạo',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'userName-column--cell',
		},
	];

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	const setClearControl = () => {
		setIdMail(Constant.EMPTY);
		setTitle(Constant.EMPTY);
		setConvertedText(Constant.EMPTY);
		setPlatform(Constant.EMPTY);
	};

	const handleRowClick = (params) => {
		console.log(params);
		setRows(params.row);
		setReviewShow(true);
	};

	const handleCloseModal = () => {
		handleSendMail();
		setModalShow(false);
	};

	const handleCloseDialog = () => {
		setReviewShow(false);
	};

	const handleChangePlatform = (event) => {
		setPlatform(event.target.value);
	};

    const handleSendMail = async () => {
        if (title?.length < 6 || title?.length > 255) {
		    enqueueSnackbar('Tiêu đề tối thiểu 6 và tối đa 255 ký tự', { variant: 'error' });
            return;
        }
        if (convertedText?.length < 6) {
            enqueueSnackbar('Nội dung tối thiểu 6 ký tự', { variant: 'error' });
            return;
        }
		let response;
		let body = {
			title: title,
			message: convertedText,
			gameId: state?.navbar?.id,
			platform: platform,
			userId: state?.login?.id,
			userName: state?.login?.name,
		};
		response = await ApiRequest(Constant.POST, Constant.MAIL_URL, body);
		if (!response?.data) return;
		enqueueSnackbar('Gửi thư thành công!', { variant: 'success' });
		const data = response?.data?.data;
		renameKey(data.mails, '_id', 'id');
		setListMail((listMail) => [data.mails, ...listMail]);
		setClearControl();
	};

	const removeMails = async () => {
		let response = await ApiRequest(
			Constant.POST,
			Constant.MAIL_URL + `/deletes`,
			idMails
		);
		if (!response?.data) return;
		setIdMails([]);
		setListMail([]);
		enqueueSnackbar('Xoá thư thành công!', { variant: 'success' });
		await init();
	};

	const init = async () => {
		let response = await ApiRequest(
			Constant.GET,
			Constant.MAIL_URL + `?gameId=${gameId}`
		);
		if (!response?.data) return;
		const data = response?.data?.data;
		if (data.mails.length > 0) {
			data.mails.forEach((obj) => renameKey(obj, '_id', 'id'));
			setListMail(data.mails);
		}
	};

	useEffect(() => {
		if (!gameId) return;
		init();
	}, [gameId]);

	return (
		<div className="home-mailing">
			<Box m="20px">
				<Box display="flex" alignItems="center" flexDirection="row">
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex={1}
					>
						<FormControl
							sx={{ width: '100%', top: -8 }}
							size="small"
							variant="standard"
						>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-select-small"
								label="Platform"
								value={platform}
								onChange={handleChangePlatform}
								displayEmpty
								renderValue={
									platform !== Constant.EMPTY
										? undefined
										: () => (
												<Placeholder>
													{Constant.LABEL.PLATFORM}
												</Placeholder>
										  )
								}
							>
								{listItems(platformData)}
							</Select>
						</FormControl>
					</Box>
				</Box>

				<Box display="flex" alignItems="center" flexDirection="row">
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex="1"
					>
						<TextField
							placeholder="Tiêu đề"
							variant="standard"
							size="small"
							style={{ width: '100%' }}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</Box>
				</Box>

				<Box
					mt="20px"
					display="flex"
					alignItems="center"
					flexDirection="row"
				>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						flex="1"
					>
						<Editor
							value={convertedText}
							setValue={setConvertedText}
							style={{ width: '100%' }}
						/>
					</Box>
				</Box>

				<Box
					mt="50px"
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexDirection="row"
				>
					<Button
						className="btnRed"
						onClick={() => setModalShow(true)}
					>
						{Constant.BUTTON.SEND}
					</Button>
					<Button
						className="btnGray"
						onClick={() => setClearControl()}
						style={{ marginLeft: '10px' }}
					>
						{Constant.BUTTON.RESET}
					</Button>
					{idMails?.length > 0 && (
						<Button
							className="btnRed"
							onClick={removeMails}
							style={{ marginLeft: '10px' }}
						>
							Xoá
						</Button>
					)}
				</Box>

				<Box
					m="10px 0 0 0"
					height="80vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={[...listMail]}
						checkboxSelection
						columns={columns}
						autoPageSize
						onRowClick={handleRowClick}
						disableRowSelectionOnClick
						onSelectionModelChange={(itm) => setIdMails(itm)}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns status and traderName, the other columns will remain visible
									id: false,
								},
							},
							pagination: { paginationModel: { pageSize: 5 } },
						}}
					/>
				</Box>

				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					message={Message.MS0002}
					btnCancelShow="true"
					btnAgreeShow="true"
					onCloseModal={handleCloseModal}
				/>
				<Review
					open={reviewShow}
					onCloseDialog={handleCloseDialog}
					data={rows}
				/>
			</Box>
		</div>
	);
};

export default Mailing;
