import './devicestatistics.scss';
import {
	Box,
	FormControl,
	Select,
	MenuItem,
	ToggleButtonGroup,
} from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import { platformData } from '../../data/mockDataUser';
import { DataGrid } from '@mui/x-data-grid';
import Column from '../../components/charts/Column';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useContext } from 'react';
import { DatePicker } from 'antd';
import Constant from '../../common/constant/constant';
import ApiRequest from '../../api/axios';
import dayjs from 'dayjs';
import { renameKey } from '../../common/util';
import { count } from '@syncfusion/ej2-react-richtexteditor';
import { SiteContext } from '../../context/SiteContext';
const { RangePicker } = DatePicker;

const ToggleButton = styled(MuiToggleButton)(() => ({
	'&.Mui-selected, &.Mui-selected:hover': {
		color: 'white',
		backgroundColor: 'red',
	},
}));

const DeviceStatistics = () => {
	const date = new Date(),
		y = date.getFullYear(),
		m = date.getMonth();
	const firstDay = dayjs(new Date(y, m, 1));
	const lastDay = dayjs(new Date(y, m + 1, 0));
	const [categories, setCategories] = useState([]);
	const [series, setSeries] = useState([]);
	const [alignment, setAlignment] = useState(Constant.VALUE.TOGGLE_DAY);
	const [from, setFrom] = useState(
		firstDay.format(Constant.VALUE.DATEFORMAT)
	);
	const [to, setTo] = useState(lastDay.format(Constant.VALUE.DATEFORMAT));
	const [platform, setPlatform] = useState(Constant.ANY);
	const [listDevices, setListDevices] = useState([]);
	const [chartDevices, setChartDevices] = useState({});
	const { state } = useContext(SiteContext);

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	const columns = [
		{
			field: 'id',
			headerName: 'STT',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'order-column--cell',
		},
		{
			field: '_id',
			headerName: 'Time',
			flex: 1,
			type: 'date',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'createdAt-column--cell',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value?.createdAt)).format(
					Constant.FORMAT.FULLDATE
				),
		},
		{
			field: '_id.deviceModel',
			headerName: 'Mã thiết bị',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'deviceModel-column--cell',
			valueGetter: (params) => params?.row?._id?.deviceModel,
		},
		{
			field: 'id.operatingSystem',
			headerName: 'Hệ điều hành',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'operatingSystem-column--cell',
			valueGetter: (params) => params?.row?._id?.operatingSystem,
		},
		{
			field: 'id.gameVersion',
			headerName: 'Version',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'gameVersion-column--cell',
			valueGetter: (params) => params?.row?._id?.gameVersion,
		},
		{
			field: 'count',
			headerName: 'Tổng người dùng',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			cellClassName: 'count-column--cell',
		},
	];

	const handleChange = (event, newAlignment) => {
		setCategories([]);
		setSeries([]);
		setAlignment(newAlignment);
		console.log(newAlignment);
		setDataChart(newAlignment, chartDevices, platform);
	};

	const onDateChange = (date, dateString) => {
		console.log(dateString);
		setFrom(dateString[0]);
		setTo(dateString[1]);
		getDataDevices(dateString[0], dateString[1], platform);
	};

	const handleChangePlatform = (event) => {
		console.log(event.target.value);
		setPlatform(event.target.value);
		getDataDevices(from, to, event.target.value);
	};

	const setDataChart = (toggleValue, data, deviceType) => {
		let keys = [];
		let totalAndroid = []; // [ 0,3,5,7]
		let totalIos = []; // [0,3,5,6]
		console.log('data Chart', data);
		// check month toggle
		if (toggleValue === Constant.VALUE.TOGGLE_MONTH) {
			let currentMonth = 0;
			let totalAndroidCurrent = 0;
			let totalIOSCurrent = 0;
			for (const [key, value] of Object.entries(data)) {
				// get key and categorys data
				const dateMonth = dayjs(key).format(Constant.FORMAT.DATEMONTH);
				if (!keys.includes(dateMonth)) {
					keys.push(dateMonth);
				}

				// check month and check data
				const monthNow = dayjs(key).format('MM');
				if (monthNow !== currentMonth) {
					currentMonth = monthNow;
					totalAndroidCurrent > 0 &&
						totalAndroid.push(totalAndroidCurrent);
					totalIOSCurrent > 0 && totalIos.push(totalIOSCurrent);
					totalAndroidCurrent > 0 && (totalAndroidCurrent = 0);
					totalIOSCurrent > 0 && (totalIOSCurrent = 0);
				}

				for (const v of value) {
					v.operatingSystem === Constant.VALUE.ANDROID &&
						(totalAndroidCurrent += v.count);
					v.operatingSystem === Constant.VALUE.IOS &&
						(totalIOSCurrent += v.count);
				}
			}
			if (totalAndroidCurrent > 0 || totalIOSCurrent > 0) {
				totalIos.push(totalIOSCurrent);
				totalAndroid.push(totalAndroidCurrent);
				totalAndroidCurrent = 0;
				totalIOSCurrent = 0;
			}
		} else {
			for (const [key, value] of Object.entries(data)) {
				let android = 0;
				let ios = 0;
				keys.push(key);
				for (const v of value) {
					v.operatingSystem === Constant.VALUE.ANDROID &&
						(android = v.count);
					v.operatingSystem === Constant.VALUE.IOS && (ios = v.count);
				}
				totalAndroid.push(android);
				totalIos.push(ios);
			}
		}

		const series = [];
		// check platform is ANY push 2 kind device type
		deviceType === Constant.ANY
			? series.push(
					{
						name: Constant.VALUE.ANDROID,
						data: totalAndroid,
					},
					{
						name: Constant.VALUE.IOS,
						data: totalIos,
					}
					// check platform is ANDROID push device type ANDRIOD
			  )
			: deviceType === Constant.VALUE.ANDROID
			? series.push(
					{
						name: Constant.VALUE.ANDROID,
						data: totalAndroid,
					}
					// check platform is IOS push device type IOS
			  )
			: series.push({
					name: Constant.VALUE.IOS,
					data: totalIos,
			  });
		console.log('categories ', keys);
		console.log('series ', series);
		setCategories(keys);
		setSeries(series);
	};

	const getDataDevices = async (dateFrom, dateTo, deviceType) => {
		let from = 'from=' + dateFrom;
		let to = 'to=' + dateTo;
		let type = 'type=' + deviceType;
		let query = `?${from}&${to}&${type}`;
		const response = await ApiRequest(
			Constant.GET,
			Constant.DEVICESTATISTICS_URL + query
		);
		if (!response?.data) return;
		const dataList = response?.data?.data?.statistics;
		console.log('statistics ', response?.data?.data);
		if (dataList.list.length > 0) {
			for (let i = 0; i < dataList.list.length; i++) {
				dataList.list[i].id = i + 1;
			}
			setListDevices(dataList.list);
		}

		if (dataList?.statistics) {
			setChartDevices(dataList.statistics);
		}
		setDataChart(alignment, dataList.statistics, deviceType);
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		getDataDevices(from, to, platform);
	}, [state]);

	return (
		<div className="home-devicestatistics">
			<Box m="20px">
				<Box display="flex" alignItems="center" flexDirection="row">
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						ml="10px"
					>
						<span>{Constant.LABEL.TIME}</span>
						<RangePicker
							size="large"
							style={{ backgroundColor: '#F5F8FF' }}
							onChange={onDateChange}
							defaultValue={[firstDay, lastDay]}
						/>
					</Box>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="flex-start"
						flexDirection="column"
						ml="10px"
					>
						<span>{Constant.LABEL.PLATFORM}</span>
						<FormControl sx={{ minWidth: 190 }} size="small">
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={platform}
								onChange={handleChangePlatform}
							>
								{listItems(platformData)}
							</Select>
						</FormControl>
					</Box>
				</Box>

				<Box
					m="10px 0 0 0"
					height="40vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={listDevices}
						columns={columns}
						autoPageSize
					/>
				</Box>

				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					height="35vh"
				>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="flex-start"
						flexDirection="row"
						width="100%"
					>
						<span>{Constant.LABEL.SHOW}</span>
						<ToggleButtonGroup
							color="primary"
							value={alignment || Constant.VALUE.TOGGLE_DAY}
							exclusive
							onChange={handleChange}
							size="small"
							aria-label="Small sizes"
							sx={{ marginLeft: '10px' }}
						>
							<ToggleButton
								className="toggleButton"
								value={Constant.VALUE.TOGGLE_DAY}
								aria-label="left aligned"
							>
								{Constant.LABEL.TOGGLE_DAY}
							</ToggleButton>
							<ToggleButton
								className="toggleButton"
								value={Constant.VALUE.TOGGLE_MONTH}
								aria-label="left aligned"
							>
								{Constant.LABEL.TOGGLE_MONTH}
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Column
						categoriesData={categories}
						// categoriesData={Object.keys(chartDevices)}
						seriesData={series}
						// seriesData={Object.values(chartDevices)}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default DeviceStatistics;
