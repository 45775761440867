import './logpurchaseggold.scss';
import {
	TextField,
	Box,
	FormControl,
	Select,
	MenuItem,
	Typography,
	Pagination,
	Grid,
} from '@mui/material';
import Button from 'react-bootstrap/Button';
import { paymentData, paymentTransactionStatusFilter } from '../../data/mockDataUser';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from 'antd';
import { useContext, useEffect, useState } from 'react';
import Constant from '../../common/constant/constant';
import ApiRequest from '../../api/axios';
import { renameKey } from '../../common/util';
import dayjs from 'dayjs';
import { SiteContext } from '../../context/SiteContext';
import Modal from '../../components/modal/ModalShowJSON';


const { RangePicker } = DatePicker;

function CustomFooterStatusComponent(props) {
	return <Box sx={{ p: 1, display: 'flex' }}>Status</Box>;
}

const LogPurchaseGGold = () => {
	const [purchaseCode, setPurchaseCode] = useState(Constant.EMPTY);
	const [username, setUserName] = useState(Constant.EMPTY);
	const [type, setType] = useState(Constant.EMPTY);
	const [status, setStatus] = useState(Constant.EMPTY);
	const [from, setFrom] = useState(Constant.EMPTY);
	const [to, setTo] = useState(Constant.EMPTY);
	const [isLoading, setIsLoading] = useState(false);
	const [dataLogGGolds, setDataLogGGolds] = useState([]);
	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(2);
	const [open, setOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});


	const handleClose = () => setOpen(false);
	const handleRowClick = (params) => {
		setSelectedRow(params.row)
		setOpen(true);
	};
	const { state } = useContext(SiteContext);
	const gameId = state?.navbar?.id;

	let listItems = (datas) => {
		if (datas.length === 0) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	const columns = [
		// {
		// 	field: 'id',
		// 	headerName: 'ID',
		// 	flex: 1,
		// 	headerAlign: 'center',
		// 	align: 'center',
		// },
		{
			field: 'gameUserId',
			headerName: 'User name',
			headerAlign: 'center',
            align: 'center',
            flex: 1,
			cellClassName: 'user_name-column--cell',
		},
		{
			field: 'sdkUserId',
			headerName: 'UID',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'uid-column--cell',
		},
		{
			field: 'type',
			headerName: 'Hình thức nạp',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'type-column--cell',
		},
		{
			field: 'code',
			headerName: 'Purchase Code',
			headerAlign: 'center',
			flex: 1,
			align: 'center',
			cellClassName: 'transaction_id-column--cell',
		},
		{
			field: 'amount',
			headerName: 'Amount',
			// flex: 1,
			type: 'number',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'amount-column--cell',
		},
		// {
		// 	field: 'wallet_log.gGold',
		// 	headerName: 'GGold',
		// 	flex: 1,
		// 	type: 'number',
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	cellClassName: 'gGold-column--cell',
		// 	renderCell: ({ row: { wallet_log } }) => {
		// 		if (!wallet_log) return;
		// 		return Number(wallet_log.gGold).toFixed(2) + '$';
		// 	},
		// },
		// {
		// 	field: 'wallet_log.afterGGold',
		// 	headerName: 'GGold sau nạp',
		// 	flex: 1,
		// 	type: 'number',
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	cellClassName: 'afterGGold-column--cell',
		// 	renderCell: ({ row: { wallet_log } }) => {
		// 		if (!wallet_log) return;
		// 		return Number(wallet_log.afterGGold).toFixed(2) + '$';
		// 	},
		// },
		{
			field: 'createdAt',
			headerName: 'Time',
			flex: 1,
			type: 'dateTime',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'createdAt-column--cell',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value)).format(
					Constant.FORMAT.FULLDATE
				) +
				' ' +
				dayjs(new Date(params?.value)).format(Constant.FORMAT.TIME),
		},
		{
			field: 'paymentStatus',
			headerName: 'Status',
			// flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { paymentStatus } }) => {
				return (
					<Box
						width="60%"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<Typography sx={{ ml: '5px' }}>
							<span
								style={{
									color: paymentStatus === 'pending'
										? Constant.FONTCOLOR.GREY
										: paymentStatus === 'success'
										? Constant.FONTCOLOR.GREEN
										: Constant.FONTCOLOR.RED,
								}}
							>
								{paymentStatus?.toUpperCase()}
							</span>
						</Typography>
					</Box>
				);
			},
		},
	];

	const onDateChange = (date, dateString) => {
		// console.log(dateString);
		setFrom(dateString[0]);
		setTo(dateString[1]);
	};

	const handleChangeType = (event) => {
		// console.log('event', event.target.value);
		setType(event.target.value);
	};

	const handleChangeStatus = (event) => {
		setStatus(event.target.value);
	};

	const handleSearch = async (page = 1) => {
		setDataLogGGolds([]);
		setIsLoading(true);
		let s = `?page=${page}&rechargeType=WALLET`;
		if (purchaseCode !== '') s += `&purchaseCode=${purchaseCode}`;
		if (username !== '') s += `&username=${username}`;
		if (status !== '' && status !== 'all') s += `&paymentStatus=${status}`;
		if (type !== '' && type !== 'ALL') s += `&type=${type}`;
		if (from !== '') s += `&from=${from}`;
		if (to !== '') s += `&to=${to}`;
		let response = await ApiRequest(
			Constant.GET,
			`${Constant.PURCHASE_URL}/list${s}`
		);
		setIsLoading(false);
		setPage(page);
		if (!response?.data) return;
		const dataLogGGolds = response?.data?.data?.purchase;

		if (dataLogGGolds?.list) {
			dataLogGGolds?.list.forEach((obj) => renameKey(obj, '_id', 'id'));
			for (let i = 0; i < dataLogGGolds.list.length; i++) {
				dataLogGGolds.list[i].order = i + 1;
			}
			setDataLogGGolds(dataLogGGolds?.list);
			setPageTotal(dataLogGGolds?.totalPage);
		}
		// if (purchaseCode !== '') setPurchaseCode('');
		// if (username !== '') setUserName('');
		// if (type !== '') setType('');
		// if (from !== '') setFrom('');
		// if (to !== '') setTo('');
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		handleSearch(page);
	}, [state]);

	return (
		<div className="home-logpurchaseggold">
			<Box m="20px">
				<Grid container>
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ alignItems: 'end' }}
						>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.PURCHASE_CODE}</Box>
								<TextField
									placeholder={
										Constant.PLACEHOLDER.TRANSACTIONID
									}
									onChange={(e) =>
										setPurchaseCode(e.target.value)
									}
									value={purchaseCode}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.USER_NAME}</Box>
								<TextField
									placeholder={Constant.PLACEHOLDER.USERNAME}
									onChange={(e) =>
										setUserName(e.target.value)
									}
									value={username}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.PAYMENT}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										defaultValue="ALL"
										value={type}
										onChange={handleChangeType}
									>
										{listItems(paymentData)}
									</Select>
								</FormControl>
								</Grid>
								<Grid item xs={12} md={6} lg={2}>
									<Box>{Constant.LABEL.STATUS}</Box>
									<FormControl
										className="inputWidth"
										size="small"
									>
										<Select
											labelId="demo-select-small-label"
											id="demo-select-small"
											defaultValue="ALL"
											value={status}
											onChange={handleChangeStatus}
										>
											{listItems(paymentTransactionStatusFilter)}
										</Select>
									</FormControl>
								</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Box>{Constant.LABEL.TIME}</Box>
								<RangePicker
									size="large"
									style={{ backgroundColor: '#F5F8FF' }}
									onChange={onDateChange}
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={2}>
								<Button
									className="btnRed"
									onClick={() => handleSearch(1)}
									disabled={isLoading}
								>
									{Constant.BUTTON.SEARCH}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Box
					m="10px 0 0 0"
					height="78vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={dataLogGGolds}
						columns={columns}
						loading={isLoading}
						onRowClick={handleRowClick}
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns status and traderName, the other columns will remain visible
									id: false,
								},
							},
						}}
						hideFooter={true}
					/>

					<Grid container justifyContent="center">
						<Pagination
							count={pageTotal}
							onChange={(e, v) => {
								handleSearch(v);
							}}
						/>
					</Grid>
				</Box>
				<Modal
                show={open}
                onHide={handleClose}
                message={JSON.stringify(selectedRow)}
                btnCancelShow="true"
                onCloseModal={handleClose}
            />
			</Box>
		</div>
	);
};

export default LogPurchaseGGold;
