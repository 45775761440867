import "./operationaldata.scss";
import Line from "../../components/charts/Line";
import { Box, FormControl, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { platformData, displayData, listData } from "../../data/mockDataUser";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ALLData } from "../../data/dataChart";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from 'antd';

const OperationalData = () => {

    const [categoriesALL, setCategoriesALL] = useState([]);
    const [seriesALL, setSeriesALL] = useState([]);
    const [tab, setTab] = useState("Chart");
    const [column, setColumn] = useState([]);
    const [rows, setRows] = useState([]);
    // const [isLoadingPage, setIsLoadingPage] = useState(true);

    let listItems = (datas) => {
        if (datas.length === 0) {
            return;
        } else {
            return datas.map((item) => {
                return (
                    <MenuItem value={item._id} key={item._id}>
                        <span>{item.code}</span>
                    </MenuItem>
                )
            });
        }
    };

    let listItemsShow = (datas) => {
        if (datas.length === 0) {
            return;
        } else {
            return datas.map((item) => {
                return (
                    <MenuItem value={item._id} key={item._id}>
                        <FiberManualRecordIcon sx={{ fontSize: 10, marginRight: "10px", color: item.color }} />
                        <span>{item.code}</span>
                    </MenuItem>
                )
            });
        }
    };

    const handleChangeTab = (event) => {
        console.log(event.target.value);
        setTab(event.target.value);
        if (event.target.value === "Grid") {
            console.log(categoriesALL);
            let column = [
                {
                    field: "id",
                    headerName: "STT",
                    flex: 1,
                    headerAlign: "center",
                    align: "center",
                },
                {
                    field: "name",
                    headerName: "A",
                    flex: 1,
                    headerAlign: "center",
                    align: "center",
                },
            ];

            for (let i = 0; i < categoriesALL.length; i++) {
                let item = {
                    field: categoriesALL[i],
                    headerName: categoriesALL[i].replace("h", "") + ":00",
                    flex: 1,
                    headerAlign: "center",
                    align: "center",
                }
                column.push(item);
                console.log(item);
            }
            console.log("data column: ", column);
            setColumn(column);

            let row = [];

            for (let i = 0; i < ALLData.length; i++) {
                if (ALLData[i].tab !== "ALL") {
                    let item = {
                        id: i,
                        name: ALLData[i].tab
                    }
                    let sum = 0;
                    for (let j = 0; j < ALLData[0].data.categories.length; j++) {
                        let col = ALLData[0].data.categories[j];
                        sum = (ALLData[i].data.series[0].data[j] + ALLData[i].data.series[1].data[j]);
                        item[col] = sum;
                    }
                    console.log(item);
                    row.push(item);
                }
            }
            console.log("data row: ", row);
            setRows(row);
        }
    };

    const setDataChartALL = () => {
        let xaxis = [];
        let series = [];

        for (let item of ALLData) {
            if (item.tab === 'ALL') {
                xaxis = item.data.categories;
                series = item.data.series;
                break;
            }
            // switch (item.tab) {
            //     case alignment:
            //         console.log("data " + item.tab + ": " + JSON.stringify(item.data));

            //         break;
            //     default:
            //         break;
            // }
        }

        console.log(xaxis);
        setCategoriesALL(xaxis);
        console.log(series);
        setSeriesALL(series);
    }

    useEffect(() => {
        setDataChartALL();
        let newColumn = [
            {
                field: "id",
                headerName: "STT",
                flex: 1,
                headerAlign: "center",
                align: "center",
            },
            {
                field: "name",
                headerName: "A",
                flex: 1,
                headerAlign: "center",
                align: "center",
            },
        ];

        for (let i = 0; i < ALLData[0].data.categories.length; i++) {
            let item = {
                field: ALLData[0].data.categories[i],
                headerName: ALLData[0].data.categories[i].replace("h", "") + ":00",
                flex: 1,
                headerAlign: "center",
                align: "center",
            }
            newColumn.push(item);
            console.log(item);
        }
        setColumn(newColumn);

        let newRow = [];

        for (let i = 0; i < ALLData.length; i++) {
            if (ALLData[i].tab !== "ALL") {
                let item = {
                    id: i,
                    name: ALLData[i].tab
                }
                let sum = 0;
                for (let j = 0; j < ALLData[0].data.categories.length; j++) {
                    let col = ALLData[0].data.categories[j];
                    sum = (ALLData[i].data.series[0].data[j] + ALLData[i].data.series[1].data[j]);
                    item[col] = sum;
                }
                console.log(item);
                newRow.push(item);
            }
        }
        setRows(newRow);
        // setTimeout(() => {
        //     setIsLoadingPage(false);
        // console.log('--------no day-----------', newRow, newColumn)

        // }, 500)
    }, []);

    return (
        <div className="home-overviewreport">
            <Box m="20px">
                <Box display="flex" alignItems="center" flexDirection="row">
                    <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column">
                        <span>Thời gian</span>
                        <DatePicker size="large" style={{ backgroundColor: "#F5F8FF" }} />
                    </Box>
                    <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column" ml="10px">
                        <span>Platform</span>
                        <FormControl sx={{ minWidth: 190 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                defaultValue="ALL"
                            // value={department}
                            // onChange={handleChangeDepartment}
                            >
                                {listItems(platformData)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column" ml="10px">
                        <span>Hiển thị</span>
                        <FormControl sx={{ minWidth: 190 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={tab}
                                onChange={handleChangeTab}
                            >
                                {listItems(displayData)}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box mt="10px" display="flex" alignItems="center" flexDirection="column" height="75vh" width="99%">
                    <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" ml="10px" width="100%">
                        <FormControl sx={{ minWidth: 190 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                defaultValue="all"
                            // value={department}
                            // onChange={handleChangeDepartment}
                            >
                                {listItemsShow(listData)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Line categoriesData={categoriesALL}
                        seriesData={seriesALL}
                        show={tab === "Chart" ? false : true}
                    />
                    {
                        tab === "Grid" &&
                        <Box
                            m="10px 0 0 0"
                            height="70vh"
                            width="100%"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // color: colors.greenAccent[300],
                                    //borderBottom: "none",
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                },
                                "& .MuiTablePagination-toolbar": {
                                    display: "flex",
                                    alignItems: "baseline"
                                },
                            }}
                        >
                            <DataGrid
                                rows={rows}
                                columns={column}
                            />

                        </Box>
                    }
                </Box>
            </Box>
        </div>
    )
}

export default OperationalData